import { Injectable } from '@angular/core';
import { CRUDService } from '#services/api/crud.service';
import { environment } from '#environments/environment';
import { Drawing } from 'models/drawing';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DrawingService extends CRUDService<Drawing>{
  constructor(http: HttpClient) {
    super(http, `${environment.apiEndpoint}/drawing`);
  }

  public enable_disable(id: number): Observable<any> {
    return this.http.put<any>(`${this.url}/${id}/toggle-status`, null);
  }

  public getDrawingbyEquipmentType(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/${id}/equipmentType`);
  }

  public getDrawingFile(id: number): any {
    return this.http.get(`${this.url}/${id}/download`, { responseType: 'blob' });
  }

  public updateFile(file, options: any): Observable<any> {
    return this.http.put<any>(`${this.url}`, file, { params: options });
  }

}
