import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '#services/shared';
import{ProfileType} from '#services/shared/model/graph.model'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Output() public sidenavToggle = new EventEmitter();
    userFullName: string;
    profile !: ProfileType | null;
    setLoginDisplay !: boolean | null;
    constructor(
        private router: Router,
        private authSvc: AuthService
    ) { }

    ngOnInit(): void {
        this.authSvc.profile.subscribe(profile => this.profile = profile);
        this.authSvc.loginDisplay.subscribe(setLoginDisplay => this.setLoginDisplay = setLoginDisplay);
        
    }

    redirect(url: string) {
        this.router.navigate([url]);
    }

    public onToggleSidenav = () => {
        this.sidenavToggle.emit();
    }
}
