import { NgModule } from '@angular/core';
import { LoggerService, AuthService, Utils, ToasterService } from './';
import { MessageService } from 'primeng/api';
import { PrintService } from './print.service';


@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [
    LoggerService,
    AuthService,
    Utils,

    ToasterService,
    MessageService,
    PrintService
  ]
})

export class SharedModule { }
