import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Equipment, Filter, PoGap, PurchaseOrder } from 'models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Role } from 'enums';
import { MatDialog } from '@angular/material/dialog';
import { PoGapService } from '#services/api/po-gap.service';
import { ToasterService } from '#services/shared';
import { take } from 'rxjs/operators';
import { PoEditDialogComponent } from '#components/purchase-order/po-edit-dialog/po-edit-dialog.component';
import { PurchaseOrderService } from '#services/api';
import { CommonHelper } from '_extentions/common-helper';

@Component({
  selector: 'app-po-gaps-table',
  templateUrl: './po-gaps-table.component.html',
  styleUrls: ['./po-gaps-table.component.scss']
})
export class PoGapsTableComponent implements OnInit {
  @Input()
  set filter(value: Filter) {
    this._filter = value;

    this.applyFilter(value);
  }
  get filter() {
    return this._filter;
  }

  @Input()
  set data(value: PoGap[]) {
    this.dataSource.data = value;
  }

  @Input() columnsToDisplay: Map<string, string>;
  @Output() itemChanged = new EventEmitter();

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  _filter: Filter = <Filter>{};
  dataSource = new MatTableDataSource<PoGap>();

  Role = Role;

  constructor(
    public dialog: MatDialog,
    private poGapService: PoGapService,
    private poService: PurchaseOrderService,
    private toaster: ToasterService,
    public helper: CommonHelper
  ) { }

  ngOnInit() {
    setTimeout(() => this.dataSource.paginator = this.paginator);
  }

  get allColumns(): string[] {
    return Array.from(this.columnsToDisplay.keys());
  }

  get canShowTable() {
    const hasSearchedItems = this.filter.search
      ? this.dataSource.filteredData.length
      : true;

    const hasFilterParamsItems = this.filter.paramsCount
      ? this.dataSource.data.length
      : true;

    return hasSearchedItems && hasFilterParamsItems;
  }

  getColumnTitle(column: string): string {
    return this.columnsToDisplay.get(column);
  }

  applyFilter(filter: Filter) {
    this.dataSource.filter = filter.search;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ignore(item: PoGap) {
    this.poGapService.ignore(item.ID)
      .pipe(take(1))
      .subscribe(data => this.onSuccessIgnore(data));
  }

  onSuccessIgnore(item: PoGap) {
    this.toaster.showSuccess('Success..', `A PO Gap with Id '${item.ID}' has been successfully ignored`);
    this.itemChanged.emit({
      ...item,
      IgnoredAt: new Date(),
      UnignoredAt: null
    });
  }

  unignore(item: PoGap) {
    this.poGapService.unignore(item.ID)
      .pipe(take(1))
      .subscribe(data => this.onSuccessUnignore(data));
  }

  onSuccessUnignore(item: PoGap) {
    this.toaster.showSuccess('Success..', `A PO Gap with Id '${item.ID}' has been successfully unignored`);
    this.itemChanged.emit({
      ...item,
      IgnoredAt: null,
      UnignoredAt: new Date()
    });
  }

  create(item: PoGap) {
    const dialogRef = this.dialog.open(PoEditDialogComponent, {
      width: '50%',
      maxHeight: '80%',
      data: {
        PurchaseOrderNumber: item.PurchaseOrderNumber,
        SubmitDate: item.SubmitDate
      } as PurchaseOrder
    });

    dialogRef.afterClosed().subscribe(async (result: {po: PurchaseOrder, eq: Equipment[]}) => {
      if (result) {
        await this.poService.create(result.po).toPromise();

        this.itemChanged.emit({
          ID: item.ID,
          CreatedAt: new Date()
        });

        this.toaster.showSuccess('Success...', 'Purchase order has been created.');
      }
    });
  }
}
