<form #projectForm="ngForm" class="col">
    <div class="row">
        <app-autocomplete-dropdown
            [label]="'Type'"
            [parentFormControl]="projectForm.control"
            [options]="types"
            [displayProperty]="'Name'"
            [placeholder]="'select project type'"
            [defaultValue]="projectTypeDefValue"
            (selectedChanged)='onProjectTypeChanged($event)'
            (clicked)="onTypeDDClick()"
            class="fx-25"
            [required]="true">
        </app-autocomplete-dropdown>
        <app-autocomplete-dropdown
            [label]="'Area'"
            [parentFormControl]="projectForm.control"
            [options]="areas"
            [displayProperty]="'Name'"
            [placeholder]="'select area'"
            [defaultValue]="projectAreaDefValue"
            (selectedChanged)='onAreaChanged($event)'
            (clicked)="onAreaDDClick()"
            class="fx-25"
            [required]="false">
        </app-autocomplete-dropdown>
        <app-autocomplete-dropdown
            [label]="'Template'"
            [parentFormControl]="projectForm.control"
            [options]="choices"
            [displayProperty]="'Name'"
            [placeholder]="'select template'"
            [defaultValue]="projectTemplateDefValue"
            (selectedChanged)='onProjectTemplateChanged($event)'
            (clicked)="onLoadTemplateChoices()"
            class="fx-25">
        </app-autocomplete-dropdown>
		<app-autocomplete-dropdown
            [label]="'Status'"
            [parentFormControl]="projectForm.control"
            [options]="statuses"
            [displayProperty]="'Name'"
            [placeholder]="'select project status'"
            [defaultValue]="projectStatusDefValue"
            (selectedChanged)='onProjectStatusChanged($event)'
			(clicked)="onLoadProjectStatuses()"
            class="fx-25"
            [required]="true">
        </app-autocomplete-dropdown>
    </div>

    <mat-divider></mat-divider>

    <div class="col">
        <span class="input-group-name">Names</span>
        <div class="row">
            <mat-form-field appearance="outline" class="fx-50">
                <mat-label>Name</mat-label>
                <input matInput
                    [(ngModel)]="project.Name"
                    [ngModelOptions]="{standalone: true}"
                    maxlength="150"
                    placeholder="enter project name"
                    required>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fx-25">
                <mat-label>Number</mat-label>
                <input matInput
                    [(ngModel)]="project.Number"
                    [ngModelOptions]="{standalone: true}"
                    maxlength="15"
                    placeholder="enter project number">
            </mat-form-field>
            <mat-form-field appearance="outline" class="fx-25">
                <mat-label>Placeholder number</mat-label>
                <input matInput
                    [(ngModel)]="project.PlaceholderNumber"
                    [ngModelOptions]="{standalone: true}"
                    maxlength="15"
                    placeholder="project placeholder number">
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field appearance="outline" class="fx-25">
                <mat-label>Key name</mat-label>
                <input matInput
                    [(ngModel)]="project.KeyName"
                    [ngModelOptions]="{standalone: true}"
                    maxlength="50"
                    placeholder="enter project key name">
            </mat-form-field>
            <mat-form-field appearance="outline" class="fx-25">
                <mat-label>Key name free form</mat-label>
                <input matInput
                    [(ngModel)]="project.KeyNameFreeForm"
                    [ngModelOptions]="{standalone: true}"
                    maxlength="120"
                    placeholder="project key name free form">
            </mat-form-field>
            <mat-form-field appearance="outline" class="fx-25">
                <mat-label>Standard name</mat-label>
                <input matInput
                    [(ngModel)]="project.StandardName"
                    [ngModelOptions]="{standalone: true}"
                    maxlength="180"
                    placeholder="project standard name">
            </mat-form-field>
            <mat-form-field appearance="outline" class="fx-25">
                <mat-label>C&D Name</mat-label>
                <input matInput
                    [(ngModel)]="project.CDName"
                    [ngModelOptions]="{standalone: true}"
                    maxlength="180"
                    placeholder="C&D name">
            </mat-form-field>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="row">
        <div class="col fx-50">
            <span class="input-group-name">General information</span>
            <div class="row">
                <app-user-dropdown class="fx-50"
                    [label]="'Representative person'"
                    [defaultValue]="representativeDefValue"
                    (changed)="onRepresentativePersonChanged($event)">
                </app-user-dropdown>

                <app-autocomplete-dropdown
                    [parentFormControl]="projectForm.control"
                    [options]="teams"
                    [label]="'Team'"
                    [displayProperty]="'Name'"
                    [placeholder]="'select project team'"
                    [defaultValue]="projectTeamDefValue"
                    (selectedChanged)='onTeamChanged($event)'
                    (clicked)="onTeamDDClick()"
                    class="fx-50">
                </app-autocomplete-dropdown>
            </div>

            <div class="row">
                <mat-form-field appearance="outline" class="fx-50">
                    <mat-label>AFE PreFund</mat-label>
                    <input matInput
                        [ngModel]="project.PreFundedAFE"
                        (ngModelChange)="project.PreFundedAFE=$event"
                        [ngModelOptions]="{standalone: true}"
                        maxlength="30"
                        placeholder="project AFE PreFund">
                </mat-form-field>
                <mat-form-field appearance="outline" class="fx-50">
                    <mat-label>AFE PrimaryFund</mat-label>
                    <input matInput
                        [ngModel]="project.PrimaryFundAFE"
                        (ngModelChange)="project.PrimaryFundAFE=$event"
                        [ngModelOptions]="{standalone: true}"
                        maxlength="30"
                        placeholder="project AFE PrimaryFund">
                </mat-form-field>
            </div>

            <span class="input-group-name">Outlook review</span>
            <div class="row">
                <mat-form-field appearance="outline" class="fx-50">
                    <mat-label>Reviewed date</mat-label>
                    <input matInput #tcdRef
                        [(ngModel)]="project.ReviewDate"
                        [ngModelOptions]="{standalone: true}"
                        [matDatepicker]="reviewDatePicker"
                        placeholder="review date"
                        required>
                    <mat-datepicker-toggle matSuffix
                        [for]="reviewDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #reviewDatePicker></mat-datepicker>
                </mat-form-field>
                <app-autocomplete-dropdown
                    [parentFormControl]="projectForm.control"
                    [options]="reviewStatuses"
                    [label]="'Review status'"
                    [displayProperty]="'Name'"
                    [placeholder]="'select review status'"
                    [defaultValue]="reviewStatusDefValue"
                    (selectedChanged)='onReviewStatusChanged($event)'
                    (clicked)="onLoadReviewStatuses()"
                    class="fx-50"
                    [required]="true">
                </app-autocomplete-dropdown>
            </div>
        </div>

        <div class="col fx-50">
            <span class="input-group-name">Dates</span>
            <div class="row">
                <mat-form-field appearance="outline" class="fx-50">
                    <mat-label>Target completion date</mat-label>
                    <input matInput #tcdRef
                        [(ngModel)]="project.TargetCompletionDate"
                        [ngModelOptions]="{standalone: true}"
                        [matDatepicker]="targetCompletionDatePicker"
                        placeholder="target completion date"
                        required>
                    <mat-datepicker-toggle matSuffix
                        [for]="targetCompletionDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #targetCompletionDatePicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline" class="fx-50">
                    <mat-label>Need by date</mat-label>
                    <input matInput #nbdRef
                        [(ngModel)]="project.NeedByDate"
                        [ngModelOptions]="{standalone: true}"
                        [matDatepicker]="needByDatePicker"
                        placeholder="need by date"
                        required>
                    <mat-datepicker-toggle matSuffix
                        [for]="needByDatePicker"
                        matTooltip="This is date for equipment providers">
                    </mat-datepicker-toggle>
                    <mat-datepicker #needByDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <mat-form-field appearance="outline" class="fx-100">
                <mat-label>Comments</mat-label>
                <textarea matInput
                    [(ngModel)]="project.Comment"
                    [ngModelOptions]="{standalone: true}"
                    maxlength="500"
                    rows="8"
                    placeholder="enter your comments here">
                    </textarea>
                </mat-form-field>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="col">
        <span class="input-group-name">Cost center codes</span>
        <div class="row">
            <app-cost-centers-select
                class="fx-100"
                [codes]="project.CostCenterCodes"
                (changed)="onCostCenterCodesChanged($event)"></app-cost-centers-select>
        </div>
    </div>
</form>
