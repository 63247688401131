<mat-nav-list>
    <div class="col">
        <h3 matSubheader>General</h3>
        <a mat-list-item routerLink="/home" (click)="onItemClick()">
            <mat-icon>home</mat-icon>
            <span class="nav-caption">Home</span>
        </a>
        <a mat-list-item routerLink="/projects" (click)="onItemClick()">
            <mat-icon>vertical_split</mat-icon>
            <span class="nav-caption">Projects</span>
        </a>
        <a mat-list-item routerLink="/equipments" (click)="onItemClick()">
            <mat-icon>view_list</mat-icon>
            <span class="nav-caption">Equipment</span>
        </a>
        <a mat-list-item routerLink="/purchase-orders" (click)="onItemClick()">
            <mat-icon>assignment</mat-icon>
            <span class="nav-caption">Purchase orders</span>
        </a>
        <br>

        <mat-divider></mat-divider>
    </div>
</mat-nav-list>

<mat-nav-list>
    <div class="col">
        <h3 matSubheader>Tools</h3>
        <a mat-list-item routerLink="/tools/po-gaps" (click)="onItemClick()">
            <mat-icon>find_replace</mat-icon>
            <span class="nav-caption">PO Gaps</span>
        </a>
        <a mat-list-item routerLink="/tools/dashboard" (click)="onItemClick()">
            <mat-icon>dashboard</mat-icon>
            <span class="nav-caption">Dashboard</span>
        </a>
        <br>

        <mat-divider></mat-divider>
    </div>
</mat-nav-list>