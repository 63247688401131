<form #equipmentTypeForm="ngForm" class="col">
    <div class="row">
        <mat-form-field appearance="outline" class="fx-50">
            <mat-label>Name</mat-label>
            <input matInput 
                [(ngModel)]="equipmentType.Name" 
                [ngModelOptions]="{standalone: true}" 
                maxlength="50"
                placeholder="enter name">
        </mat-form-field>

        <app-autocomplete-dropdown
            [parentFormControl]="equipmentTypeForm.control"
            [options]="orderTypes"
            [label]="'Order type'"
            [displayProperty]="'Name'"
            [defaultValue]="orderTypeDefValue"            
            [placeholder]="'select order type'"
            [required]="true"
            (selectedChanged)='onOrderTypeChanged($event)'
            (clicked)="onLoadOrderTypes()"
            class="fx-50">
        </app-autocomplete-dropdown>
    </div>
</form>