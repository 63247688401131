<form #equipmentListForm="ngForm" class="page-with-shadow">
    <app-page-header
        [title]="pageTitle"
        [description]="pageDescription">
    </app-page-header>

    <mat-toolbar class="page-toolbar" *ngIf="canShowEquipments">
        <span class="project-count">
            {{equipments && equipments.length}} equipments
        </span>
        <span class="fill-remaining-space"></span>
    </mat-toolbar>

    <div *ngIf="canShowEquipments; else noEquipmentsTemplate">
        <mat-divider></mat-divider>

        <app-filter-panel [paramsCount]="filter.paramsCount" (searchChanged)="onUpdateFilters($event)">
          <!-- Delivery statuses filter -->
          <div class="fx-20">
            <mat-form-field class="fx-100" (click)="onLoadDeliveryStatuses();">
                <mat-label>Statuses</mat-label>
                <mat-select
                    #deliveryStatusesSelect
                    placeholder="select statuses"
                    [formControl]="deliveryStatusesControl"
                    (closed)="deliveryStatusesSelectClosed($event)"
                    (selectionChange)="deliveryStatusesSelectionChange($event)"
                    disableOptionCentering
                    multiple>
                    <mat-select-trigger>
                      <a onclick href=""></a>
                        {{deliveryStatusesControl.value?.length > 0 ? deliveryStatusesControl.value[0].Name : ''}}
                        <span
                            *ngIf="deliveryStatusesControl.value?.length > 1"
                            class="additional-selection">
                                (+{{deliveryStatusesControl.value.length - 1}}
                                {{deliveryStatusesControl.value?.length === 2 ? 'other' : 'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of deliveryStatuses" [value]="status">
                        {{status.Name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          </div>

          <!-- Equipment type filter -->
          <app-equipment-type-dropdown-multiple
              class="fx-20"
              (closed)="onFilterChanged()"
              (changed)="equipmentTypesSelectionChange($event)"
          ></app-equipment-type-dropdown-multiple>
        </app-filter-panel>

        <app-equipment-table
            [columnsToDisplay]="columnsToDisplay"
            [columnsToDisplayInHints]="columnsToDisplayInHints"
            [columnsToDisplayInComments]="columnsToDisplayInComments"
            [data]="equipments"
            [filter]="filter">
        </app-equipment-table>
    </div>
</form>

<ng-template #noEquipmentsTemplate>
    <app-empty-list-holder
        [description]="'No equipments added yet.'">
    </app-empty-list-holder>
</ng-template>
