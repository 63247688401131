import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// material
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from 'app/app.material.module';

// prime ng
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';

import { SharedModule } from '#services/shared/shared.module';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { PageHeaderComponent } from './shared/page-header/page-header.component';

import { FileSizePipe } from '../pipes/file-size.pipes';
import { DateFormatPipe } from '../pipes/date-format.pipes';
import { CommonHelper } from '_extentions/common-helper';
import { AutocompleteDropdownComponent } from './shared/autocomplete-dropdown/autocomplete-dropdown.component';
import { EmptyListComponent } from './shared/empty-list/empty-list.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { FilterPanelComponent } from './shared/filter-panel/filter-panel.component';

import { ProjectListComponent } from './project/project-list/project-list.component';
import { ProjectEditComponent } from './project/project-edit-dialog/project-edit-dialog.component';
import { ProjectCreateComponent } from './project/project-create/project-create.component';
import { ProjectTemplateComponent } from './project/project-template/project-template.component';
import { ProjectTableComponent } from './project/project-table/project-table.component';

import { EquipmentTableComponent } from './equipment/equipment-table/equipment-table.component';
import { EquipmentListComponent } from './equipment/equipment-list/equipment-list.component';
import { EquipmentCreateComponent } from './equipment/equipment-create/equipment-create.component';
import { EquipmentTemplateComponent } from './equipment/equipment-template/equipment-template.component';
import { EquipmentSelectDialogComponent } from './equipment/equipment-select-dialog/equipment-select-dialog.component';
import { EquipmentCreateDialogComponent } from './equipment/equipment-create-dialog/equipment-create-dialog.component';
import { EquipmentEditDialogComponent } from './equipment/equipment-edit-dialog/equipment-edit-dialog.component';
import { EquipmentMilestoneHistoryComponent } from './equipment/equipment-milestone-history/equipment-milestone-history.component';


import { PoListComponent } from './purchase-order/po-list/po-list.component';
import { PoTemplateComponent } from './purchase-order/po-template/po-template.component';
import { PoEditDialogComponent } from './purchase-order/po-edit-dialog/po-edit-dialog.component';
import { PoTableComponent } from './purchase-order/po-table/po-table.component';
import { PoCreateComponent } from './purchase-order/po-create/po-create.component';
import { PoDropdownMultipleComponent } from './purchase-order/po-dropdown-multiple/po-dropdown-multiple.component';

import { ReshufflingComponent } from './reshuffling/reshuffling.component';
import { MilestoneImportComponent } from './milestone-import/milestone-import.component';
import { DragDropComponent } from './shared/drag-drop/drag-drop.component';

import { DragDropDirective } from '../directives/drag-drop.directive';
import { UserRoleUpDirective } from '../directives/user-role-up.directive';

import { OutlookReviewComponent } from './outlook-review/outlook-review.component';
import { TooltipComponent } from './shared/tooltip/tooltip.component';
import { AppTooltipDirective } from 'directives/tooltip.directive';

import { DataManagerComponent } from './data-manager/data-manager.component';

import { EquipmentTypeListComponent } from './equipment-type/equipment-type-list/equipment-type-list.component';
import { EquipmentTypeTableComponent } from './equipment-type/equipment-type-table/equipment-type-table.component';
import { EquipmentTypeEditDialogComponent } from './equipment-type/equipment-type-edit-dialog/equipment-type-edit-dialog.component';
import { EquipmentTypeTemplateComponent } from './equipment-type/equipment-type-template/equipment-type-template.component';
import { EquipmentTypeCreateComponent } from './equipment-type/equipment-type-create/equipment-type-create.component';
import { EquipmentTypeDropdownMultipleComponent } from './equipment-type/equipment-type-dropdown-multiple/equipment-type-dropdown-multiple.component';

import { VendorCreateComponent } from './vendor/vendor-create/vendor-create.component';
import { VendorEditDialogComponent } from './vendor/vendor-edit-dialog/vendor-edit-dialog.component';
import { VendorListComponent } from './vendor/vendor-list/vendor-list.component';
import { VendorTableComponent } from './vendor/vendor-table/vendor-table.component';
import { VendorTemplateComponent } from './vendor/vendor-template/vendor-template.component';
import { VendorDropdownMultipleComponent } from './vendor/vendor-dropdown-multiple/vendor-dropdown-multiple.component';

import { DateHelper } from '_extentions/date-helper';
import { EquipmentListBuadminComponent } from './equipment/equipment-list/equipment-list-buadmin/equipment-list-buadmin.component';
import { EquipmentListVendorComponent } from './equipment/equipment-list/equipment-list-vendor/equipment-list-vendor.component';
import { EquipmentHintComponent } from './equipment/equipment-hint/equipment-hint.component';
import { EquipmentCommentComponent } from './equipment/equipment-comment/equipment-comment.component';
import { EquipmentViewDialogComponent } from './equipment/equipment-view-dialog/equipment-view-dialog.component';
import { PoViewDialogComponent } from './purchase-order/po-view-dialog/po-view-dialog.component';
import { ProjectViewDialogComponent } from './project/project-view-dialog/project-view-dialog.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserTableComponent } from './user/user-table/user-table.component';
import { SearchPanelComponent } from './shared/search-panel/search-panel.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { UserTemplateComponent } from './user/user-template/user-template.component';
import { UserDropdownComponent } from './user/user-dropdown/user-dropdown.component';
import { RoleDropdownComponent } from './role/role-dropdown/role-dropdown.component';
import { VendorDropdownComponent } from './vendor/vendor-dropdown/vendor-dropdown.component';
import { UserEditDialogComponent } from './user/user-edit-dialog/user-edit-dialog.component';
import { ProcurementSyncComponent } from './procurement-sync/procurement-sync.component';
import { EquipmentHistoryComponent } from './equipment/equipment-history/equipment-history.component';
import { CostTransferImportComponent } from './cost-transfer-import/cost-transfer-import.component';
import { PoGapsListComponent } from './po-gaps/po-gaps-list/po-gaps-list.component';
import { PoGapsTableComponent } from './po-gaps/po-gaps-table/po-gaps-table.component';
import { PoGapsPageComponent } from './po-gaps/po-gaps-page/po-gaps-page.component';
import { ProjectSelectDialogComponent } from './project/project-select-dialog/project-select-dialog.component';
import { CostCentersSelectComponent } from './cost-center/cost-centers-select/cost-centers-select.component';

import { MilestoneTypeListComponent } from './milestone-type/milestone-type-list/milestone-type-list.component';
import { MilestoneTypeTableComponent } from './milestone-type/milestone-type-table/milestone-type-table.component';
import { MilestoneEditDialogComponent } from './milestone-type/milestone-type-edit-dialog/milestone-type-edit-dialog.component';
import { MilestoneTemplateComponent } from './milestone-type/milestone-template/milestone-template.component';
import { MilestoneTypeCreateComponent } from '#components/milestone-type/milestone-type-create/milestone-type-create.component';

import { AreaListComponent } from '#components/area/area-list/area-list.component';
import { AreaTypeTableComponent } from '#components/area/area-table/area-table.component';
import { AreaCreateComponent } from '#components/area/area-create/area-create.component';
import { AreaEditDialogComponent } from '#components/area/area-edit-dialog/area-edit-dialog.component';
import { AreaTemplateComponent } from '#components/area/area-template/area-template.component';
import { DemandSupplyComponent } from './demand-supply/demand-supply.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { associatedPurchaseOrderLineItemsComponent } from '#components/equipment/associated-purchase-order-line-items/associated-purchase-order-line-items.component';
import { linkPOInfoFormComponent } from './linkedPOInfo/link-po-info-form/link-po-info-form.component';
import { ProjectTypeCreateComponent } from './project-type/project-type-create/project-type-create.component';
import { ProjectTypeEditDialogComponent } from './project-type/project-type-edit-dialog/project-type-edit-dialog.component';
import { ProjectTypeListComponent } from './project-type/project-type-list/project-type-list.component';
import { ProjectTypeTableComponent } from './project-type/project-type-table/project-type-table.component';
import { ProjectTypeTemplateComponent } from './project-type/project-type-template/project-type-template.component';
import { DrawingListComponent } from './drawing/drawing-list/drawing-list.component';
import { DrawingTableComponent } from './drawing/drawing-table/drawing-table.component';
import { DrawingEditDialogComponent } from './drawing/drawing-edit-dialog/drawing-edit-dialog.component';
import { DrawingTemplateComponent } from './drawing/drawing-template/drawing-template.component';
import { DrawingCreateComponent } from './drawing/drawing-create/drawing-create.component';
import { EquipmentDrawingComponent } from './equipment/equipment-drawing/equipment-drawing.component';
import { EquipmentDrawingCreateDialogComponent } from './equipment/equipment-drawing-create-dialog/equipment-drawing-create-dialog.component';


@NgModule({
    declarations: [
        HomeComponent,
        LoginComponent,
        DateFormatPipe,
        FileSizePipe,
        EquipmentTableComponent,
        AutocompleteDropdownComponent,
        FilterPanelComponent,
        PageHeaderComponent,
        EmptyListComponent,
        ConfirmationDialogComponent,
        ProjectListComponent,
        ProjectEditComponent,
        ProjectCreateComponent,
        ProjectTableComponent,
        ProjectTemplateComponent,
        ProjectSelectDialogComponent,
        EquipmentListComponent,
        EquipmentCreateComponent,
        EquipmentTemplateComponent,
        EquipmentSelectDialogComponent,
        EquipmentCreateDialogComponent,
        EquipmentEditDialogComponent,
        EquipmentListBuadminComponent,
        EquipmentListVendorComponent,
        EquipmentHistoryComponent,
        EquipmentTypeDropdownMultipleComponent,
        VendorDropdownMultipleComponent,
        PoListComponent,
        PoTemplateComponent,
        PoEditDialogComponent,
        PoTableComponent,
        PoCreateComponent,
        PoDropdownMultipleComponent,
        ReshufflingComponent,
        MilestoneImportComponent,
        DemandSupplyComponent,
        DragDropComponent,
        DragDropDirective,
        UserRoleUpDirective,
        TooltipComponent,
        AppTooltipDirective,
        OutlookReviewComponent,
        DataManagerComponent,
        EquipmentTypeListComponent,
        EquipmentTypeTableComponent,
        EquipmentTypeEditDialogComponent,
        EquipmentTypeTemplateComponent,
        EquipmentTypeCreateComponent,
        VendorCreateComponent,
        VendorEditDialogComponent,
        VendorListComponent,
        VendorTableComponent,
        VendorTemplateComponent,
        EquipmentHintComponent,
        EquipmentCommentComponent,
        EquipmentViewDialogComponent,
        PoViewDialogComponent,
        ProjectViewDialogComponent,
        UserListComponent,
        UserTableComponent,
        SearchPanelComponent,
        UserCreateComponent,
        UserTemplateComponent,
        UserDropdownComponent,
        RoleDropdownComponent,
        VendorDropdownComponent,
        UserEditDialogComponent,
        ProcurementSyncComponent,
        CostTransferImportComponent,
        PoGapsListComponent,
        PoGapsTableComponent,
        PoGapsPageComponent,
        ProjectSelectDialogComponent,
        EquipmentMilestoneHistoryComponent,
        CostCentersSelectComponent,
        MilestoneTypeListComponent,
        MilestoneTypeTableComponent,
        MilestoneEditDialogComponent,
        MilestoneTemplateComponent,
        MilestoneTypeCreateComponent,
        AreaListComponent,
        AreaTypeTableComponent,
        AreaCreateComponent,
        AreaTemplateComponent,
        AreaEditDialogComponent,
        DemandSupplyComponent,
        DashboardComponent,
        associatedPurchaseOrderLineItemsComponent,
        linkPOInfoFormComponent,
        ProjectTypeCreateComponent,
        ProjectTypeEditDialogComponent,
        ProjectTypeListComponent,
        ProjectTypeTableComponent,
        ProjectTypeTemplateComponent,
        DrawingListComponent,
        DrawingTableComponent,
        DrawingEditDialogComponent,
        DrawingTemplateComponent,
        DrawingCreateComponent,
        EquipmentDrawingComponent,
        EquipmentDrawingCreateDialogComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        // material
        FormsModule,
        AppMaterialModule,
        ReactiveFormsModule,
        // prime ng
        ButtonModule,
        MultiSelectModule,
        AutoCompleteModule,
    ],
    exports: [
        UserRoleUpDirective,
        AppMaterialModule
    ],
    providers: [
        LoadingIndicatorService,
        CommonHelper,
        DateHelper
    ]
})

export class ComponentsModule { }
