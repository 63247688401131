import { Component, OnInit } from '@angular/core';
import { Page } from '_interfaces/page';
import { DashboardSummary } from 'models/dashboard';
import { DashboardService } from '#services/api/dashboard.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements Page, OnInit {
  summary: DashboardSummary;

  constructor(private dashboardService: DashboardService) { }

  // page
  pageTitle = 'Dashboard';
  pageDescription = 'review summary';

  ngOnInit() {
    this.dashboardService.get()
      .pipe(take(1))
      .subscribe(data => {
        this.summary = data;
      });
  }
}
