import { Component, OnInit, ViewChild } from '@angular/core';
import { Dictionary, Equipment } from 'models';
import { Page } from '_interfaces/page';
import { PostFilterBase } from 'models';
import { Observable } from 'rxjs';
import { DictionaryService, EquipmentService } from '#services/api';
import { take } from 'rxjs/operators';
import { Dictionaries } from 'enums/dictionary';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-equipment-list-vendor',
  templateUrl: './equipment-list-vendor.component.html',
  styleUrls: ['./equipment-list-vendor.component.scss']
})
export class EquipmentListVendorComponent extends PostFilterBase implements OnInit, Page {
  @ViewChild('deliveryStatusesSelect')
  deliveryStatusesSelect;

  // page
  pageTitle = 'Equipment';
  pageDescription = 'list of equipment';

  // collections
  deliveryStatuses: Dictionary[];
  selectedEquipmentTypeIds: number[];

  // other properties
  equipments: Equipment[];
  columnsToDisplay: Map<string, string> = new Map([
    ['SerialNumber', 'Serial #'],
    ['EquipmentType', 'Equipment type'],
    ['ProjectName', 'Project'],
    ['PurchaseOrderNumber', 'PO #'],
    ['PurchaseOrderLineNumber', 'PO line'],
    ['ProjectedFATDate', 'Projected FAT date'],
    ['ProjectRepresentative', 'Project representative'],
    ['ProjectPrimaryAFE', 'Project primary AFE'],
    ['IsAribaAFEMismatch', '='],
    ['AribaAFE', 'Ariba AFE'],
    ['DeliveryStatus', 'Status'],
    ['_info', ''],
    ['Comment', ''],
    ['NeedByDate', 'Need by date']
  ]);
  columnsToDisplayInHints: Map<string, string> = new Map([
    ['Vendor', ''],
    ['DeliveryStatus', ''],
    ['ActualShipDate', ''],
  ]);
  columnsToDisplayInComments: Map<string, string> = new Map([
    ['VendorComment', '']
  ]);

  deliveryStatusesControl = new FormControl();

  constructor(
    private equipmentService: EquipmentService,
    private dictionaryService: DictionaryService) {
    super();
    super.addFilterParam('includeInstalled', true);
  }

  ngOnInit() {
    this.initEquipments();
  }

  initEquipments() {
    this.fetchEquipments()
      .pipe(take(1))
      .subscribe(data => {
        this.setEquipments(data);
      });
  }

  fetchEquipments(): Observable<Equipment[]> {
    const body = super.getBodyParams();

    return this.equipmentService.getAll(body);
  }

  setEquipments(equipments: Equipment[]) {
    this.equipments = equipments;
  }

  get canShowEquipments() {
    return (this.equipments && this.equipments.length)
          || this.filter.paramsCount > 0;
  }

  async onLoadDeliveryStatuses() {
    if (!this.deliveryStatuses) {
        this.deliveryStatuses = await this.dictionaryService.getAll(
            `${Dictionaries.deliveryStatuses}/dictionary`).toPromise();
    }

    setTimeout(() => this.deliveryStatusesSelect.open());
}

  deliveryStatusesSelectClosed(event) {
    this.onFilterChanged();
  }

  async onFilterChanged() {
    await this.loadEquipments();

    this.onUpdateFilters(this.filter.search);
  }

  async loadEquipments() {
    this.updateHttpFilters();
    const body = super.getBodyParams();
    this.equipments = await this.equipmentService.getAll(body).toPromise();
  }

  updateHttpFilters() {
    super.clearFilterParams();

    if (this.deliveryStatuses) {
        const deliveryStatusesIDs = this.deliveryStatuses
            .filter(s => s['_checked'])
            .map(i => i.ID);

        if (deliveryStatusesIDs && deliveryStatusesIDs.length) {
            super.addFilterParam('deliveryStatuses', deliveryStatusesIDs);
        }
    }

    if (this.selectedEquipmentTypeIds && this.selectedEquipmentTypeIds.length) {
      super.addFilterParam('types', this.selectedEquipmentTypeIds);
    }
  }

  deliveryStatusesSelectionChange(event) {
    const ids = event.value.map(i => i.ID);

    for (let i = 0; i < this.deliveryStatuses.length; i++) {
        this.deliveryStatuses[i]['_checked'] = ids.includes(this.deliveryStatuses[i].ID);
    }
  }

  equipmentTypesSelectionChange(ids) {
    this.selectedEquipmentTypeIds = ids;
  }

}
