import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CRUDService } from '#services/api/crud.service';
import { Dictionary } from 'models';
import { environment } from '#environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class DictionaryService extends CRUDService<Dictionary> {
    constructor(http: HttpClient) {
        super(http, environment.apiEndpoint);
    }

    public getAll(dictionaryName: string, options = null): Observable<Dictionary[]> {
        return super.getAll(`${this.url}/${dictionaryName}`, options);
    }
}
