import { DrawingService } from '#services/api';
import { ToasterService } from '#services/shared';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Role } from 'enums';
import { Equipment, Filter } from 'models';
import { Drawing } from 'models/drawing';
import { take } from 'rxjs/operators';
import { CommonHelper } from '_extentions/common-helper';


export interface EquipmentDrawingCreateArgs {
  equipment: Equipment;
  selectedDrawing: any[];
}

@Component({
  selector: 'app-equipment-drawing-create-dialog',
  templateUrl: './equipment-drawing-create-dialog.component.html',
  styleUrls: ['./equipment-drawing-create-dialog.component.scss']
})

export class EquipmentDrawingCreateDialogComponent implements OnInit {
  // page
  pageTitle = 'Assign Drawing';

  selection = new SelectionModel<Drawing>(true, []);

  // other properties
  equipment: Equipment;
  filter: Filter = <Filter>{};
  Role = Role;
  drawings: Drawing[];
  selectedItems: Drawing[];
  isValid = false;

  public drawingColumnsToDisplay: Map<string, string> = new Map([
    ['ID', ''],
    ['Name', 'Drawing Name'],
    ['DocumentName', 'Link to document']
  ]);

  constructor(
    private drawingService: DrawingService,
    private toaster: ToasterService,
    public dialogRef: MatDialogRef<EquipmentDrawingCreateArgs>,
    @Inject(MAT_DIALOG_DATA) public data: EquipmentDrawingCreateArgs) { }

  ngOnInit() {
    this.equipment = CommonHelper.deepCopy(this.data.equipment);
    this.selectedItems = CommonHelper.deepCopy(this.data.selectedDrawing);

    if (this.equipment != null && this.equipment.EquipmentTypeID > 0) {
      this.initDrawings();
    }
    else {
      this.toaster.showSuccess('Success...', 'Drawing has been updated.');
    }
  }

  ngDoCheck(): void {
    if(this.selection && this.selection.selected.length>0) {      
      this.isValid = true;
    }
}

  async initDrawings() {
    this.drawingService
      .getDrawingbyEquipmentType(this.equipment.EquipmentTypeID)
      .pipe(
        take(1)
      ).subscribe(data => {
        this.drawings = data;

        let selectedDrawings = data.filter(x => this.selectedItems.some(o2 => o2.ID === x.ID));
        selectedDrawings.forEach(row => {
          this.selection.select(row);
        });

      });
  }

  onDrawingCollectionChanged(event) {
    if (event) {
      this.initDrawings();
    }
  }

  onSearchChanged(event: { value: string }) {
    if (!event) {
      return;
    }

    this.filter = {
      search: event.value,
      paramsCount: 0
    };
  }

  get canShowDrawings() {
    return this.drawings && this.drawings.length > 0;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSave(): void {
      this.dialogRef.close(this.selection.selected);    
  }

  onKey(event) {
    this.filter = <Filter>{
      search: event.target.value
    };
  }

  onClear(){
    this.selection.clear();
  }

}
