import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Dictionary } from 'models';
import { FormControl } from '@angular/forms';
import { DictionaryService } from '#services/api';
import { Dictionaries } from 'enums';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-role-dropdown',
  templateUrl: './role-dropdown.component.html',
  styleUrls: ['./role-dropdown.component.scss']
})
export class RoleDropdownComponent implements OnInit {
  items: Dictionary[];

  @Input() parentFormControl: FormControl;
  @Input() appearance = 'outline';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() defaultValue: Dictionary;
  @Output() changed = new EventEmitter();

  constructor(private dictionaryService: DictionaryService) { }

  ngOnInit() {
    this.initRoles();
  }

  initRoles() {
    this.fetchRoles()
      .pipe(take(1))
      .subscribe(data => {
        this.setRoles(data);
      });
  }

  fetchRoles(): Observable<Dictionary[]> {
    return this.dictionaryService
      .getAll(`${Dictionaries.roles}/dictionary`);
  }

  setRoles(data: Dictionary[]) {
    this.items = data;
  }

  onItemChanged(event) {
    this.changed.emit({ value: event.value });
  }
}
