import { Component } from '@angular/core';
import { Page } from '_interfaces/page';
import { Equipment, Dictionary } from 'models';
import { DictionaryService, EquipmentService } from '#services/api';
import { Dictionaries } from 'enums';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-demand-supply',
  templateUrl: './demand-supply.component.html',
  styleUrls: ['./demand-supply.component.scss']
})
export class DemandSupplyComponent implements Page {

  // page
  pageTitle = 'Demand & supply';
  pageDescription = 'select equipment type to get demand & supply details';

  // other properties
  demandItems: Equipment[];
  supplyItems: Equipment[];
  equipmentTypes: Dictionary[];
  selectedTypeId: number;
  selectedFabricatorId: number;

  demandColumnsToDisplay: Map<string, string> = new Map([
    ['Locked', ''],
    ['ProjectName', 'Project'],
    ['Vendor', 'Vendor'],
    ['ProjectedFATDate', 'Projected FAT date'],
    ['NeedByDate', 'Need By Date'],
    ['IsNonStandard', ''],
    ['_info', ''],
    ['Comment', ''],
    ['DeliveryStatus', 'Status'],
    ['Menu', '']
  ]);

  supplyColumnsToDisplay: Map<string, string> = new Map([
    ['ProjectName', 'Project'],
    ['Vendor', 'Vendor'],
    ['ProjectedFATDate', 'Projected FAT date'],
    ['NeedByDate', 'Need By Date'],
    ['IsNonStandard', ''],
    ['_info', ''],
    ['Comment', ''],
    ['DeliveryStatus', 'Status']
  ]);

  get supplyItemsCount(): number {
    return !this.supplyItems
      ? 0
      : this.supplyItems
        .filter(i => i.ID > 0)
        .length;
  }

  get demandItemsCount(): number {
    return !this.demandItems
      ? 0
      : this.demandItems
        .filter(i => i.ID > 0)
        .length;
  }

  async onLoadEquipmentTypes() {
    if (!this.equipmentTypes) {
      this.equipmentTypes = await this.dictionaryService.getAll(
        `${Dictionaries.equipmentTypes}/dictionary`).toPromise();
    }
  }

  onEquipmentTypeChanged(event) {
    if (event.value) {
      this.selectedTypeId = (<Dictionary>event.value).ID;
      this.reload();
    }
  }

  onFabricatorChanged(event) {
    if (event.value) {
        this.selectedFabricatorId = (<Dictionary>event.value).ID;
        this.reload();
    }
}

  reload() {
    this.equipmentService
      .demandSupply(this.selectedTypeId, this.selectedFabricatorId)
      .pipe(take(1))
      .subscribe(data => {
        this.supplyItems = data.map(i => i.Supply).filter(i => i);
        this.demandItems = data.map(i => i.Demand).filter(i => i);
      });
  }

  constructor(
    private dictionaryService: DictionaryService,
    private equipmentService: EquipmentService
  ) { }
}
