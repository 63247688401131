import { Component } from '@angular/core';
import { Page } from '_interfaces/page';
import { EquipmentTypeService } from '#services/api/equipment-type.service';
import { ToasterService } from '#services/shared';
import { EquipmentType } from 'models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-equipment-type-create',
  templateUrl: './equipment-type-create.component.html',
  styleUrls: ['./equipment-type-create.component.scss']
})
export class EquipmentTypeCreateComponent implements Page {
  // page
  pageTitle = 'Add new equipment type';
  pageDescription = 'edit data below to create equipment type';

  // other properties
  newEquipmentType: EquipmentType = new EquipmentType(0, null);
  isValid = false;

  constructor(
    private equipmentTypeService: EquipmentTypeService,
    private router: Router,
    private toaster: ToasterService
  ) { }
  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }

  async onSubmit() {
    await this.equipmentTypeService.create(this.newEquipmentType).toPromise();

    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 1 } });
    this.toaster.showSuccess('Success...', 'Equipment type has been added.');
  }

  onCancel() {
    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 1 } });
  }
}
