import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  AppComponent, HeaderComponent, FooterComponent, MainNavMenuComponent,
  LoadingIndicatorComponent, VersionComponent
} from './';
import { SpeedDialFabComponent } from './speed-dial-fab/speed-dial-fab.component';
import { AppRoutingModule } from './app.routing.module';
import { LoadingIndicatorService, AuthService } from '#services/shared';
import { SharedModule } from '#services/shared/shared.module';
import { DatetimeParseInterceptor, LoadingInterceptor, ErrorInterceptor } from '#services/interceptors';
import { AppMaterialModule } from './app.material.module';
import { SideNavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { SpeedButtonService } from '#services/shared/speed-button.service';
import { SidenavVendorComponent } from './navigation/sidenav-list/sidenav-vendor/sidenav-vendor.component';
import { SidenavBuadminComponent } from './navigation/sidenav-list/sidenav-buadmin/sidenav-buadmin.component';
import { SidenavUserComponent } from './navigation/sidenav-list/sidenav-user/sidenav-user.component';
import { CommonModule } from '@angular/common';
import { ApiModule } from '#services/api/api.module';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalService } from '@azure/msal-angular';
import { GraphService } from '#services/shared/graph.service';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from '#services/api/msal.interceptor';
import { ComponentsModule } from '#components/components.module';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainNavMenuComponent,
    LoadingIndicatorComponent,
    VersionComponent,
    SideNavListComponent,
    SidenavVendorComponent,
    SidenavBuadminComponent,
    SidenavUserComponent,
    SpeedDialFabComponent
  ],
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AppMaterialModule,
    ToastModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,

    // App
    ApiModule,
    SharedModule
    
  ],
  providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
      },
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
      },
    { provide: HTTP_INTERCEPTORS, useClass: DatetimeParseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
   
    MsalService,
    MsalGuard,
    MsalBroadcastService,    
    GraphService,
    AuthService,
    LoadingIndicatorService,
    SpeedButtonService,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
