import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[appDragDrop]'
})

export class DragDropDirective {
    @Output()
    fileDropped = new EventEmitter<any>();

    @HostBinding('style.borderRadius')
    public borderRadius = '10px';

    @HostBinding('style.border')
    public border = '2px hidden';

    @HostListener('dragover', ['$event'])
    onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.showBorder(true);
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.showBorder(false);
    }

    @HostListener('drop', ['$event'])
    public ondrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.showBorder(false);

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }

    showBorder(show: boolean) {
        const border = '2px dashed';

        this.border = show
            ? border + ' #1C8ADB'
            : border + ' transparent';
    }
}
