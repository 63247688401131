import { Component, OnInit, ViewChild } from '@angular/core';
import { PostFilterBase } from 'models';
import { Page } from '_interfaces/page';
import { EquipmentTableComponent } from '#components/equipment/equipment-table/equipment-table.component';
import { Project, Dictionary, Equipment } from 'models';
import { FormControl } from '@angular/forms';
import { EquipmentService, DictionaryService, ProjectService } from '#services/api';
import { Dictionaries } from 'enums/dictionary';
import { Role } from 'enums';
@Component({
  selector: 'app-equipment-list-buadmin',
  templateUrl: './equipment-list-buadmin.component.html',
  styleUrls: ['./equipment-list-buadmin.component.scss']
})
export class EquipmentListBuadminComponent extends PostFilterBase implements OnInit, Page {
  @ViewChild(EquipmentTableComponent)
  equipmentTable: EquipmentTableComponent;

  @ViewChild('projectsSelect')
  projectsSelect;

  @ViewChild('deliveryStatusesSelect')
  deliveryStatusesSelect;

  // page
  pageTitle = 'Equipment';
  pageDescription = 'list of equipment';

  // collections
  projects: Project[];
  deliveryStatuses: Dictionary[];
  selectedEquipmentTypeIds: number[];
  selectedVendorIds: number[];
  selectedPurchaseOrderIds: number[];
  projectedFATDateFrom: Date;
  projectedFATDateTo: Date;
  includeInstalled: boolean;

  // other properties
  equipments: Equipment[];
  columnsToDisplay: Map<string, string> = new Map([
      ['Locked', ''],
      ['SerialNumber', 'Serial #'],
      ['IsNonStandard', ''],
      ['EquipmentType', 'Equipment type'],
      ['Vendor', 'Vendor'],
      ['ProjectPrimaryAFE', 'Project primary AFE'],
      ['IsAribaAFEMismatch', '='],
      ['AribaAFE', 'Ariba AFE'],
      ['AribaCost', 'Ariba Cost'],
      ['CostTransferAFE', 'Cost Transfer AFE'],
      ['ProjectName', 'Project'],
      ['PurchaseOrderNumber', 'PO #'],
      ['PurchaseOrderLineNumber', 'PO line'],
      ['OrderType', 'PO type'],
      ['ProjectedFATDate', 'Projected FAT date'],
      ['_info', ''],
      ['Comment', ''],
      ['Installed', ''],
      ['DeliveryStatus', 'Status'],
      ['Menu', '']
  ]);
  Role = Role;

  projectsControl = new FormControl();
  deliveryStatusesControl = new FormControl();

  constructor(
      private equipmentService: EquipmentService,
      private dictionaryService: DictionaryService,
      private projectService: ProjectService
  ) {
      super();

  }

  async ngOnInit() {
      this.loadEquipments();
  }

  get canShowEquipments() {
      return (this.equipments && this.equipments.length)
          || this.filter.paramsCount > 0;
  }

  async loadEquipments() {
      this.updateHttpFilters();
      const body = super.getBodyParams();
      this.equipments = await this.equipmentService.getAll(body).toPromise();
  }

  async onLoadProjects() {
      if (!this.projects) {
          const initial = {
              ID: null,
              Name: 'Spare'
          } as Project;

          const fetched = await this.projectService.getAll().toPromise();
          const sorted = fetched.sort((a, b) => a.Name.localeCompare(b.Name));

          this.projects = [initial].concat(sorted);
      }

      setTimeout(() => this.projectsSelect.open());
  }

  projectsSelectClosed(event) {
      this.onFilterChanged();
  }

  projectsSelectionChange(event) {
      const ids = event.value.map(i => i.ID);

      for (let i = 0; i < this.projects.length; i++) {
          this.projects[i]['_checked'] = ids.includes(this.projects[i].ID);
      }
  }

  async onLoadDeliveryStatuses() {
      if (!this.deliveryStatuses) {
          this.deliveryStatuses = await this.dictionaryService.getAll(
              `${Dictionaries.deliveryStatuses}/dictionary`).toPromise();
      }

      setTimeout(() => this.deliveryStatusesSelect.open());
  }

  deliveryStatusesSelectClosed(event) {
      this.onFilterChanged();
  }

  deliveryStatusesSelectionChange(event) {
      const ids = event.value.map(i => i.ID);

      for (let i = 0; i < this.deliveryStatuses.length; i++) {
          this.deliveryStatuses[i]['_checked'] = ids.includes(this.deliveryStatuses[i].ID);
      }
  }

  equipmentTypesSelectionChange(ids) {
      this.selectedEquipmentTypeIds = ids;
  }

  vendorSelectionChange(ids) {
      this.selectedVendorIds = ids;
  }

  purchaseOrderSelectionChange(ids) {
      this.selectedPurchaseOrderIds = ids;
  }

  async onFilterChanged() {
      await this.loadEquipments();

      this.onUpdateFilters(this.filter.search);
  }

  get allColumns(): string[] {
      return Array.from(this.columnsToDisplay.keys());
  }

  updateHttpFilters() {
      super.clearFilterParams();

      if (this.projects) {
          const projectIDs = this.projects
              .filter(s => s['_checked'])
              .map(i => i.ID);

          if (projectIDs && projectIDs.length) {
              super.addFilterParam('projects', projectIDs);
          }
      }

      if (this.deliveryStatuses) {
          const deliveryStatusesIDs = this.deliveryStatuses
              .filter(s => s['_checked'])
              .map(i => i.ID);

          if (deliveryStatusesIDs && deliveryStatusesIDs.length) {
              super.addFilterParam('deliveryStatuses', deliveryStatusesIDs);
          }
      }

      if (this.selectedEquipmentTypeIds && this.selectedEquipmentTypeIds.length) {
          super.addFilterParam('types', this.selectedEquipmentTypeIds);
      }

      if (this.selectedVendorIds && this.selectedVendorIds.length) {
          super.addFilterParam('fabricators', this.selectedVendorIds);
      }

      if (this.selectedPurchaseOrderIds && this.selectedPurchaseOrderIds.length) {
          super.addFilterParam('purchaseOrders', this.selectedPurchaseOrderIds);
      }

      if (this.projectedFATDateFrom) {
          super.addFilterParam('fromProjectedFATDate', this.projectedFATDateFrom);
      }

      if (this.projectedFATDateTo) {
          super.addFilterParam('toProjectedFATDate', this.projectedFATDateTo);
      }

      if (this.includeInstalled) {
          super.addFilterParam('includeInstalled', this.includeInstalled);
      }
  }
}

