import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '_interfaces/page';
import { ProjectService, DictionaryService } from '#services/api';
import { CommonHelper } from '_extentions/common-helper';
import { ReviewStatus } from 'enums/review-status';
import { FabButton, User, Dictionary, Project } from 'models';
import { SpeedButtonService } from '#services/shared/speed-button.service';
import { Dictionaries } from 'enums/dictionary';
import { ProjectTableComponent } from '#components/project/project-table/project-table.component';
import { ProjectStatus } from 'enums/project-status';
import { GetFilterBase } from 'models';
import { Role } from 'enums';

@Component({
    selector: 'app-outlook-review',
    templateUrl: './outlook-review.component.html',
    styleUrls: ['./outlook-review.component.scss']
})
export class OutlookReviewComponent extends GetFilterBase implements OnInit, OnDestroy, Page {
    @ViewChild(ProjectTableComponent)
    projectTable: ProjectTableComponent;

    // page
    pageTitle = 'Outlook Review';
    pageDescription = 'review projects';

    // table
    projects: Project[];
    selectedProjects: Project[];

    // collections
    statuses: Dictionary[];
    projectStatuses: Dictionary[];
    types: Dictionary[];

    // filters
    reviewedDateFrom: Date;
    reviewedDateTo: Date;
    status: ReviewStatus;
    typeID: Dictionary;
    projectStatus: Dictionary;
    user: User;

    projectColumnsToDisplay: Map<string, string> = new Map([
        ['_checked', ''],
        ['Number', 'Project #'],
        ['Name', 'Name'],
        ['Representative', 'Representative Person'],
        ['CDName', 'C&D Name'],
        ['Type', 'Type'],
        ['NeedByDate', 'Need by date'],
        ['ReviewDate', 'Reviewed'],
        ['Status', 'Status'],
        ['HasNonStandardEquipments', ''],
        ['Comment', ''],
        ['ReviewStatus', ''],
        ['EquipmentsNeed', 'Eq. Goal'],
        ['Menu', '']
    ]);

    Role = Role;

    fabButtons: FabButton[] = [
        new FabButton(() => this.markAsNotReviewed(), 'Not reviewed', 'priority_high')
    ];

    constructor(
        private router: Router,
        private projectService: ProjectService,
        public helper: CommonHelper,
        private speedButtonService: SpeedButtonService,
        private dictionaryService: DictionaryService) {
        super();
    }

    ngOnInit() {
        this.loadProjects();
    }

    ngOnDestroy(): void {
        this.speedButtonService.clear();
    }

    get canShowProjects() {
        return (this.projects && this.projects.length)
            || this.filter.paramsCount > 0;
    }

    get filtersCount(): number {
        return this.projectStatus
            ? this.filter.paramsCount
            : this.filter.paramsCount - 1;
    }

    async loadProjects() {
        this.updateHttpFilters();
        this.projects = await this.projectService.getAll(null, this.httpParams).toPromise();
    }

    async onLoadStatuses() {
        if (!this.statuses) {
            this.statuses = await this.dictionaryService.getAll(
                `${Dictionaries.reviewStatuses}/dictionary`).toPromise();
        }
    }

    async onLoadProjectStatuses() {
        if (!this.projectStatuses) {
            this.projectStatuses = await this.dictionaryService.getAll(
                `${Dictionaries.projectStatuses}/dictionary`).toPromise();
        }
    }

    async markAsNotReviewed() {
        this.projectTable.changeReviewStatus(
            this.selectedProjects.map(i => i.ID),
            ReviewStatus.notReviewed);
    }

    // events
    checkedItemsChanged(projects: Project[]) {
        this.selectedProjects = projects;

        if (projects.length > 1) {
            this.speedButtonService.update(this.fabButtons);
        } else {
            this.speedButtonService.clear();
        }
    }

    onCreateProject() {
        this.router.navigate(['/project-create/']);
    }

    onEditProject(id: number) {
        this.router.navigate(['/project-details/', { id: id }]);
    }

    async onLoadTypes() {
        if (!this.types) {
            this.types = await this.dictionaryService.getAll(
                `${Dictionaries.projectTypes}/dictionary`).toPromise();
        }
    }

    // filters
    async onFilterStatusChanged(event) {
        if (!event.value && !this.status) {
            return;
        }

        this.status = event.value
            ? event.value.ID as ReviewStatus
            : null;

        this.onFilterChanged();
    }

    async onFilterProjectStatusChanged(event) {
        if (!event.value && !this.projectStatus) {
            return;
        }

        this.projectStatus = event.value
            ? event.value.ID
            : null;

        this.onFilterChanged();
    }

    async onFilterTypeChanged(event) {
        if (!event.value && !this.typeID) {
            return;
        }

        this.typeID = event.value
            ? event.value.ID
            : null;

        this.onFilterChanged();
    }

    async onFilterChanged() {
        await this.loadProjects();

        super.onUpdateFilters(this.filter.search);
        this.projectTable.reloadSource(this.projects);
    }

    onRepresentativePersonChanged(event) {
        if (!event.value && !this.user) {
            return;
        }

        this.user = event.value as User;
        this.onFilterChanged();
    }

    updateHttpFilters() {
        super.clearFilterParams();

        if (this.projectStatus) {
            super.addFilterParam('statuses', this.projectStatus.toString());
        } else {
            super.addFilterParam('statuses', ProjectStatus.draft.toString());
            super.addFilterParam('statuses', ProjectStatus.active.toString());
        }

        if (this.user) {
            super.addFilterParam('representativeEmail', this.user.Email);
        }

        if (this.typeID) {
            super.addFilterParam('type', this.typeID.toString());
        }

        if (this.status) {
            super.addFilterParam('statusReview', this.status.toString());
        }

        if (this.reviewedDateFrom) {
            super.addFilterParam('fromReviewDate', this.reviewedDateFrom.toLocaleDateString());
        }

        if (this.reviewedDateTo) {
            super.addFilterParam('toReviewDate', this.reviewedDateTo.toLocaleDateString());
        }
    }
}
