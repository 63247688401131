import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { User } from 'models';
import { CRUDService } from './crud.service';

@Injectable()
export class UserService extends CRUDService<User> {
    constructor(http: HttpClient) {
        super(http, `${environment.apiEndpoint}/users`);
    }

    public getCurrentUser(): Observable<User> {
        return this.http.get<User>(`${environment.apiEndpoint}/users/current`);
    }

    public acceptDisclaimer(): Observable<User> {
        console.log(`acceptDisclaimer:: ${environment.apiEndpoint}/users/accept-disclaimer`)
        return this.http.put<User>(`${environment.apiEndpoint}/users/accept-disclaimer`, null);
    }

    public enable(id: number): Observable<any> {
        return this.http.put<any>(`${this.url}/${id}/enable`, null);
    }

    public disable(id: number): Observable<any> {
        return this.http.put<any>(`${this.url}/${id}/disable`, null);
    }
}
