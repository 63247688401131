<form #vendorListForm="ngForm">
    <mat-toolbar class="page-toolbar" *ngIf="canShowVendors">
        <span class="project-count">
            {{vendors && vendors.length}} vendors
        </span>
        <span class="fill-remaining-space"></span>

        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary margin-left-md"
            [routerLink]="['/vendor-create']">
            Add new vendor
        </button>
    </mat-toolbar>

    <div *ngIf="canShowVendors; else noVendorsTemplate">
        <mat-divider></mat-divider>
        
        <app-search-panel 
            (searchChanged)="onSearchChanged($event)">
        </app-search-panel>
        
        <app-vendor-table
            [data]="vendors"
            [columnsToDisplay]="vendorsColumnsToDisplay"
            [filter]="filter"
            (collectionChanged)="onVendorCollectionChanged($event)">
        </app-vendor-table>
    </div>
</form>

<ng-template #noVendorsTemplate>
    <app-empty-list-holder
        [description]="'No vendors added yet.'">
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary" 
            [routerLink]="'/vendor-create'">
            Add new vendor
        </button>
    </app-empty-list-holder>
</ng-template>