<div class="page-with-shadow">
  <app-page-header [title]="pageTitle" [description]="pageDescription">
  </app-page-header>

  <mat-tab-group [(selectedIndex)]="tabIndex">
      <mat-tab label="User">
        <app-user-list></app-user-list>
      </mat-tab>
      <mat-tab label="Equipment type">
        <app-equipment-type-list></app-equipment-type-list>
      </mat-tab>
      <mat-tab label="Vendor">
        <app-vendor-list></app-vendor-list>
      </mat-tab>
      <mat-tab label="Milestone Type">
        <milestone-type-list></milestone-type-list>
      </mat-tab>
      <mat-tab label="Area">
        <area-list></area-list>
      </mat-tab>
      <mat-tab label="Project Type">
        <app-project-type-list></app-project-type-list>
      </mat-tab>
      <mat-tab label="Drawing">
        <app-drawing-list></app-drawing-list>
      </mat-tab>
  </mat-tab-group>
</div>
