import { Component, EventEmitter, Output, Input, DoCheck } from '@angular/core';
import { Dictionary } from 'models';

@Component({
  selector: 'area-template',
  templateUrl: './area-template.component.html',
  styleUrls: ['./area-template.component.scss']
})
export class AreaTemplateComponent implements DoCheck {
  @Input() area: Dictionary;
  @Output() isValidChanged = new EventEmitter();

  ngDoCheck(): void {
    this.isValidChanged.emit(!this.isValid);
  }

  get isValid(): boolean {
    if (!this.area) {
      return false;
    }

    return !this.area.Name;
  }
}
