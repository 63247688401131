<mat-nav-list>
    <div class="col">
        <h3 matSubheader>General</h3>
        <a mat-list-item routerLink="/home" (click)="onItemClick()">
            <mat-icon>home</mat-icon>
            <span class="nav-caption">Home</span>
        </a>
        <a mat-list-item routerLink="/equipments" (click)="onItemClick()">
            <mat-icon>view_list</mat-icon>
            <span class="nav-caption">Equipment</span>
        </a>
        <br>

        <mat-divider></mat-divider>

        <h3 matSubheader>Tools</h3>
        <a mat-list-item routerLink="/tools/milestone-import" (click)="onItemClick()">
            <mat-icon>cloud_upload</mat-icon>
            <span class="nav-caption">Milestone import</span>
        </a>
        <br>

        <mat-divider></mat-divider>
    </div>
</mat-nav-list>