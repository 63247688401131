<form #projectListForm="ngForm" class="page-with-shadow">
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>
    
    <mat-toolbar class="page-toolbar" *ngIf="canShowProjects">
        <span class="project-count">
            {{projects && projects.length}} projects
        </span>
        <span class="fill-remaining-space"></span>

        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-default" 
            color="primary" 
            [routerLink]="['/tools/reshuffling']">
            Reshuffling
            <mat-icon class="shuffle-icon">shuffle</mat-icon>
        </button>
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary margin-left-md"
            [routerLink]="['/project-create']">
            Add new project
        </button>
    </mat-toolbar>    

    <div *ngIf="canShowProjects; else noProjectsTemplate">
        <mat-divider></mat-divider>
        <app-filter-panel [paramsCount]="filter.paramsCount" (searchChanged)="onUpdateFilters($event)">
            <div class="row fx-100">
                <mat-form-field class="fx-10">
                    <mat-label>Need by date (from)</mat-label>
                    <input matInput #fromRef 
                        [(ngModel)]="needByDateFrom"
                        [ngModelOptions]="{standalone: true}" 
                        [matDatepicker]="needByDateFromDatePicker"
                        placeholder="need by date from"
                        (ngModelChange)="onFilterChanged()">
                    <mat-datepicker-toggle matSuffix 
                        [for]="needByDateFromDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #needByDateFromDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="fx-10">
                    <mat-label>Need by date (to)</mat-label>
                    <input matInput #nbdRef 
                        [(ngModel)]="needByDateTo" 
                        [ngModelOptions]="{standalone: true}"
                        [matDatepicker]="needByDateToPicker"
                        placeholder="need by date to"
                        (ngModelChange)="onFilterChanged()">
                    <mat-datepicker-toggle matSuffix 
                        [for]="needByDateToPicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #needByDateToPicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="fx-15 margin-left-md" (click)="onLoadStatuses();">
                    <mat-label>Statuses</mat-label>
                    <mat-select 
                        #statusSelect
                        placeholder="select project statuses" 
                        [formControl]="statusControl"
                        (closed)="statusSelectClosed($event)"
                        (selectionChange)="statusSelectionChange($event)"
                        disableOptionCentering
                        multiple>
                        <mat-select-trigger>
                            {{statusControl.value?.length > 0 ? statusControl.value[0].Name : ''}}
                            <span 
                                *ngIf="statusControl.value?.length > 1" 
                                class="additional-selection">
                                    (+{{statusControl.value.length - 1}} 
                                    {{statusControl.value?.length === 2 ? 'other' : 'others'}})
                            </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let status of statuses" [value]="status">
                            {{status.Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <app-autocomplete-dropdown class="fx-10"
                    [parentFormControl]="projectListForm.control" 
                    [options]="types"
                    [label]="'Type'"
                    [displayProperty]="'Name'"
                    [placeholder]="'project type'"
                    [appearance]="'outline'"
                    (selectedChanged)='onFilterTypeChanged($event)'
                    (clicked)="onLoadTypes()">
                </app-autocomplete-dropdown>

                <app-autocomplete-dropdown class="fx-10"
                    [parentFormControl]="projectListForm.control" 
                    [options]="afes"
                    [label]="'AFE'"
                    [displayProperty]="'Name'"
                    [placeholder]="'AFE options'"
                    [appearance]="'outline'"
                    (selectedChanged)='onFilterAFEChanged($event)'>
                </app-autocomplete-dropdown>

                <app-user-dropdown class="fx-20 margin-left-md"
                    [appearance]="'outline'"
                    [label]="'Representative person'"
                    (changed)="onRepresentativePersonChanged($event)">
                </app-user-dropdown>
                
                <mat-slide-toggle class="fx-10" style="align-self: center;"
                    [(ngModel)]="includeArchived" 
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onFilterChanged()">
                    Archived
                </mat-slide-toggle>

                <mat-slide-toggle class="fx-10" style="align-self: center;"
                    [(ngModel)]="includeTemplate" 
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onFilterChanged()">
                    Templates
                </mat-slide-toggle>
            </div>
        </app-filter-panel>

        <app-project-table
            [data]="projects"
            [columnsToDisplay]="projectColumnsToDisplay"
            [filter]="filter"
        >
        </app-project-table>
    </div>
</form>

<ng-template #noProjectsTemplate>
    <app-empty-list-holder
        [description]="'No projects added yet.'">
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary" 
            [routerLink]="'/project-create'">
            Add new project
        </button>
    </app-empty-list-holder>
</ng-template>