<div class="col">
    <mat-table 
        *ngIf="canShowTable; else noMatchesTemplate"
        matSort 
        [dataSource]="dataSource" 
        class="mat-table-container">
        <ng-container 
            matColumnDef="{{column}}" 
            *ngFor="let column of allColumns">

            <ng-container>
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{getColumnTitle(column)}}
                </mat-header-cell>
            </ng-container>

            <mat-header-cell *matHeaderCellDef>
                {{getColumnTitle(column)}}
            </mat-header-cell>

            <mat-cell class="mat-cell-value" *matCellDef="let item">
                <ng-container
                    [ngTemplateOutlet]="columnContent"
                    [ngTemplateOutletContext]="{item: item, column: column}">
                </ng-container>
            </mat-cell>
            
        </ng-container>

        <mat-header-row *matHeaderRowDef="allColumns; sticky: true"></mat-header-row>
        <mat-row
            *matRowDef="let row; columns: allColumns; let entry"
            class="element-row">
        </mat-row>
    </mat-table>
</div>

<mat-paginator 
    [pageSizeOptions]="[10, 25, 50]" 
    showFirstLastButtons>
</mat-paginator>

<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item
            (click)="onEditMilestone(item)">
            <mat-icon>edit</mat-icon>
            Edit
        </button>
    </ng-template>
</mat-menu>

<ng-template #noMatchesTemplate>
    <app-empty-list-holder
        [icon]="'search'"
        [description]="'No matches'">
    </app-empty-list-holder>
</ng-template>

<ng-template #columnContent let-item="item" let-column="column">
    <ng-container [ngSwitch]="column">
        <ng-container *ngSwitchCase="'Name'">
            {{ item[column] }}
        </ng-container>

        <ng-container *ngSwitchCase="'OrderType'">
            {{ item[column] }}
        </ng-container>

        <ng-container *appUserRoleUp="[ Role.Admin ]">
            <ng-container *ngIf="item?.ID > 0">
                <button *ngSwitchCase="'Menu'"
                    mat-button
                    class="menu-button pull-left"
                    (click)="$event.preventDefault()"
                    [matMenuTriggerFor]="menu"
                    [matMenuTriggerData]="{item: item}">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </ng-container>  
        </ng-container>
    </ng-container>
</ng-template>