import { Component, OnInit, ViewChild, Output, Input, EventEmitter, DoCheck } from '@angular/core';
import { Filter } from 'models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Role } from 'enums';
import { Drawing } from 'models/drawing';
import { ConfirmationDialogComponent } from '#components/shared/confirmation-dialog/confirmation-dialog.component';
import { DrawingService } from '#services/api';
import { ToasterService } from '#services/shared';
import { Page } from '_interfaces/page';
import { DrawingEditArgs, DrawingEditDialogComponent } from '../drawing-edit-dialog/drawing-edit-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-drawing-table',
  templateUrl: './drawing-table.component.html',
  styleUrls: ['./drawing-table.component.scss']
})

export class DrawingTableComponent implements OnInit, DoCheck {
  @Input() selection = new SelectionModel<Drawing>(true, []);
  @Output() dataChanged = new EventEmitter();
  @Output() isValidChanged = new EventEmitter();
  _filter: Filter = <Filter>{};

  @Input()
  set filter(value: Filter) {
    this._filter = value;

    this.applyFilter(value);
  }

  get filter() {
    return this._filter;
  }

  ngDoCheck(): void {
    this.isValidChanged.emit(!this.isValid);
  }

  get isValid(): boolean {
    if (!this.selection) {
      return false;
    }

    return this.selection.selected.length > 0;
  }

  @Input()
  set data(value: Drawing[]) {
    this.dataSource.data = value;
  }

  @Input() columnsToDisplay: Map<string, string>;
  @Output() collectionChanged = new EventEmitter();

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  dataSource = new MatTableDataSource<Drawing>();

  Role = Role;

  constructor(
    public dialog: MatDialog,
    private drawingService: DrawingService,
    private toaster: ToasterService
  ) {
  }

  ngOnInit() {
    setTimeout(() => this.dataSource.paginator = this.paginator);
  }

  get allColumns(): string[] {
    return Array.from(this.columnsToDisplay.keys());
  }

  get canShowTable() {
    const hasSearchedItems = this.filter.search
      ? this.dataSource.filteredData.length
      : true;

    const hasFilterParamsItems = this.filter.paramsCount
      ? this.dataSource.data.length
      : true;

    return hasSearchedItems && hasFilterParamsItems;
  }

  getColumnTitle(column: string): string {
    return this.columnsToDisplay.get(column);
  }

  applyFilter(filter: Filter) {
    this.dataSource.filter = filter.search;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  reloadSource(drawings: Drawing[]) {
    this.data = drawings;
    this.dataSource.data = this.data;
  }

  async onEditDrawing(drawing: any) {
    const args: DrawingEditArgs = {
      drawing
    };
    const dialogRef = this.dialog.open(DrawingEditDialogComponent, {
      width: '50%',
      maxHeight: '80%',
      data: args
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {console.log('res1', result);
        let drawingdata = await this.drawingService.updateFile(result.formData, result.param).toPromise();       
        this.collectionChanged.emit({ value: drawingdata });
        this.toaster.showSuccess('Success...', 'Drawing has been updated.');
      }
    });
  }

  onEnableDrawing(drawing: Drawing) {
    const { ID: id, Name: name } = drawing;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '350px',
      minHeight: '200px',
      data: <Page>{
        pageTitle: 'Confirmation',
        pageDescription: `Enable '${name}' Drawing?`
      }
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        await this.drawingService.enable_disable(id).toPromise();
        this.collectionChanged.emit({ value: drawing });
        this.toaster.showSuccess('Success...', `'${name}' has been enabled.`);
      }
    });
  }

  onDisableDrawing(drawingItem: Drawing) {
    const { ID: id, Name: name } = drawingItem;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '350px',
      minHeight: '200px',
      data: <Page>{
        pageTitle: 'Confirmation',
        pageDescription: `Disable '${name}' drawing?`
      }
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        await this.drawingService.enable_disable(id).toPromise();
        this.collectionChanged.emit({ value: drawingItem });
        this.toaster.showSuccess('Success...', `'${name}' has been disabled.`);
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {

    if (!this.isAllSelected()) {
      this.selection.clear();
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }
    else {
      this.selection.clear();
    }
  }

  downloadDrawing(item: any) {
    this.drawingService.getDrawingFile(item.ID).subscribe(
      data => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = '_blank';
        link.download = item.DocumentName;
        link.click();
        this.toaster.showSuccess('Success...', 'File downloaded successfully.');
      });
  }

  displayEquipmentType(equipmentTypes: any[])
  {
    return equipmentTypes.map(x => x.Name).join(",")
  }

}



