<div class="page-with-shadow">
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>

    <form  #equipmentForm="ngForm" class="col">
        <app-equipment-template
            [equipment]="equipment"
            (isValidChanged)="isValidChanged($event)">
        </app-equipment-template>

        <div class="row pull-right">
            <button type="button" 
                class="btn btn-default" 
                (click)="onCancel()">
                Cancel
            </button>
            <button type="submit" 
                class="btn btn-primary margin-left-md" 
                [disabled]="!isValid"
                (click)="onSubmit()">
                Create new equipment
            </button>
        </div>
    </form>
</div>