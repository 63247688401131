import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PurchaseOrderService } from '#services/api';
import { PurchaseOrder } from 'models';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-po-dropdown-multiple',
  templateUrl: './po-dropdown-multiple.component.html',
  styleUrls: ['./po-dropdown-multiple.component.scss']
})
export class PoDropdownMultipleComponent implements OnInit {
  @ViewChild('purchaseOrdersSelect')
  purchaseOrdersSelect;

  @Output() closed = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<number[]>();

  // collections
  purchaseOrders: PurchaseOrder[];

  purchaseOrdersControl = new FormControl();

  purchaseOrdersFilterControl = new FormControl();


  /* list of vendors filtered by search keyword */
  public filteredpurchaseOrdersMulti: ReplaySubject<PurchaseOrder[]>
  = new ReplaySubject<PurchaseOrder[]>(1);

   /** Subject that emits when the component has been destroyed. */
   protected _onDestroy = new Subject<void>();

  constructor(
    private purchaseOrderService: PurchaseOrderService
  ) { }

  ngOnInit() {

    // listen for search field value changes
    this.purchaseOrdersFilterControl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterPurchaseOrdersMulti();
    });
  }

  async onLoad() {
    if (!this.purchaseOrders) {
      const fetched = await this.purchaseOrderService.getAll().toPromise();

     const sorted = fetched.sort((a, b) => a.PurchaseOrderNumber.localeCompare(b.PurchaseOrderNumber));

     this.purchaseOrders =  sorted;
     // add purchase orders unsorted

       // load inital vendors list
       this.filteredpurchaseOrdersMulti.next(this.purchaseOrders.slice());
    }

    setTimeout(() => this.purchaseOrdersSelect.open());
  }

  onChange(event) {
    const ids = event.value.map(i => i.ID);

    this.changed.emit(ids);
  }

  onClose(event) {
    this.closed.emit(true);
  }

  protected filterPurchaseOrdersMulti() {
    if (!this.purchaseOrders) {
      return;
    }

      let search = this.purchaseOrdersFilterControl.value;
      if (!search) {
        this.filteredpurchaseOrdersMulti.next(this.purchaseOrders.slice());
        return;
      } else {
        search = search.toLowerCase();
      }

      // filter purchase orders
      this.filteredpurchaseOrdersMulti.next(
        this.purchaseOrders.filter (vendor => vendor.PurchaseOrderNumber.toLowerCase().indexOf(search) > -1)
        );
    }
}
