<div>
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>

    <div mat-dialog-content>
        <ng-content></ng-content>
    </div>
    
    <div mat-dialog-actions>
        <button 
            class="btn btn-default" 
            (click)="onCancelClick()">
            No
        </button>
        <span class="fill-remaining-space"></span>
        <button 
            class="btn btn-primary"
            [mat-dialog-close]="data">
            Yes
        </button>
    </div>
</div>