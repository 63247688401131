<form #equipmentListForm="ngForm" class="page-with-shadow">
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>
    
    <mat-toolbar class="page-toolbar" *ngIf="canShowEquipments">
        <span class="project-count">
            {{equipments && equipments.length}} equipments
        </span>
        <span class="fill-remaining-space"></span>
        
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-default" 
            color="primary" 
            [routerLink]="['/tools/reshuffling']">
            Reshuffling
            <mat-icon class="shuffle-icon">shuffle</mat-icon>
        </button>
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary margin-left-md" 
            [routerLink]="['/equipment-create']">
            Add new equipment
        </button>
    </mat-toolbar>

    <div *ngIf="canShowEquipments; else noEquipmentsTemplate">
        <mat-divider></mat-divider>
        <app-filter-panel [paramsCount]="filter.paramsCount" (searchChanged)="onUpdateFilters($event)">
            <div class="row fx-100">
                <!-- Project filter -->
                <div class="fx-20">
                    <mat-form-field class="fx-100" (click)="onLoadProjects();">
                        <mat-label>Projects</mat-label>
                        <mat-select 
                            #projectsSelect
                            placeholder="select projects" 
                            [formControl]="projectsControl"
                            (closed)="projectsSelectClosed($event)"
                            (selectionChange)="projectsSelectionChange($event)"
                            disableOptionCentering
                            multiple>
                            <mat-select-trigger>
                                {{projectsControl.value?.length > 0 ? projectsControl.value[0].Name : ''}}
                                <span 
                                    *ngIf="projectsControl.value?.length > 1" 
                                    class="additional-selection">
                                        (+{{projectsControl.value.length - 1}} 
                                        {{projectsControl.value?.length === 2 ? 'other' : 'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let project of projects" [value]="project">
                                {{project.Name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Delivery statuses filter -->
                <div class="fx-20">
                    <mat-form-field class="fx-100" (click)="onLoadDeliveryStatuses();">
                        <mat-label>Statuses</mat-label>
                        <mat-select 
                            #deliveryStatusesSelect
                            placeholder="select statuses" 
                            [formControl]="deliveryStatusesControl"
                            (closed)="deliveryStatusesSelectClosed($event)"
                            (selectionChange)="deliveryStatusesSelectionChange($event)"
                            disableOptionCentering
                            multiple>
                            <mat-select-trigger>
                                {{deliveryStatusesControl.value?.length > 0 ? deliveryStatusesControl.value[0].Name : ''}}
                                <span 
                                    *ngIf="deliveryStatusesControl.value?.length > 1" 
                                    class="additional-selection">
                                        (+{{deliveryStatusesControl.value.length - 1}} 
                                        {{deliveryStatusesControl.value?.length === 2 ? 'other' : 'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let status of deliveryStatuses" [value]="status">
                                {{status.Name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Equipment type filter -->
                <app-equipment-type-dropdown-multiple
                    class="fx-20"
                    (closed)="onFilterChanged()"
                    (changed)="equipmentTypesSelectionChange($event)"
                ></app-equipment-type-dropdown-multiple>

                <!-- Vendors filter -->
                <app-vendor-dropdown-multiple
                    class="fx-20"
                    (closed)="onFilterChanged()"
                    (changed)="vendorSelectionChange($event)"
                ></app-vendor-dropdown-multiple>

                <!-- Purchase orders filter -->
                <app-po-dropdown-multiple
                    #purchaseOrdersSelect
                    class="fx-20"
                    (closed)="onFilterChanged()"
                    (changed)="purchaseOrderSelectionChange($event)"
                ></app-po-dropdown-multiple>
            </div>

            <div class="row fx-100">
                <mat-form-field class="fx-20">
                    <mat-label>Projected FAT date (from)</mat-label>
                    <input matInput #fromRef 
                        [(ngModel)]="projectedFATDateFrom"
                        [ngModelOptions]="{standalone: true}" 
                        [matDatepicker]="projectedFATDateFromDatePicker"
                        placeholder="projected FAT date from"
                        (ngModelChange)="onFilterChanged()">
                    <mat-datepicker-toggle matSuffix 
                        [for]="projectedFATDateFromDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #projectedFATDateFromDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="fx-20">
                    <mat-label>Projected FAT date (to)</mat-label>
                    <input matInput #toRef 
                        [(ngModel)]="projectedFATDateTo" 
                        [ngModelOptions]="{standalone: true}"
                        [matDatepicker]="projectedFATDateToPicker"
                        placeholder="submit date to"
                        (ngModelChange)="onFilterChanged()">
                    <mat-datepicker-toggle matSuffix 
                        [for]="projectedFATDateToPicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #projectedFATDateToPicker></mat-datepicker>
                </mat-form-field>

                <mat-slide-toggle class="fx-10 margin-left-md" style="align-self: center;"
                    [(ngModel)]="includeInstalled" 
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onFilterChanged()">
                    Installed
                </mat-slide-toggle>
            </div>
        </app-filter-panel>
        <app-equipment-table
            [columnsToDisplay]="columnsToDisplay"
            [data]="equipments"
            (Click)="onItemClick($event)"
            [filter]="filter">
        </app-equipment-table>
    </div>
</form>

<ng-template #noEquipmentsTemplate>
    <app-empty-list-holder 
        [description]="'No equipments added yet.'">
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary" 
            [routerLink]="'/equipment-create'">
            Add new equipment
        </button>
    </app-empty-list-holder>
</ng-template>