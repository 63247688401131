import { Component, OnInit, DoCheck, Input, Output, EventEmitter } from '@angular/core';
import { User, Dictionary } from 'models';
import { Role } from 'enums';

@Component({
  selector: 'app-user-template',
  templateUrl: './user-template.component.html',
  styleUrls: ['./user-template.component.scss']
})
export class UserTemplateComponent implements OnInit, DoCheck {
  @Input() user: User;
  @Input() userEditable = true;
  @Output() isValidChanged = new EventEmitter();

  defaultUser: User;
  defaultRole: Dictionary;
  defaultFabricator: Dictionary;

  ngOnInit() {
    if (this.user.ID) {
      this.defaultUser = {
        ...this.user
      };
    }

    if (this.user.RoleID) {
      this.defaultRole = new Dictionary(
        this.user.RoleID,
        Role[this.user.RoleID]
      );
    }

    if (this.user.FabricatorID) {
      this.defaultFabricator = new Dictionary(
        this.user.FabricatorID,
        this.user.Fabricator
      );
    }
  }

  ngDoCheck(): void {
    this.isValidChanged.emit(this.isValid);
  }

  get isValid(): boolean {
    if (!this.user) {
      return false;
    }

    if (!this.user.RoleID || !this.user.Email) {
      return false;
    }

    if (this.user.RoleID === Role.Vendor && !this.user.FabricatorID) {
      return false;
    }

    return true;
  }

  get isFabricatorRequired(): boolean {
    return this.user && this.user.RoleID === Role.Vendor;
  }

  onUserChanged(event) {
    if (!event) {
      return;
    }

    const user = event.value as User;
    if (user) {
      this.user.Email = user.Email;
    }
  }

  onRoleChanged(event) {
    if (!event) {
      return;
    }

    const role = event.value as Dictionary;
    if (!role) {
      return;
    }

    this.user.RoleID = role.ID;
    this.user.Role = role.Name;

    if (role.ID !== Role.Vendor) {
      this.user.Fabricator = null;
      this.user.FabricatorID = null;
    }
  }

  onFabricatorChanged(event) {
    if (!event) {
      return;
    }

    const fabricator = event.value as Dictionary;
    if (fabricator) {
      this.user.FabricatorID = fabricator.ID;
      this.user.Fabricator = fabricator.Name;
    }
  }
}

