<div>
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>
    
    <div mat-dialog-content (keyup.enter)="onSaveClick()">
        <area-template *ngIf="area"
            [area]="area"
            (isValidChanged)="isValidChanged($event)">
        </area-template>
    </div>
    
    <mat-divider></mat-divider>
    
    <div mat-dialog-actions class="row pull-right">
        <button 
            class="btn btn-default"
            (click)="onCancelClick()">
            Cancel
        </button>
        <button
            *appUserRoleUp="[ Role.Admin ]"
            [disabled]="!isValid"
            class="btn btn-primary margin-left-md"
            [mat-dialog-close]="vendor"
            (click)="onSaveClick()">
            Apply changes 
        </button>
    </div>
</div>