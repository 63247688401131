<div class="col">
    <mat-table matSort multiTemplateDataRows
        *ngIf="!!dataSource.filteredData.length; else noMatchesTemplate"
        (matSortChange)="onSortData($event)"
        [dataSource]="dataSource"
        class="mat-table-container"
        style="overflow: hidden"
        >
        <ng-container matColumnDef="{{column}}" *ngFor="let column of allColumns">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{getColumnTitle(column)}}
            </mat-header-cell>
            <mat-cell class="mat-cell-value" *matCellDef="let item">
                <ng-container
                    [ngTemplateOutlet]="columnContent"
                    [ngTemplateOutletContext]="{item: item, column: column}">
                </ng-container>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="allColumns; sticky: true"></mat-header-row>

        <!-- Expanded Content Column -->
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let detail" class="delails-info--expanded row">
                <ng-container *ngIf="expandedItem?.ID === detail?.ID">
                    <div  *ngIf="expandedItem" class="col details-info-left-content">
                        <p class="bold-text" style="margin: 0">
                            {{detail.Fabricator}}
                        </p>
                        <a *appUserRoleUp="[ Role.Admin ]"
                            (click)="onEditPurchaseOrder(expandedItem)">
                            view details >
                        </a>
                        <a *appUserRoleUp="[ Role.User ]"
                            (click)="onViewPurchaseOrder(expandedItem)">
                            view details >
                        </a>

                        <br>
                        <span>
                            not ordered
                            <p class="bold-text">{{ notOrderedCount }}</p>
                        </span>
                        <span>
                            ordered
                            <p class="bold-text">{{ orderedCount }}</p>
                        </span>
                        <span>
                            ready to ship
                            <p class="bold-text">{{ readyToShipCount }}</p>
                        </span>
                        <span>
                            storage
                            <p class="bold-text">{{ storageCount }}</p>
                        </span>
                        <span>
                            shipped
                            <p class="bold-text">{{ shippedCount }}</p>
                        </span>
                        <span>
                            total
                            <p class="bold-text">{{equipment ? equipment.length : '0'}}</p>
                        </span>
                    </div>
                    <div class="col details-info-right-content">
                        <ng-container *appUserRoleUp="[ Role.Admin ]">
                            <div
                                *ngIf="equipment && equipment.length"
                                class="row pull-right">
                                <button mat-button
                                    (click)="onAddNewEquipment()"
                                    class="add-equipment-button">
                                    <mat-icon>add</mat-icon>
                                    Add new equipment
                                </button>
                                <button mat-button
                                    (click)="onAddExistEquipment()"
                                    class="add-equipment-button margin-left-md">
                                    <mat-icon>add</mat-icon>
                                    Add exist equipment
                                </button>
                            </div>
                        </ng-container>

                        <app-equipment-table
                            *ngIf="equipment && equipment.length; else noEquipmentsTemplate"
                            [columnsToDisplay]="equipmentColumnsToDisplay"
                            [data]="equipment"
                            [showPadination]="false"
                            (collectionChanged)="onEquipmentCollectionChanged($event)"
                            style="height: 340px; overflow: auto">
                        </app-equipment-table>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>

        <mat-row
            *matRowDef="let row; columns: allColumns"
            matRipple
            class="element-row"
            [@rowsAnimation]=""
            [class.expanded]="expandedItem?.ID === row?.ID"
            (click)="onExpandRow(row)">
        </mat-row>
        <mat-row
            class="details-row"
            *matRowDef="let row; columns: ['expandedDetail']"
            [@detailExpand]="row?.ID === expandedItem?.ID ? 'expanded' : 'collapsed'">
        </mat-row>
    </mat-table>
</div>

<mat-paginator
    [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons>
</mat-paginator>

<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item
            (click)="onEditPurchaseOrder(item)">
            <mat-icon>edit</mat-icon>
            Edit
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item
            (click)="onArchivePO(item['ID'])">
            <mat-icon>archive</mat-icon>
            Archive
        </button>
        <button mat-menu-item
            (click)="onDeletePO(item['ID'])">
            <mat-icon>delete_forever</mat-icon>
            Delete
        </button>
    </ng-template>
</mat-menu>

<ng-template #noMatchesTemplate>
    <app-empty-list-holder
        [icon]="'search'"
        [description]="'No matches'">
    </app-empty-list-holder>
</ng-template>

<ng-template #columnContent let-item="item" let-column="column">
    <ng-container [ngSwitch]="column">
        <ng-container *appUserRoleUp="[ Role.Admin ]">
            <button *ngSwitchCase="'Menu'"
                mat-button
                (click)="$event.stopPropagation()"
                class="menu-button pull-left"
                [matMenuTriggerFor]="menu"
                [matMenuTriggerData]="{item: item}">
                <mat-icon>more_vert</mat-icon>
            </button>
        </ng-container>

        <span *ngSwitchCase="'EquipmentsOrdered'">
            {{ item.EquipmentsOfProjects || 0 }}/{{ item[column] || 0 }}
        </span>

        <span *ngSwitchCase="'Archived'" class="cell-align-center">
            <mat-icon *ngIf="item[column]" title="The order is archived">folder</mat-icon>
        </span>

        <span *ngSwitchCase="'HasAribaAFEMismatchEquipments'">
            <mat-icon *ngIf="item[column] === null"
                class="info-color"
                title="The purchase order has no information about AFE missmatch equipments">
                sync_problem
            </mat-icon>

            <mat-icon *ngIf="item[column] === true"
                class="warning-color"
                title="The purchase order has AFE missmatch equipments">
                warning
            </mat-icon>
        </span>

        <span *ngSwitchDefault>
            {{
                helper.isDate(item[column])
                ? (item[column] | dateFormatPipe)
                : item[column]
            }}
        </span>
    </ng-container>
</ng-template>

<ng-template #noEquipmentsTemplate>
    <app-empty-list-holder
        [description]="'No equipment needs added yet.'">
        <div *appUserRoleUp="[ Role.Admin ]" class="row">
            <span>Add
                <button mat-button
                    (click)="onAddNewEquipment()"
                    class="add-equipment-button">
                    new equipment
                </button>
                or select an
                <button mat-button
                    (click)="onAddExistEquipment()"
                    class="add-equipment-button">
                    exist equipment
                </button>
                from the list.
            </span>
        </div>
    </app-empty-list-holder>
</ng-template>

<ng-template #wrongTimeToGo>
    <p class="bold-text">{{dateLeftToStart}}</p>
</ng-template>
