import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

import { CommonHelper } from '_extentions/common-helper';
import { Equipment, PurchaseOrder, FabButton, BulkOperationResult } from 'models';
import { EquipmentService, PurchaseOrderService, ProjectService } from '#services/api';
import { ConfirmationDialogComponent } from '#components/shared/confirmation-dialog/confirmation-dialog.component';
import { EquipmentEditDialogComponent, EquipmentEditArgs } from '../equipment-edit-dialog/equipment-edit-dialog.component';
import { ToasterService } from '#services/shared';
import { PoEditDialogComponent } from '#components/purchase-order/po-edit-dialog/po-edit-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Page } from '_interfaces/page';
import { SpeedButtonService } from '#services/shared/speed-button.service';
import { EquipmentViewArgs, EquipmentViewDialogComponent } from '../equipment-view-dialog/equipment-view-dialog.component';
import { PoViewDialogComponent } from '#components/purchase-order/po-view-dialog/po-view-dialog.component';
import { Role, DeliveryStatus } from 'enums';
import { Filter } from 'models';
import { ProjectViewDialogComponent } from '#components/project/project-view-dialog/project-view-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable } from 'rxjs';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-equipment-table',
    templateUrl: './equipment-table.component.html',
    styleUrls: ['./equipment-table.component.scss']
    // -----------------------------------------------------------------------------
    // It will help to increase performance for big collections
    // TODO need to be supported for data change methods (lock, edit, spare etc.)
    // Google it for details:
    // changeDetection: ChangeDetectionStrategy
    // -----------------------------------------------------------------------------
})

export class EquipmentTableComponent implements OnInit, OnDestroy {
    @Input()
    set data(value: Equipment[]) {
        this.dataSource.data = value;
        this._data = value;
    }
    get data() {
        return this._data;
    }
    _data: Equipment[];

    equipmentFormGroup: FormGroup;

    @Input() canSort = true;
    @Input() canEdit = true;
    @Input() canLockEquipment = true;
    @Input() canNavigateToView = true;
    @Input() canDeleteEquipment = true;
    @Input() canSelect = true;
    @Input() showPadination = true;
    @Input() dragDropDisabled = true;
    @Input() swapDisabled = true;
    @Input() bulkDisabled = true;
    @Input() showCheckAll = true;
    @Input() editDialogPO: boolean;
    @Input() columnsToDisplay: Map<string, string>;
    @Input() columnsToDisplayInHints: Map<string, string>;
    @Input() columnsToDisplayInComments: Map<string, string>;
    @Output() selectedChanged = new EventEmitter();
    @Output() collectionChanged = new EventEmitter();
    @Output() dataChanged = new EventEmitter();

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort)
    set content(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    @Input()
    set filter(filter: Filter) {
        this.applyFilter(filter);
    }

    get checkedItems() {
        return this.dataSource.filteredData
            .filter(p => p['_checked']);
    }

    Role = Role;
    selectedItem: Equipment | null;
    dataSource = new MatTableDataSource<Equipment>();

    constructor(
        public helper: CommonHelper,
        public dialog: MatDialog,
        private equipmentService: EquipmentService,
        private purchseOrderService: PurchaseOrderService,
        private projectService: ProjectService,
        private toaster: ToasterService,
        private speedButtonService: SpeedButtonService,
        private formbuilder: FormBuilder) {
    }

    ngOnInit() {
        this.dataSource.data = this.data;
        this.dataSource.filterPredicate = (data, filter: string) => {
            const accumulator = (currentTerm, key) => {
              return this.nestedFilterCheck(currentTerm, data, key);
            };
            const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
            // Transform the filter by converting it to lowercase and removing whitespace.
            const transformedFilter = filter.trim().toLowerCase();
            return dataStr.indexOf(transformedFilter) !== -1;
          }

        if (this.showPadination) {
            setTimeout(() => this.dataSource.paginator = this.paginator);
        }
        this.equipmentFormGroup = this.formbuilder.group({
            equipments: this.formbuilder.array([])
        });
    }

    ngOnDestroy() {
        this.speedButtonService.clear();
    }

    nestedFilterCheck(search, data, key) {
        if (typeof data[key] === 'object') {
          for (const k in data[key]) {
            if (data[key][k] !== null) {
              search = this.nestedFilterCheck(search, data[key], k);
            }
          }
        } else {
          search += data[key];
        }
        return search;
      }

    canSpare(item: Equipment) {
        return item.ProjectID && !item.Locked;
    }

    canLock(item: Equipment): boolean {
        return this.canLockEquipment &&
            !item.Locked &&
            item.ProjectID > 0;
    }

    canDelete(item: Equipment) {
        return this.canDeleteEquipment &&
            !item.Locked;
    }

    canUnlock(item: Equipment) {
        return item.Locked;
    }

    canCheck(item: Equipment) {
        const checked = this.checkedItems.map(i => i.ID).includes(item.ID);
        if (checked) {
            return true;
        }

        if (this.swapDisabled || this.checkedItems.length < 2) {
            return true;
        }

        return false;
    }

    get allColumns(): string[] {
        return Array.from(this.columnsToDisplay.keys());
    }

    getColumnTitle(column: string): string {
        return this.columnsToDisplay.get(column);
    }

    applyFilter(filter: Filter) {
        this.dataSource.filter = filter.search;

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        const cur = event.currentIndex;
        const prev = event.previousIndex;

        if (cur === prev) {
            return;
        }

        this.flipFlop(cur, prev);
    }

    swap() {
        const cur = this.findIndex(this.checkedItems[0]);
        const prev = this.findIndex(this.checkedItems[1]);

        this.flipFlop(cur, prev);
    }

    flipFlop(cur: number, prev: number) {
        const flipFlopIndex = cur > prev
            ? cur - 1
            : cur + 1;

        const arr = CommonHelper.deepCopy(this.data);

        moveItemInArray(arr, prev, cur);
        moveItemInArray(arr, flipFlopIndex, prev);

        this.dataChanged.emit({ value: arr });
    }

    lockChecked() {
        const success = 'Equipment have been locked';

        this.bulk(ids => this.equipmentService.bulkLock(ids), success);
    }

    unlockChecked() {
        const success = 'Equipment have been unlocked';

        this.bulk(ids => this.equipmentService.bulkUnlock(ids), success);
    }

    spareChecked() {
        const success = 'Equipment have been made spare';

        this.bulk(ids => this.equipmentService.bulkSpare(ids), success);
    }

    readyToShipChecked() {
        const success = 'Equipment status have been changed to ready to ship';

        this.bulk(ids => this.equipmentService.bulkChangeStatus(ids, DeliveryStatus.readyToShip), success);
    }

    storageChecked() {
        const success = 'Equipment status have been changed to storage';

        this.bulk(ids => this.equipmentService.bulkChangeStatus(ids, DeliveryStatus.storage), success);
    }

    shippedChecked() {
        const success = 'Equipment status have been changed to shipped';

        this.bulk(ids => this.equipmentService.bulkChangeStatus(ids, DeliveryStatus.shipped), success);
    }

    bulk(action: (ids: number[]) => Observable<BulkOperationResult<Equipment>>, success: string) {
        const equipmentIds = this.checkedItems.map(i => i.ID);
        if (!equipmentIds || equipmentIds.length <= 0) {
            return;
        }

        action(equipmentIds)
            .subscribe(response => {
                this.collectionChanged.emit({ ids: equipmentIds });
                this.refreshSpeedButtons();

                if (response.Warning) {
                    this.toaster.showWarn('Warning...', response.Warning);
                } else {
                    this.toaster.showSuccess('Success...', success);
                }
            });
    }

    async reloadDetails() {
        // get server info
        const id = this.selectedItem.ID;
        const updatedItem = await this.equipmentService.get(id).toPromise();

        // update data source
        this.data.forEach((item, i) => {
            if (item.ID === id) {
                this.data[i] = updatedItem;
            }
        });

        // refresh expanded item and collection
        this.selectedItem = updatedItem;
        this.dataSource.data = this.data;
    }

    onRowClick(row: Equipment) {
        if (this.canSelect) {
            this.selectedItem = row;
        }
    }

    poLineChange() {
      this.collectionChanged.emit(this.dataSource.data);
    }

    onCheckedEq(event) {
        const equipments = <FormArray>this.equipmentFormGroup.get('equipments') as FormArray;
        if (event.checked) {
            equipments.push(new FormControl(event.source.value.ID));
        } else {
            const i = equipments.controls.findIndex(eq => eq.value === event.source.value);
            equipments.removeAt(i);
        }
        this.selectedChanged.emit({ value: this.equipmentFormGroup.value});
    }

    onCheckedAll(event: MatCheckboxChange) {
        this.dataSource.filteredData.forEach(p => {
            p['_checked'] = event.checked;
        });

        this.onChecked();
    }

    onChecked() {
        if (this.swapDisabled && this.bulkDisabled) {
            return;
        }

        this.refreshSpeedButtons();
    }

    refreshSpeedButtons() {
        this.speedButtonService.clear();

        if (this.checkedItems.length > 0) {
            const buttons = [
                this.getSwapButton(),
                this.getLockButton(),
                this.getUnlockButton(),
                this.getSpareButton(),
                this.getReadyToShipButton(),
                this.getStorageButton(),
                this.getShippedButton()
            ];

            this.speedButtonService.update(buttons.filter(b => b));
        }
    }

    getSwapButton() {
        if (this.swapDisabled) {
            return null;
        }

        if (this.checkedItems.length !== 2) {
            return null;
        }

        return new FabButton(() => this.swap(), 'Swap', 'swap_vert');
    }

    getLockButton() {
        const canLock = this.checkedItems.every(this.canLock.bind(this));

        if (!canLock) {
            return null;
        }

        return new FabButton(() => this.lockChecked(), 'Lock', 'link');
    }

    getUnlockButton() {
        const canUnlock = this.checkedItems.every(this.canUnlock.bind(this));

        if (!canUnlock) {
            return null;
        }

        return new FabButton(() => this.unlockChecked(), 'Unlock', 'link_off');
    }

    getSpareButton() {
        const canSpare = this.checkedItems.every(this.canSpare.bind(this));

        if (!canSpare) {
            return null;
        }

        return new FabButton(() => this.spareChecked(), 'Make spare', 'remove_circle_outline');
    }

    getReadyToShipButton() {
        return new FabButton(() => this.readyToShipChecked(), 'Ready for shipping', null, 'R');
    }

    getStorageButton() {
        return new FabButton(() => this.storageChecked(), 'Storage', null, 'St');
    }

    getShippedButton() {
        return new FabButton(() => this.shippedChecked(), 'Shipped', null, 'Sh');
    }

    async onLockClick(equipmentId: number, lock: boolean = true) {
        let res: Equipment;

        lock
            ? res = await this.equipmentService.lock(equipmentId).toPromise()
            : res = await this.equipmentService.unlock(equipmentId).toPromise();

        const origin = this.dataSource.data.find(i => {
            return i.ID === equipmentId;
        });

        origin.Locked = res.Locked;

        this.collectionChanged.emit({ value: res });
    }

    onSpareClick(equipmentId: number) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            minWidth: '350px',
            minHeight: '200px',
            data: <Page>{
                pageTitle: 'Confirmation',
                pageDescription: 'Are you sure to make it spare?'
            }
        });

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                const res = await this.equipmentService.spare(equipmentId).toPromise();
                const origin = this.dataSource.data.find(i => {
                    return i.ID === equipmentId;
                });

                origin.ProjectID = res.ProjectID;
                origin.ProjectName = res.ProjectName;
                this.collectionChanged.emit({ value: res });
            }
        });
    }

    onDeleteClick(equipmentId: number) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            minWidth: '350px',
            minHeight: '200px',
            data: <Page>{
                pageTitle: 'Confirmation',
                pageDescription: 'Delete this equipment from the system?'
            }
        });

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                this.collectionChanged.emit({
                    value: await this.equipmentService.delete(equipmentId).toPromise()
                });
            }
        });
    }

    async onEditEquipment(equipment: Equipment) {
        const purchaseOrder = await this.loadPurchaseOrder(equipment);
        const drawing = await  this.equipmentService.getDrawing(equipment.ID).toPromise();
        equipment.AssignedDrawing = drawing;

        const args: EquipmentEditArgs = {
            equipment: equipment,
            purchaseOrder: purchaseOrder
        };
        const dialogRef = this.dialog.open(EquipmentEditDialogComponent, {
            width: '70%',
            maxHeight: '80%',
            data: args
        });

        dialogRef.afterClosed().subscribe(async (result: Equipment) => {
            if (result) {
                let drawingIds : number[];            

                const res = await this.equipmentService.update(result).toPromise().then(async (values) => {
             
                    if(result.AssignedDrawing && result.AssignedDrawing.length>0)
                    {         
                        const drawingIds: number[] = result.AssignedDrawing.map(i => i.ID);               
                        await this.equipmentService.assignDrawing(values.ID, drawingIds).toPromise();              
                    }
                    else
                    if(equipment.EquipmentTypeID != result.EquipmentTypeID)
                    {
                        const params = new HttpParams().set('isRemoveAll', 'true');
                        await this.equipmentService.assignDrawing(values.ID, drawingIds, params).toPromise();              
                    }
                    else if(equipment.AssignedDrawing.length>0 && result.AssignedDrawing.length==0)
                    {
                        const params = new HttpParams().set('isRemoveAll', 'true');
                        await this.equipmentService.assignDrawing(values.ID, drawingIds, params).toPromise();              
                    }
                  }); 
                this.reloadDetails();
                this.collectionChanged.emit({ value: res });
                this.toaster.showSuccess('Success...', 'Equipment has been updated.');
            }
        });
    }

    async onViewEquipment(equipment: Equipment) {
        const purchaseOrder = await this.loadPurchaseOrder(equipment);
      
        const args: EquipmentViewArgs = {
            equipment: equipment,
            purchaseOrder: purchaseOrder
        };

        this.dialog.open(EquipmentViewDialogComponent, {
            width: '70%',
            maxHeight: '80%',
            data: args
        });
    }

    async onEditPO(equipment: Equipment) {
        const purchaseOrder = await this.loadPurchaseOrder(equipment);

        const dialogRef = this.dialog.open(PoEditDialogComponent, {
            width: '50%',
            maxHeight: '80%',
            data: purchaseOrder
        });

        dialogRef.afterClosed().subscribe(async (result: {po: PurchaseOrder, eq: Equipment}) => {
            if (result) {
              const poPromise = this.purchseOrderService.update(result.po).toPromise();
              const eqPromise = this.equipmentService.multipleUpdate(result.eq).toPromise();

              Promise.all([poPromise, eqPromise]).then(() => {
                this.reloadDetails();
                this.toaster.showSuccess('Success...', 'Purchase order has been updated.');
              });
            }
        });
    }

    async onViewPO(equipment: Equipment) {
        const purchaseOrder = await this.loadPurchaseOrder(equipment);

        this.dialog.open(PoViewDialogComponent, {
            width: '50%',
            maxHeight: '80%',
            data: purchaseOrder
        });
    }

    async onViewProject(equipment: Equipment) {
        const project = await this.loadProject(equipment);

        this.dialog.open(ProjectViewDialogComponent, {
            width: '50%',
            maxHeight: '80%',
            data: project
        });
    }

    loadPurchaseOrder(equipment: Equipment) {
        if (!equipment || !equipment.PurchaseOrderID) {
            return null;
        }

        return this.purchseOrderService
            .get(equipment.PurchaseOrderID)
            .toPromise();
    }

    loadProject(equipment: Equipment) {
        if (!equipment || !equipment.ProjectID) {
            return null;
        }

        return this.projectService
            .get(equipment.ProjectID)
            .toPromise();
    }

    findIndex = (equipment: Equipment) => this.data.findIndex(e => e.ID === equipment.ID);
}
