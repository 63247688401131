import {
    Component,
    Input,
    Output,
    EventEmitter,
    DoCheck,
} from '@angular/core';

import { PurchaseOrder, Dictionary } from 'models';
import { DictionaryService } from '#services/api';
import { Dictionaries } from 'enums/dictionary';

@Component({
    selector: 'app-po-template',
    templateUrl: './po-template.component.html',
    styleUrls: ['./po-template.component.scss']
})

export class PoTemplateComponent implements DoCheck {
    @Input() purchaseOrder: PurchaseOrder;
    @Output() isValidChanged = new EventEmitter();

    fabricators: Dictionary[];

    constructor(private dictionaryService: DictionaryService) {
    }

    ngDoCheck(): void {
        this.isValidChanged.emit(!this.isInvalid);
    }

    get isInvalid(): boolean {
        if (!this.purchaseOrder) {
            return false;
        }

        const isNumsValid =
            !!this.purchaseOrder.PurchaseOrderNumber ||
            !!this.purchaseOrder.RequestNumber;

        const isSubmitDateValid =
            !!this.purchaseOrder.PurchaseOrderNumber
                ? !!this.purchaseOrder.SubmitDate
                : true;

        return !isNumsValid ||
            !isSubmitDateValid ||
            !this.purchaseOrder.FabricatorID;
    }

    get fabricatorDefValue(): Dictionary {
        if (!this.purchaseOrder.FabricatorID) {
            return null;
        }

        return new Dictionary(this.purchaseOrder.FabricatorID, this.purchaseOrder.Fabricator);
    }

    async onLoadFabricators() {
        if (!this.fabricators) {
            this.fabricators = await this.dictionaryService.getAll(`${Dictionaries.fabricators}/dictionary`).toPromise();
        }
    }

    onFabricatorChanged(event) {
        if (event.value) {
            this.purchaseOrder.FabricatorID = (<Dictionary>event.value).ID;
        }
    }
}
