import { Component, OnInit } from '@angular/core';
import { ProjectTypeService } from '#services/api/project-type.service';
import { Role } from 'enums';
// import { Dictionary } from 'models'};
import { Filter } from 'models';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-project-type-list',
  templateUrl: './project-type-list.component.html',
  styleUrls: ['./project-type-list.component.scss']
})
export class ProjectTypeListComponent implements OnInit {

  projectTypes: any;
  Role = Role;

  filter: Filter = <Filter>{};

  public projectTypesColumnsToDisplay: Map<string, string> = new Map([
      ['Name', 'Area'],
      ['Menu', '']
    ]);

  constructor(private ProjectTypeService: ProjectTypeService) {}

  ngOnInit() {
      this.initAreas();
  }

  get canShowprojectTypes() {
      return this.projectTypes && this.projectTypes.length;
  }

  initAreas() {
      this.ProjectTypeService
      .getAll()
      .pipe(
        take(1)
      ).subscribe(data => {
        this.projectTypes = data;
      });
  }

  onSearchChanged(event: { value: string }) {
      if (!event) {
        return;
      }

      this.filter = {
        search: event.value,
        paramsCount: 0
      };
    }

    onAreaCollectionChanged(event) {
      if (event) {
        this.initAreas();
      }
    }
}
