import { Component, OnInit } from '@angular/core';
import { User } from 'models';
import { Role } from 'enums';
import { UserService } from '#services/api';
import { ToasterService } from '#services/shared';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  // page
  pageTitle = 'Add new user';
  pageDescription = 'edit data below to create user';

  // other propertoes
  newUser: User = new User();
  isValid = false;

  constructor(
    private userService: UserService,
    private toaster: ToasterService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.newUser.RoleID = Role.User;
  }

  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }

  onSubmit() {
    this.userService
      .create(this.newUser)
      .subscribe((result: User) => {
        this.router.navigate(['tools/data-manager'], { queryParams: { tab: 0 } });
        this.toaster.showSuccess('Success...', `User '${result.Email}' has been added.`);
      });
  }

  onCancel() {

    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 0 } });
  }
}
