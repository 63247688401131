<form #drawingListForm="ngForm">
  <mat-toolbar class="page-toolbar" *ngIf="canShowDrawings">
    <span class="project-count">
      {{drawings && drawings.length}} Drawings
    </span>
    <span class="fill-remaining-space"></span>

    <button *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary margin-left-md"
            [routerLink]="['/drawing-create']">
      Add new Drawing
    </button>
  </mat-toolbar>

  <div *ngIf="canShowDrawings; else noDrawingTemplate">
    <mat-divider></mat-divider>

    <app-search-panel (searchChanged)="onSearchChanged($event)">
    </app-search-panel>

    <app-drawing-table [data]="drawings"
                       [columnsToDisplay]="drawingColumnsToDisplay"
                       [filter]="filter"
                       (collectionChanged)="onDrawingCollectionChanged($event)">
    </app-drawing-table>
  </div>
</form>

<ng-template #noDrawingTemplate>
  <app-empty-list-holder [description]="'No Drawing added yet.'">
    <button *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary"
            [routerLink]="'/drawing-create'">
      Add new Drawing
    </button>
  </app-empty-list-holder>
</ng-template>

