import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Output, Input } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-cost-centers-select',
  templateUrl: './cost-centers-select.component.html',
  styleUrls: ['./cost-centers-select.component.scss']
})
export class CostCentersSelectComponent {
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input() codes: string[] = [];
  @Output() changed = new EventEmitter();

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.codes.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value='';
    }

    this.changed.emit({ value: this.codes });
  }

  remove(code: string): void {
    const index = this.codes.indexOf(code);

    if (index >= 0) {
      this.codes.splice(index, 1);
    }

    this.changed.emit({ value: this.codes });
  }
}
