<div>
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>

    <div mat-dialog-content>
        <div class="col">
            <mat-form-field class="fx-33 filter-panel">
                <input matInput
                    placeholder="Search"
                    (keyup)="onKey($event)">
            </mat-form-field>
        </div>

        <app-equipment-table
            *ngIf="equipments && equipments.length; else noEquipmentsTemplate"
            [columnsToDisplay]="equipmentColumnsToDisplay" 
            [data]="equipments"
            (selectedChanged)="onItemClick($event)"
            [filter]="filter"
            [canEdit]="false">
        </app-equipment-table>
    </div>

    <mat-divider></mat-divider>

    <div mat-dialog-actions class="row pull-right">
        <button 
            class="btn btn-default"
            (click)="onCancelClick()">
            Cancel
        </button>
        <button 
            *ngIf="equipments && equipments.length"
            [disabled]="!selectedEquipment"
            class="btn btn-primary margin-left-md"
            [mat-dialog-close]="selectedEquipment">
            Ok 
        </button>
    </div>
</div>

<ng-template #noEquipmentsTemplate>
    <app-empty-list-holder 
        [description]="'No equipment available.'">
    </app-empty-list-holder>
</ng-template>