import { Component, Input, EventEmitter, Output, DoCheck } from '@angular/core';
import { EquipmentType } from 'models';
import { Dictionary } from 'models';
import { DictionaryService } from '#services/api';
import { Dictionaries } from 'enums/dictionary';

@Component({
  selector: 'app-equipment-type-template',
  templateUrl: './equipment-type-template.component.html',
  styleUrls: ['./equipment-type-template.component.scss']
})
export class EquipmentTypeTemplateComponent implements DoCheck {
  @Input() equipmentType: EquipmentType;
  @Output() isValidChanged = new EventEmitter();

  orderTypes: Dictionary[];

  constructor(
    private dictionaryService: DictionaryService
  ) { }

  ngDoCheck(): void {
    this.isValidChanged.emit(!this.isInvalid);
  }

  get isInvalid(): boolean {
    if (!this.equipmentType) {
      return false;
    }

    return !this.equipmentType.Name || !this.equipmentType.OrderType;
  }

  get orderTypeDefValue(): Dictionary {
    return new Dictionary(this.equipmentType.OrderTypeID, this.equipmentType.OrderType);
  }

  async onLoadOrderTypes() {
    if (!this.orderTypes) {
      this.orderTypes = await this.dictionaryService.getAll(
        `${Dictionaries.orderTypes}/dictionary`).toPromise();
    }
  }

  onOrderTypeChanged(event) {
    if (event.value) {
      this.equipmentType.OrderTypeID = (<Dictionary>event.value).ID;
      this.equipmentType.OrderType = (<Dictionary>event.value).Name;
    }
  }
}
