<form #vendorForm="ngForm" class="col">
    <div class="row">
        <mat-form-field appearance="outline" class="fx-50">
            <mat-label>Name</mat-label>
            <input matInput 
                [(ngModel)]="vendor.Name" 
                [ngModelOptions]="{standalone: true}" 
                maxlength="50"
                placeholder="enter name">
        </mat-form-field>
    </div>
</form>