<form #userListForm="ngForm">
    <mat-toolbar class="page-toolbar" *ngIf="canShowUsers">
        <span class="project-count">
            {{ users && users.length}} users
        </span>
        <span class="fill-remaining-space"></span>

        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary margin-left-md"
            [routerLink]="['/user-create']">
            Add new user
        </button>
    </mat-toolbar>

    <div *ngIf="canShowUsers; else noUsersTemplate">
        <mat-divider></mat-divider>

        <app-search-panel 
            (searchChanged)="onSearchChanged($event)">
        </app-search-panel>

        <app-user-table
            [data]="users"
            [columnsToDisplay]="columnsToDisplay"
            [filter]="filter"
            (collectionChanged)="onUserCollectionChanged($event)">
        </app-user-table>
    </div>
</form>

<ng-template #noUsersTemplate>
    <app-empty-list-holder
        [description]="'No users added yet.'">
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary" 
            [routerLink]="'/user-create'">
            Add new user
        </button>
    </app-empty-list-holder>
</ng-template>