import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';

import * as dayjs from 'dayjs';

/**
 * @description Workaround for weird angular / JSON.parse() behavior unable to automatically parse json datetime into javascript Date object
 */
@Injectable()
export class DatetimeParseInterceptor implements HttpInterceptor {
    private dateRegex = /^(\d{4})-(\d{2})-(\d{2})?$/;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .do((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.convertDates(event.body);
                }
            });
    }

    private convertDates(object: Object) {
        if (!object || !(object instanceof Object)) {
            return;
        }

        if (object instanceof Array) {
            for (const item of object) {
                this.convertDates(item);
            }
        }
        this.convertDatesFromObj(object);
    }

    private convertDatesFromObj(object: Object){
    
        for (const key of Object.keys(object)) {
            const value = object[key];

            if (value instanceof Array) {
                for (const item of value) {
                    this.convertDates(item);
                }
            }

            if (value instanceof Object) {
                this.convertDates(value);
            }

            if (typeof value === 'string' && this.dateRegex.test(value)) {
                object[key] = this.toDate(value);
            } else if (value instanceof String && this.dateRegex.test(value.toString())) {
                object[key] = this.toDate(value.toString());
            }
        }
    }
    toDate(value: string) {
        return dayjs(value, 'YYYY-MM-DD').toDate();
    }
}
