<form #vendorListForm="ngForm">
    <mat-toolbar class="page-toolbar" *ngIf="canShowAreas">
        <span class="project-count">
            {{areas && areas.length}} Areas
        </span>
        <span class="fill-remaining-space"></span>

        <button
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary margin-left-md"
            [routerLink]="['/area-create']">
            Add new Area
        </button>
    </mat-toolbar>

    <div *ngIf="canShowAreas; else noVendorsTemplate">
        <mat-divider></mat-divider>

        <app-search-panel
            (searchChanged)="onSearchChanged($event)">
        </app-search-panel>

        <area-table
            [data]="areas"
            [columnsToDisplay]="areaColumnsToDisplay"
            [filter]="filter"
            (collectionChanged)="onAreaCollectionChanged($event)">
        </area-table>
    </div>
</form>

<ng-template #noVendorsTemplate>
    <app-empty-list-holder
        [description]="'No area added yet.'">
        <button
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary"
            [routerLink]="'/area-create'">
            Add new Area
        </button>
    </app-empty-list-holder>
</ng-template>
