import { Component, OnInit, Inject } from '@angular/core';
import { Page } from '_interfaces/page';
import { Project, Filter } from 'models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-project-select-dialog',
  templateUrl: './project-select-dialog.component.html',
  styleUrls: ['./project-select-dialog.component.scss']
})
export class ProjectSelectDialogComponent implements OnInit, Page {
  // page
  pageTitle = 'Project';
  pageDescription = 'select template';

  // other properties
  projects: Project[];
  selectedProject: Project;
  filter: Filter = <Filter>{};

  public projectColumnsToDisplay: Map<string, string> = new Map([
    ['Name', 'Name'],
    ['Type', 'Type'],
    ['Representative', 'Representative'],
  ]);

  constructor(
    public dialogRef: MatDialogRef<ProjectSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Project[]) {
  }

  async ngOnInit() {
    this.projects = this.data;
  }

  onItemClick(event) {
    this.selectedProject = event.value;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onKey(event) {
    this.filter = <Filter>{
      search: event.target.value
    };
  }
}
