import { Component, OnInit, EventEmitter, Output, Input, DoCheck } from '@angular/core';
import { Dictionary } from 'models';

@Component({
  selector: 'app-vendor-template',
  templateUrl: './vendor-template.component.html',
  styleUrls: ['./vendor-template.component.scss']
})
export class VendorTemplateComponent implements OnInit, DoCheck {
  @Input() vendor: Dictionary;
  @Output() isValidChanged = new EventEmitter();

  ngOnInit() {
  }

  ngDoCheck(): void {
    this.isValidChanged.emit(!this.isValid);
  }

  get isValid(): boolean {
    if (!this.vendor) {
      return false;
    }

    return !this.vendor.Name;
  }
}
