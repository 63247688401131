import { Component, OnInit, Input } from '@angular/core';
import { Equipment } from 'models';
import { EquipmentService } from '#services/api';
import { EquipmentHistory } from 'models/equipment-history';
import { take } from 'rxjs/operators';
import { CommonHelper } from '_extentions/common-helper';

@Component({
  selector: 'app-equipment-history',
  templateUrl: './equipment-history.component.html',
  styleUrls: ['./equipment-history.component.scss']
})
export class EquipmentHistoryComponent implements OnInit {
  @Input() equipment: Equipment;

  historyRecords: EquipmentHistory[];

  displayedColumns: Map<string, string> = new Map([
    ['FromProject', 'From Project'],
    ['FromProjectPrimaryFundAFE', 'From Project AFE'],
    ['ToProject', 'To Project'],
    ['AllocatedByUser', 'Updated By'],
    ['AllocatedDate', 'Date'],
  ]);

  constructor(
    public helper: CommonHelper,
    private equipmentService: EquipmentService) {
  }

  ngOnInit() {
    this.initHistory();
  }

  initHistory() {
    const equipmentId = this.equipment && this.equipment.ID;
    if (!equipmentId) {
      return;
    }

    this.equipmentService
      .getHistory(equipmentId)
      .pipe(
        take(1)
      )
      .subscribe(data => {
        this.historyRecords = data;
      });
  }

  get canShowHistoryRecords() {
    return this.historyRecords && this.historyRecords.length;
  }

  get allColumns(): string[] {
    return Array.from(this.displayedColumns.keys());
  }

  getColumnTitle(column: string): string {
    return this.displayedColumns.get(column);
  }
}
