import { Component } from '@angular/core';
import { Role } from 'enums';

@Component({
  selector: 'app-mainnav-menu',
  templateUrl: './mainnav-menu.component.html',
  styleUrls: ['./mainnav-menu.component.scss']
})
export class MainNavMenuComponent {
  Role = Role;
}
