import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { ProfileType } from 'services/shared/model/graph.model';
import { GraphService } from './graph.service';
import { Inject, Injectable } from '@angular/core';
import { UserService } from '#services/api';
import 'rxjs/add/operator/finally';
import { User } from 'models';
import { Role } from 'enums';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public profile = new Subject<ProfileType | null>(); 
    public loginDisplay = new Subject<boolean | null>();
    private currentUser: User;
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,        
        private garphService: GraphService,
        private userSvc: UserService
    ){
        this.getUserProfile();
        this.setLoginDisplay();
        
    }
    
    
    getUserProfile(): void {
        this.garphService.getAdUserProfile().subscribe(userprofile =>this.profile.next(userprofile));
    }
    
    getUserProfileDetails():Observable<ProfileType>{
       return this.garphService.getAdUserProfile();
    }
    setLoginDisplay(): void {
        this.loginDisplay.next(this.msalService.instance.getAllAccounts().length > 0);
    }


    logout(): void {
        if(this.msalGuardConfig.interactionType == InteractionType.Popup){
            this.msalService.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
              });
        } else {
            this.msalService.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
        this.profile.next(null);
        this.loginDisplay.next(null);
    }

    acceptDisclaimer() {
        this.userSvc.acceptDisclaimer()
            .finally(() => {
                window.location.reload();
            })
            .subscribe();
    }
    getCurrentUser(): User {
        return this.currentUser;
    }
    hasRole(role: Role): boolean {
        let roleId= localStorage.getItem('currentUserRole');
        if(roleId==null){
            this.loadUserInfo();
            roleId= localStorage.getItem('currentUserRole');
        }
         return !!roleId
             && Number(roleId) === role;
     }
     loadUserInfo(): Promise<User> {
         return this.userSvc.getCurrentUser()
             .toPromise()
             .then((userInfo: User) => {
                 this.currentUser = userInfo;
                 localStorage.setItem('currentUserRole',this.currentUser.RoleID.toString())
                 return userInfo;
             });
     }
    
}