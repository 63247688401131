<div>
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>

    <div mat-dialog-content>
        <app-equipment-template
            [equipment]="equipment"
            [purchaseOrder]="purchaseOrder"
            [canEditProject]="canEditProject"
            [canEditPurchaseOrder]="canEditPurchaseOrder"
            (isValidChanged)="isValidChanged($event)">
        </app-equipment-template>
    </div>

    <mat-divider></mat-divider>

    <div mat-dialog-actions class="row pull-right">
        <button 
            class="btn btn-default"
            (click)="onCancelClick()">
            Cancel
        </button>
        <button 
            [disabled]="!isValid"
            class="btn btn-primary margin-left-md"
            [mat-dialog-close]="equipment">
            Ok 
        </button>
    </div>
</div>