import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

//import { ComponentsModule } from '#components/components.module';
import { NotFoundComponent } from '#components/error/not-found/not-found.component';
import { ErrorPagesModule } from '#components/error/error.pages.module';
import { HomeComponent } from '#components/home/home.component';
import { GenericErrorComponent } from '#components/error/generic-error/generic-error.component';
import { NotAuthorizedComponent } from '#components/error/not-authorized/not-authorized.component';
import { LoginComponent } from '#components/login/login.component';

import { ProjectListComponent } from '#components/project/project-list/project-list.component';
import { ProjectCreateComponent } from '#components/project/project-create/project-create.component';
import { ProjectEditComponent } from '#components/project/project-edit-dialog/project-edit-dialog.component';

import { EquipmentListComponent } from '#components/equipment/equipment-list/equipment-list.component';
import { EquipmentCreateComponent } from '#components/equipment/equipment-create/equipment-create.component';
import { EquipmentEditDialogComponent } from '#components/equipment/equipment-edit-dialog/equipment-edit-dialog.component';

import { PoListComponent } from '#components/purchase-order/po-list/po-list.component';
import { PoCreateComponent } from '#components/purchase-order/po-create/po-create.component';
import { PoEditDialogComponent } from '#components/purchase-order/po-edit-dialog/po-edit-dialog.component';

import { ReshufflingComponent } from '#components/reshuffling/reshuffling.component';
import { MilestoneImportComponent } from '#components/milestone-import/milestone-import.component';
import { OutlookReviewComponent } from '#components/outlook-review/outlook-review.component';
import { DataManagerComponent } from '#components/data-manager/data-manager.component';
import { EquipmentTypeCreateComponent } from '#components/equipment-type/equipment-type-create/equipment-type-create.component';
import { VendorCreateComponent } from '#components/vendor/vendor-create/vendor-create.component';
import { Role } from 'enums';
import { ProjectViewDialogComponent } from '#components/project/project-view-dialog/project-view-dialog.component';
import { VendorEditDialogComponent } from '#components/vendor/vendor-edit-dialog/vendor-edit-dialog.component';
import { EquipmentTypeEditDialogComponent } from '#components/equipment-type/equipment-type-edit-dialog/equipment-type-edit-dialog.component';
import { PoViewDialogComponent } from '#components/purchase-order/po-view-dialog/po-view-dialog.component';
import { EquipmentViewDialogComponent } from '#components/equipment/equipment-view-dialog/equipment-view-dialog.component';
import { UserCreateComponent } from '#components/user/user-create/user-create.component';
import { UserEditDialogComponent } from '#components/user/user-edit-dialog/user-edit-dialog.component';
import { ProcurementSyncComponent } from '#components/procurement-sync/procurement-sync.component';
import { CostTransferImportComponent } from '#components/cost-transfer-import/cost-transfer-import.component';
import { PoGapsPageComponent } from '#components/po-gaps/po-gaps-page/po-gaps-page.component';
import { MilestoneTypeCreateComponent } from '#components/milestone-type/milestone-type-create/milestone-type-create.component';
import { AreaCreateComponent } from '#components/area/area-create/area-create.component';
import { DemandSupplyComponent } from '#components/demand-supply/demand-supply.component';
import { DashboardComponent } from '#components/dashboard/dashboard.component';
import { ProjectTypeCreateComponent } from '#components/project-type/project-type-create/project-type-create.component';
import { DrawingEditDialogComponent } from '#components/drawing/drawing-edit-dialog/drawing-edit-dialog.component';
import { DrawingCreateComponent } from '#components/drawing/drawing-create/drawing-create.component';
import { EquipmentDrawingCreateDialogComponent } from '#components/equipment/equipment-drawing-create-dialog/equipment-drawing-create-dialog.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Route[] = [
    {
        path: '',
        children: [
            {
                path: 'home',
                component: HomeComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin,
                        Role.Vendor,
                        Role.User
                    ]
                }
            },

            {
                path: 'projects',
                component: ProjectListComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin,
                        Role.User
                    ]
                }
            },

            {
                path: 'equipments',
                component: EquipmentListComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin,
                        Role.Vendor,
                        Role.User
                    ]
                }
            },

            {
                path: 'purchase-orders',
                component: PoListComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin,
                        Role.User
                    ]
                }
            },

            { path: '', redirectTo: '/home', pathMatch: 'full' }
        ]
    },
    {
        path: '',
        canActivate: [MsalGuard],
        data: {
            roles: [
                Role.Admin
            ]
        },
        children: [
            { path: 'project-create', component: ProjectCreateComponent },
            { path: 'project-edit', component: ProjectEditComponent },
            { path: 'project-view', component: ProjectViewDialogComponent },

            { path: 'equipment-create', component: EquipmentCreateComponent },
            { path: 'equipment-edit', component: EquipmentEditDialogComponent },
            { path: 'equipment-view', component: EquipmentViewDialogComponent },
            { path: 'equipment-drawing-create', component: EquipmentDrawingCreateDialogComponent },

            { path: 'purchase-order-create', component: PoCreateComponent },
            { path: 'purchase-order-edit', component: PoEditDialogComponent },
            { path: 'purchase-order-view', component: PoViewDialogComponent },

            { path: 'equipment-type-create', component: EquipmentTypeCreateComponent },
            { path: 'equipment-type-edit', component: EquipmentTypeEditDialogComponent },

            { path: 'vendor-create', component: VendorCreateComponent },
            { path: 'vendor-edit', component: VendorEditDialogComponent },

            { path: 'user-create', component: UserCreateComponent },
            { path: 'user-edit', component: UserEditDialogComponent },

            { path: 'milestone-type-create', component: MilestoneTypeCreateComponent },
            { path: 'area-create', component: AreaCreateComponent },
            { path: 'project-type-create', component: ProjectTypeCreateComponent },

            { path: 'drawing-create', component: DrawingCreateComponent },
            { path: 'drawing-edit', component: DrawingEditDialogComponent }           
        ]
    },
    // Tools
    {
        path: 'tools',
        canActivate: [MsalGuard],
        data: {
            roles: [
                Role.Admin,
                Role.User,
                Role.Vendor
            ]
        },
        children: [
            {
                path: 'reshuffling',
                component: ReshufflingComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin
                    ]
                },
            },

            {
                path: 'demand-supply',
                component: DemandSupplyComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin
                    ]
                },
            },

            {
                path: 'milestone-import',
                component: MilestoneImportComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin,
                        Role.Vendor
                    ]
                },
            },

            {
                path: 'outlook-review',
                component: OutlookReviewComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin
                    ]
                },
            },

            {
                path: 'data-manager',
                component: DataManagerComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin
                    ]
                },
            },

            {
                path: 'procurement-sync',
                component: ProcurementSyncComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin
                    ]
                },
            },

            {
                path: 'cost-transfer-import',
                component: CostTransferImportComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin
                    ]
                },
            },

            {
                path: 'po-gaps',
                component: PoGapsPageComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin,
                        Role.User
                    ]
                },
            },

            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [MsalGuard],
                data: {
                    roles: [
                        Role.Admin,
                        Role.User
                    ]
                },
            },
        ]
    },
    // "Awaiting login" page
    {
        path: 'login',
        component: LoginComponent
    },
    // Error pages
    {
        path: 'error',
        children: [
            { path: 'not-found', component: NotFoundComponent },
            { path: 'internal-error', component: GenericErrorComponent },
            { path: 'not-authorized', component: NotAuthorizedComponent }
        ]
    },

    // Displays 404 "Not Found" error page for all unrecognized router.
    // Instead of redirecting, renders context to preserve original "incorrect" url
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        ErrorPagesModule,
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
