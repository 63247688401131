import { Component, OnInit } from '@angular/core';
import { MilestoneTypeService } from '#services/api/milestone-type.service';
import { Role } from 'enums';
// import { Dictionary } from 'models'};
import { Filter } from 'models';
import { take } from 'rxjs/operators';

@Component({
    selector: 'milestone-type-list',
    templateUrl: './milestone-type-list.component.html',
    styleUrls: ['./milestone-type-list.component.scss']
    // -----------------------------------------------------------------------------
    // It will help to increase performance for big collections
    // TODO need to be supported for data change methods (lock, edit, spare etc.)
    // Google it for details:
    // changeDetection: ChangeDetectionStrategy
    // -----------------------------------------------------------------------------
})

export class MilestoneTypeListComponent implements OnInit {

    milestoneTypes: any;
    Role = Role;

    filter: Filter = <Filter>{};

    public milestoneColumnsToDisplay: Map<string, string> = new Map([
        ['Name', 'Milestone Type Name'],
        ['Menu', '']
      ]);

    constructor(private milestoneTypeService: MilestoneTypeService) {}

    ngOnInit() {
        this.initMilestoneTypes();
    }

    get canShowMilestoneTypes() {
        return this.milestoneTypes && this.milestoneTypes.length;
    }

    initMilestoneTypes() {
        this.milestoneTypeService
        .getAll()
        .pipe(
          take(1)
        ).subscribe(data => {
          this.milestoneTypes = data;
        });
    }

    onSearchChanged(event: { value: string }) {
        if (!event) {
          return;
        }

        this.filter = {
          search: event.value,
          paramsCount: 0
        };
      }

      onMilestoneCollectionChanged(event) {
        if (event) {
          this.initMilestoneTypes();
        }
      }
}
