<div class="page-with-shadow">
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>
    
    <form (ngSubmit)="onSubmit()" #milestoneForm="ngForm" class="col">
        <div class="row">
            <app-vendor-dropdown class="fx-50"
                [parentFormControl]="milestoneForm.control"
                [label]="'Vendor'"
                [placeholder]="'select vendor'"
                [required]="true"
                (changed)="onFabricatorChanged($event)">
            </app-vendor-dropdown>

            <mat-form-field appearance="outline" class="fx-50">
                <mat-label>Report date</mat-label>
                <input matInput
                    [(ngModel)]="reportDate"
                    [ngModelOptions]="{standalone: true}" 
                    [matDatepicker]="reportDatePicker"
                    [matDatepickerFilter]="getReportDateFilter"
                    [min]="minReportDate"
                    [max]="maxReportDate"
                    placeholder="report date"
                    required>
                <mat-datepicker-toggle matSuffix 
                    [for]="reportDatePicker">
                </mat-datepicker-toggle>
                <mat-datepicker #reportDatePicker
                    [startAt]="minReportDate">
                </mat-datepicker>
            </mat-form-field>
        </div>
        <br/>
        <mat-divider></mat-divider>

        <ng-template *ngIf="!parsedData; then dragDropTemplate; else resultTemplate"></ng-template>
        <br>
        <div class="row pull-right">
            <button 
                type="button" 
                class="btn btn-default" 
                (click)="onCancel()">
                {{parsedData ? 'Close': 'Cancel'}}
            </button>
            <button 
                *ngIf="parsedData"
                type="button" 
                class="btn btn-primary margin-left-md"
                (click)="onCleanData()">
                Import another file
            </button>
            <button 
                *ngIf="!parsedData"
                type="submit" 
                class="btn btn-primary margin-left-md" 
                [disabled]="(!this.selectedFabricatorId) || (!this.file)">
                Import data
            </button>
        </div>
    </form>
</div>
 
<ng-template #dragDropTemplate>
    <app-drag-drop *ngIf="!parsedData"
        [format]="'.csv'" 
        (fileListChanged)="fileListChanged($event)">

        <app-empty-list-holder 
            [description]="file && file.name || 'Select your file'"
            [icon]="'cloud_upload'"
            class="col drag-drop-container">
            <p *ngIf="!file">or drag & drop it</p>
            <p *ngIf="file" class="{{ file.size >= 5242880 && 'big-file-size' }}">
                File size: {{ file.size | fileSizePipe }}
            </p>
        </app-empty-list-holder>
        
    </app-drag-drop>
</ng-template>

<ng-template #resultTemplate>
    <div *ngIf="parsedData" class="row" style="flex-wrap: nowrap;">
        <div class="col details-info-left-content">
            <p class="file-name">{{file.name}}</p>
            <br>
            <span>
                succeed
                <p class="bold-text">{{parsedData.EquipmentsSuccessfullyUpdated}}</p>
            </span>
            <span>
                errors
                <p class="bold-text">{{parsedData.Errors.length}}</p>
            </span>
            <span>
                warnings
                <p class="bold-text">{{parsedData.Warnings.length}}</p>
            </span>
        </div>

        <div class="details-info-right-content">
            <mat-tab-group>
                <mat-tab *ngIf="parsedData.Errors.length">
                    <ng-template mat-tab-label>
                        <span class="errors-tab">Errors ({{parsedData.Errors.length}})</span>
                    </ng-template>
                    <div class="col error-list">
                        <mat-list role="list" *ngFor="let e of parsedData.Errors; let i = index">
                            <span class="column">
                                <span class="error-line-number">Line {{e.LineNumber}}</span>
                                <span role="listitem" class="margin-left-md">
                                    <span style="font-weight: bolder;">Error:</span> 
                                    {{e.Message}}
                                </span>
                            </span>
                        </mat-list>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="parsedData.Warnings.length">
                    <ng-template mat-tab-label>
                        <span class="warnings-tab">Warnings ({{parsedData.Warnings.length}})</span>
                    </ng-template>
                    <div class="col warning-list">
                        <mat-list role="list" *ngFor="let e of parsedData.Warnings; let i = index">
                            <span class="column">
                                <span class="warning-line-number">Line {{e.LineNumber}}</span>
                                <span role="listitem" class="margin-left-md">
                                    <span style="font-weight: bolder;">Warning:</span> 
                                    {{e.Message}}
                                </span>
                            </span>
                        </mat-list>
                    </div>
                </mat-tab>
            </mat-tab-group>

            <app-empty-list-holder 
                *ngIf="!parsedData.Errors.length && !parsedData.Warnings.length" 
                [description]="'File has been imported with no issues.'"
                [icon]="'cloud_done'">
            </app-empty-list-holder>
        </div>        
    </div>
</ng-template>