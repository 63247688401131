
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable()
export class ToasterService {
    constructor(private messageService: MessageService) {

    }

    showSuccess(message: string, details?: string) {
        this.messageService.add({ severity: 'success', summary: message, detail: details });
    }

    showInfo(message: string, details?: string) {
        this.messageService.add({ severity: 'info', summary: message, detail: details });
    }

    showWarn(message: string, details?: string) {
        this.messageService.add({ severity: 'warn', summary: message, detail: details });
    }

    showError(message: string, details?: string) {
        this.messageService.add({ severity: 'error', sticky: true, summary: message, detail: details });
    }

    showConfirm(message: string, details?: string) {
        this.messageService.clear();
        this.messageService.add({ key: 'confirmDialog', sticky: true, severity: 'warn', summary: message, detail: details });
    }

    onConfirm() {
        this.messageService.clear('confirmDialog');
    }

    onReject() {
        this.messageService.clear('confirmDialog');
    }
}
