import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { PurchaseOrder, Equipment } from 'models';
import { CommonHelper } from '_extentions/common-helper';
import { PurchaseOrderService, EquipmentService } from '#services/api';
import { ToasterService } from '#services/shared';
import { EquipmentSelectDialogComponent } from '#components/equipment/equipment-select-dialog/equipment-select-dialog.component';
import { PoEditDialogComponent } from '../po-edit-dialog/po-edit-dialog.component';
import { EquipmentCreateDialogComponent } from '#components/equipment/equipment-create-dialog/equipment-create-dialog.component';
import { EquipmentEditArgs } from '#components/equipment/equipment-edit-dialog/equipment-edit-dialog.component';
import { ConfirmationDialogComponent } from '#components/shared/confirmation-dialog/confirmation-dialog.component';
import { DeliveryStatus } from 'enums/delivery-status';

import { rowsAnimation } from 'animations/rows.animation';
import { expandRowAnimation } from 'animations/expand-row.animation';
import { Page } from '_interfaces/page';
import { Filter } from 'models';
import { Role } from 'enums';
import { PoViewDialogComponent } from '../po-view-dialog/po-view-dialog.component';

@Component({
    selector: 'app-po-table',
    templateUrl: './po-table.component.html',
    styleUrls: ['./po-table.component.scss'],
    animations: [
        rowsAnimation,
        expandRowAnimation
    ]
})

export class PoTableComponent implements OnInit {
    @Input() columnsToDisplay: Map<string, string>;
    @Input() data: PurchaseOrder[];
    @Input() equipment: Equipment[];
    @Input()
    set filter(value: Filter) {
        this._filter = value;

        this.applyFilter(value);
    }
    get filter() {
        return this._filter;
    }

    @Output() rowExpanded = new EventEmitter();

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort)
    set content(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    _filter: Filter = <Filter>{};
    expandedItem: PurchaseOrder;
    dataSource = new MatTableDataSource<PurchaseOrder>();

    equipmentColumnsToDisplay: Map<string, string> = new Map([
        ['PurchaseOrderLineNumber', 'PO line'],
        ['IsNonStandard', ''],
        ['EquipmentType', 'Equipment type'],
        ['ProjectPrimaryAFE', 'Project primary AFE'],
        ['IsAribaAFEMismatch', '='],
        ['AribaAFE', 'Ariba AFE'],
        ['AribaCost', 'Ariba Cost'],
        ['CostTransferAFE', 'Cost Transfer AFE'],
        ['ProjectName', 'Project name'],
        ['SerialNumber', 'Serial #'],
        ['ProjectedFATDate', 'Projected FAT date'],
        ['OrderType', 'PO type'],
        ['Comment', ''],
        ['DeliveryStatus', 'Status'],
    ]);

    Role = Role;

    constructor(
        public helper: CommonHelper,
        public dialog: MatDialog,
        private poService: PurchaseOrderService,
        private equipmentService: EquipmentService,
        private toaster: ToasterService) {
    }

    ngOnInit() {
        this.dataSource.data = this.data;
        setTimeout(() => this.dataSource.paginator = this.paginator);
    }

    get allColumns(): string[] {
        return Array.from(this.columnsToDisplay.keys());
    }

    get notOrderedCount() {
        return this.equipment
            ? this.equipment.filter(i => i.StatusDeliveryID === DeliveryStatus.notOrdered).length
            : 0;
    }

    get orderedCount() {
        return this.equipment
            ? this.equipment.filter(i => i.StatusDeliveryID === DeliveryStatus.ordered).length
            : 0;
    }

    get readyToShipCount() {
        return this.equipment
            ? this.equipment.filter(i => i.StatusDeliveryID === DeliveryStatus.readyToShip).length
            : 0;
    }

    get storageCount() {
        return this.equipment
            ? this.equipment.filter(i => i.StatusDeliveryID === DeliveryStatus.storage).length
            : 0;
    }

    get shippedCount() {
        return this.equipment
            ? this.equipment.filter(i => i.StatusDeliveryID === DeliveryStatus.shipped).length
            : 0;
    }

    getColumnTitle(column: string): string {
        return this.columnsToDisplay.get(column);
    }

    applyFilter(filter: Filter) {
        this.dataSource.filter = filter.search;

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    reloadSource(projects: PurchaseOrder[]) {
        this.data = projects;
        this.dataSource.data = this.data;
    }

    async reloadDetails() {
        const id: number = this.expandedItem.ID;
        this.equipment = await this.poService.getEquipment(id).toPromise();
        this.reloadPOInfo(id);
    }

    async reloadPOInfo(id: number) {
        const updatedPO = await this.poService.get(id).toPromise();

        // update data source
        this.data.forEach((item, i) => {
            if (item.ID === id) {
                this.data[i] = updatedPO;
            }
        });

        // refresh expanded item and datasource
        this.dataSource.data = this.data;
        if (this.expandedItem) {
            this.expandedItem = updatedPO;
        }
    }

    // events
    onExpandRow(row) {
        this.expandedItem = this.expandedItem === row
            ? null
            : row;

        if (this.expandedItem) {
            this.rowExpanded.emit({ value: this.expandedItem });
        }
    }

    onEquipmentCollectionChanged(event) {
        this.reloadDetails();
    }

    async onAddExistEquipment() {
        const equipment = await this.poService.getEquipment(null).toPromise();
        const dialogRef = this.dialog.open(EquipmentSelectDialogComponent, {
            width: '50%',
            maxHeight: '90%',
            minWidth: '400px',
            minHeight: '200px',
            data: equipment
        });

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                await this.poService.addCheckedEquipmentsPurchaseOrders(this.expandedItem.ID, result.equipments).toPromise();
                this.reloadDetails();
                this.toaster.showSuccess('Success...', 'Equipment has been added.');
            }
        });
    }

    onAddNewEquipment() {
        const newEquipment = new Equipment();
        newEquipment.PurchaseOrderID = this.expandedItem.ID;
        newEquipment.PurchaseOrderNumber = `${this.expandedItem.PurchaseOrderNumber}/${this.expandedItem.RequestNumber}`;
        newEquipment.AssignedDrawing = [];

        const args: EquipmentEditArgs = {
            equipment: newEquipment,
            purchaseOrder: this.expandedItem
        };

        const dialogRef = this.dialog.open(EquipmentCreateDialogComponent, {
            width: '70%',
            maxHeight: '90%',
            minWidth: '600px',
            minHeight: '400px',
            data: args
        });

        dialogRef.afterClosed().subscribe(async (result: Equipment) => {
            if (result) {
                await this.equipmentService.create(result).toPromise().then(async (values) => {
                if (result.AssignedDrawing && result.AssignedDrawing.length > 0) {
       
                    const drawingIds: number[] = result.AssignedDrawing.map(i => i.ID);
            
                    await this.equipmentService.assignDrawing(values.ID, drawingIds).toPromise();
                  }
                });
                
                this.reloadDetails();
                this.toaster.showSuccess('Success...', 'Equipment has been added.');
            }
        });
    }

    onEditPurchaseOrder(po: PurchaseOrder, eq: Equipment) {
        const dialogRef = this.dialog.open(PoEditDialogComponent, {
            width: '50%',
            maxHeight: '80%',
            data: po
        });

        dialogRef.afterClosed().subscribe(async (result: {po: PurchaseOrder, eq: Equipment}) => {
            if (result) {
                const res = await this.poService.update(result.po).toPromise();
                const eqRes = this.equipmentService.multipleUpdate(result.eq).toPromise();

                eqRes.then(async () => {
                  this.equipment = await this.poService.getEquipment(po.ID).toPromise();
                  this.reloadPOInfo(res.ID);
                  this.toaster.showSuccess('Success...', 'Purchase order has been updated.');
                });
            }
        });
    }

    onViewPurchaseOrder(po: PurchaseOrder) {
        this.dialog.open(PoViewDialogComponent, {
            width: '50%',
            maxHeight: '80%',
            data: po
        });
    }

    onDeletePO(id: number) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            minWidth: '350px',
            minHeight: '200px',
            data: <Page>{
                pageTitle: 'Confirmation',
                pageDescription: 'Delete this PO from the system?'
            }
        });

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                await this.poService.delete(id).toPromise();

                const idx = this.data.findIndex(i => i.ID === id);
                this.data.splice(idx, 1);
                this.dataSource.data = this.data;

                this.toaster.showSuccess('Success...', 'Purchase order has been deleted.');
            }
        });
    }

    onArchivePO(id: number) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            minWidth: '350px',
            minHeight: '200px',
            data: <Page>{
                pageTitle: 'Confirmation',
                pageDescription: 'Archive this PO?'
            }
        });

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                await this.poService.archive(id).toPromise();

                const idx = this.data.findIndex(i => i.ID === id);
                this.data.splice(idx, 1);
                this.dataSource.data = this.data;

                this.toaster.showSuccess('Success...', 'Purchase order has been archived.');
            }
        });
    }

    onSortData(event) {
        // workaround to allow expand rows after sorting
        this.dataSource.data = CommonHelper.deepCopy(this.data);
    }
}
