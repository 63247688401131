import { Component, OnInit, Inject } from '@angular/core';
import { User } from 'models';
import { Page } from '_interfaces/page';
import { Role } from 'enums';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface UserEditArgs {
  user: User;
}

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss']
})
export class UserEditDialogComponent implements OnInit, Page {
  // page
  pageTitle = 'Edit user';
  pageDescription = 'edit data below to update user';

  // other properties
  user: User;
  isValid = false;
  Role = Role;

  constructor(
    public dialogRef: MatDialogRef<UserEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserEditArgs) {
  }

  ngOnInit() {
    this.user = {
      ...this.data.user
    };
  }

  onSaveClick(): void {
    if (this.isValid) {
      this.dialogRef.close(this.user);
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }
}
