<div class="page-with-shadow">
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>

    <form (ngSubmit)="onSubmit()" #reshufflingForm="ngForm" class="col">
        <app-autocomplete-dropdown
            [parentFormControl]="reshufflingForm.control"
            [options]="equipmentTypes"
            [label]="'Equipment type'"
            [displayProperty]="'Name'"
            [placeholder]="'select equipment type'"
            [required]="true"
            (selectedChanged)='onEquipmentTypeChanged($event)'
            (clicked)="onLoadEquipmentTypes()"
            class="fx-50">
        </app-autocomplete-dropdown>

        <br/>
        <mat-divider></mat-divider>

        <div class="row" *ngIf="reshuffleCollection && reshuffleCollection.length; else noOptionsTemplate">
            <div class="col fx-50">
                <span class="input-group-name">Demand ({{ demandItemsCount }})</span>
                <app-equipment-table 
                    [columnsToDisplay]="demandColumnsToDisplay" 
                    [data]="demandItems"
                    [showPadination]="false"
                    [canSort]="false"
                    [canDeleteEquipment]="false"
                    (collectionChanged)="onDemandCollectionChanged()">
                </app-equipment-table>
            </div>
            <div class="col vertical-splitter">
                <span class="vertical-line fill-remaining-space"></span>
                <mat-icon class="shuffle-icon rounded-border">arrow_forward</mat-icon>
                <span class="vertical-line fill-remaining-space"></span>
            </div>
            <div class="col fill-remaining-space">
                <span class="input-group-name">Supply ({{ supplyItemsCount }})</span>
                <app-equipment-table 
                    #supplyTable
                    [columnsToDisplay]="supplyColumnsToDisplay" 
                    [data]="supplyItems"
                    [dragDropDisabled]="false"
                    [swapDisabled]="false"
                    [showPadination]="false"
                    [showCheckAll]="false"
                    [canSort]="false"
                    [canSelect]="false"
                    (dataChanged)="onSupplyCollectionChanged($event)">
                </app-equipment-table>
            </div>
        </div>
        <br/>
        <div class="row pull-right">
            <button type="button" 
                class="btn btn-default" 
                (click)="onCancel()">
                Cancel
            </button>
            <button type="button" 
                class="btn btn-primary margin-left-md" 
                *ngIf="!canApplyChanges && selectedTypeId"
                (click)="reload()">
                Reload
            </button>
            <button type="submit" 
                class="btn btn-primary margin-left-md" 
                [disabled]="!canApplyChanges">
                Apply changes
            </button>
        </div>
    </form>
</div>

<ng-template #noOptionsTemplate>
    <app-empty-list-holder 
        [description]="'No options to perform reshuffle'">
       <span *ngIf="!selectedTypeId">
           Select equipment type from the list.
       </span>
       <span *ngIf="selectedTypeId">
            Cannot find options for selected type of equipment.
        </span>
    </app-empty-list-holder>
</ng-template>