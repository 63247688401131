import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingIndicatorService } from '#services/shared';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    constructor(public service: LoadingIndicatorService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.service.show();
        return next.handle(req).pipe(
            finalize(() => this.service.hide())
        );
    }
}
