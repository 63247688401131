<nav class="navbar navbar-default navbar-default-subsite centered" role="navigation">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="visible-xs">
        <button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon>menu</mat-icon>
        </button>

        <div class="navbar-brand navbar-brand-centered visible-xs">
            <div class="hallmark-container">
                <a class="hallmark" href="/" title="Chevron"><img alt="Chevron" src="/assets/images/hallmark.png"
                        width="36" height="40"></a>
            </div>
            <div class="sitename-container vertical-align">
                <a class="sitename light-blue font-gotham" href="/">LLFE Manager</a>
            </div>
        </div>
    </div>

    <!-- desktop -->
    <div class="top-bar hidden-xs font-gotham">
        <button mat-icon-button (click)="onToggleSidenav()" class="side-menu-item" onclick="this.blur();">
            <span class="col">
                <mat-icon>menu</mat-icon>
                <span class="">menu</span>
            </span>
        </button>

        <div class="navbar-brand navbar-brand-centered">
            <div class="hallmark-container">
                <a class="hallmark" href="/" title="Chevron">
                    <img alt="Chevron" src="/assets/images/hallmark.png" width="42" height="46">
                </a>
            </div>
            <div class="sitename-container vertical-align">
                <a class="sitename light-blue font-gotham" href="/">
                    LLFE Manager
                </a>
            </div>
        </div>

        <div class="row user-item">
            <div class="col" *ngIf="this.profile">
                <span>{{this.profile.givenName}} {{this.profile.surname}}</span>
            </div>
            <mat-icon>person</mat-icon>
        </div>
    </div>
</nav>

<app-mainnav-menu></app-mainnav-menu>