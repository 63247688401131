<p-toast></p-toast>
<app-loading-indicator></app-loading-indicator>
<app-speed-dial-fab></app-speed-dial-fab>
<mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
        <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
            <main tabindex="-1" id="main-content" role="main">
                <div *ngIf="loginDisplay" class="module box-shadow text-center">
                    <h3 style="margin-bottom: 0px;">NOTICE TO USERS: </h3>
                    <h4>
                        This Chevron software product is for use by authorized users only.
                        To the extent permitted by law, by using this software product you acknowledge and consent to
                        the
                        monitoring,
                        access (including the decryption and inspection of selected encrypted internet traffic),
                        use or disclosure by Chevron of any information generated, received or stored on this
                        system/device and
                        waive any right or expectation of privacy in connection with your use.
                        Unauthorized and/or improper use of this software product in violation of Chevron corporate
                        policies
                        will be not tolerated and may result in disciplinary action, including termination.
                    </h4>
                    <button type="button" class="btn btn-light" (click)="iAccept()">I Agree</button>
                </div>
                <div *ngIf="!loginDisplay">
                    <div class="content">
                        <router-outlet></router-outlet><!-- app-home -->
                    </div>
                </div>
            </main>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>