import { Component } from '@angular/core';
import { Page } from '_interfaces/page';

@Component({
  selector: 'app-po-gaps-page',
  templateUrl: './po-gaps-page.component.html',
  styleUrls: ['./po-gaps-page.component.scss']
})
export class PoGapsPageComponent implements Page {
  // page
  pageTitle = 'Manage PO Gaps';
  pageDescription = 'Manage purchase orders gaps between procurement and system';
}
