<app-autocomplete-dropdown
    [parentFormControl]="parentFormControl" 
    [options]="items"
    [displayProperty]="'Name'"
    [label]="label"
    [placeholder]="placeholder"
    [required]="required"
    [defaultValue]="defaultValue"
    [appearance]="appearance"
    (selectedChanged)="onItemChanged($event)">
</app-autocomplete-dropdown>