<div>
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>
    
    <div mat-dialog-content>
        <app-equipment-template *ngIf="equipment"
            [equipment]="equipment"
            [purchaseOrder]="purchaseOrder"
            (isValidChanged)="isValidChanged($event)">
        </app-equipment-template>
    </div>
    
    <mat-divider></mat-divider>
    
    <div mat-dialog-actions class="row pull-right">
        <button 
            class="btn btn-default"
            (click)="onCancelClick()">
            Cancel
        </button>
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            [disabled]="!isValid"
            class="btn btn-primary margin-left-md"
            [mat-dialog-close]="equipment">
            Apply changes 
        </button>
    </div>
</div>