import { Component, OnInit, ViewChild } from '@angular/core';
import { EquipmentType, Filter } from 'models';
import { EquipmentTypeService } from '#services/api/equipment-type.service';
import { EquipmentTypeTableComponent } from '../equipment-type-table/equipment-type-table.component';
import { Role } from 'enums';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-equipment-type-list',
  templateUrl: './equipment-type-list.component.html',
  styleUrls: ['./equipment-type-list.component.scss']
})
export class EquipmentTypeListComponent implements OnInit {
  @ViewChild(EquipmentTypeTableComponent)
  equipmentTypeTable: EquipmentTypeTableComponent;

  filter: Filter = <Filter>{};

  // collections
  equipmentTypes: EquipmentType[];

  public equipmentTypesColumnsToDisplay: Map<string, string> = new Map([
    ['Name', 'Equipment type'],
    ['OrderType', 'Order type'],
    ['Menu', '']
  ]);

  Role = Role;

  constructor(private equipmentTypeService: EquipmentTypeService) {
  }

  ngOnInit() {
    this.initEquipmentTypes();
  }

  initEquipmentTypes() {
    this.equipmentTypeService
      .getAll()
      .pipe(
        take(1)
      )
      .subscribe(data => {
        this.equipmentTypes = data;
      });
  }

  get canShowEquipmentTypes() {
    return this.equipmentTypes && this.equipmentTypes.length;
  }

  async onEquipmentTypeCollectionChanged(event) {
    if (event) {
      this.initEquipmentTypes();
    }
  }

  onSearchChanged(event: { value: string }) {
    if (!event) {
      return;
    }

    this.filter = {
      search: event.value,
      paramsCount: 0
    };
  }
}
