<div class="search-panel-container">
    <mat-form-field class="fx-25">
        <input 
            matInput 
            type="text" 
            [(ngModel)]="value"
            (keydown)="$event.stopPropagation()"
            placeholder="Search"
        >
        <button 
            mat-button 
            *ngIf="value" 
            matSuffix 
            mat-icon-button 
            matTooltip="Clear filter"
            aria-label="Clear" 
            (click)="value=''"
        >
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</div>