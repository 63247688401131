import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '_interfaces/page';
import { AuthService } from '#services/shared';
import { Role } from 'enums';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, Page {
    pageTitle = 'Home page';
    pageDescription = 'some description';

    constructor(private router: Router, private authSvc: AuthService) { }

    ngOnInit() {
        const defaultUrl = this.authSvc.hasRole(Role.Vendor)
            ? '/equipments'
            : '/projects';

        this.router.navigate([defaultUrl]);
    }
}
