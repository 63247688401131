<mat-form-field class="fx-100" (click)="onLoad();">
    <mat-label>Vendors</mat-label>
    <mat-select
        #vendorsSelect
        placeholder="select vendors"
        [formControl]="vendorsControl"
        (closed)="onClose($event)"
        (selectionChange)="onChange($event)"
        disableOptionCentering
        multiple>
        <mat-option>
          <ngx-mat-select-search [formControl]="vendorsFilterControl"
              [placeholderLabel]="'Search...'"
              [noEntriesFoundLabel] ="'Not found'" ></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
            {{vendorsControl.value?.length > 0 ? vendorsControl.value[0].Name : ''}}
            <span
                *ngIf="vendorsControl.value?.length > 1"
                class="additional-selection">
                    (+{{vendorsControl.value.length - 1}}
                    {{vendorsControl.value?.length === 2 ? 'other' : 'others'}})
            </span>
        </mat-select-trigger>

        <mat-option *ngFor="let vendor of filteredVendorsMulti | async" [value]="vendor">
            {{vendor.Name}}
        </mat-option>
    </mat-select>
</mat-form-field>
