<div>
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>

    <div mat-dialog-content>
        <div class="col">
            <mat-form-field class="fx-33 filter-panel">
                <input matInput
                    placeholder="Search"
                    (keyup)="onKey($event)">
            </mat-form-field>
        </div>

        <app-project-table
            *ngIf="projects && projects.length; else noProjectsTemplate"
            [columnsToDisplay]="projectColumnsToDisplay" 
            [data]="projects"
            (selectedChanged)="onItemClick($event)"
            [filter]="filter"
            [canExpand]="false">
        </app-project-table>
    </div>

    <mat-divider></mat-divider>

    <div mat-dialog-actions class="row pull-right">
        <button 
            class="btn btn-default"
            (click)="onCancelClick()">
            Cancel
        </button>
        <button 
            *ngIf="projects && projects.length"
            [disabled]="!selectedProject"
            class="btn btn-primary margin-left-md"
            [mat-dialog-close]="selectedProject">
            Ok 
        </button>
    </div>
</div>

<ng-template #noProjectsTemplate>
    <app-empty-list-holder 
        [description]="'No templates available.'">
    </app-empty-list-holder>
</ng-template>