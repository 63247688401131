import { Injectable } from '@angular/core';
import { FabButton } from 'models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpeedButtonService {
    private fabButtons: FabButton[];
    subject: BehaviorSubject<FabButton[]>;

    constructor() {
        this.subject = new BehaviorSubject(this.fabButtons);
    }

    public update(fabButtons: FabButton[]): void {
        this.subject.next(fabButtons);
    }

    public clear(): void {
        this.subject.next(null);
    }
}
