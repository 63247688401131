import { Component, Input, Output, EventEmitter, DoCheck } from '@angular/core';
import { DictionaryService } from '#services/api/index';
import { Project, Dictionary, Choice } from 'models';
import { Dictionaries } from 'enums/dictionary';
import { User } from 'models';
import { ProjectStatus } from 'enums/project-status';
import { ReviewStatus } from 'enums/review-status';

@Component({
    selector: 'app-project-template',
    templateUrl: './project-template.component.html',
    styleUrls: ['./project-template.component.scss']
})

export class ProjectTemplateComponent implements DoCheck {
    @Input() project: Project;
    @Output() isValidChanged = new EventEmitter();

    types: Dictionary[];
    teams: Dictionary[];
    statuses: Dictionary[];
    reviewStatuses: Dictionary[];
    choices: Choice[];
    areas: Dictionary[];

    constructor(private dictionaryService: DictionaryService) { }

    ngDoCheck(): void {
        this.isValidChanged.emit(!this.isInvalid);
    }

    // default dictionary values
    get projectTypeDefValue(): Dictionary {
        if (!this.project.TypeID) {
            return null;
        }

        return new Dictionary(this.project.TypeID, this.project.Type);
    }

    get projectStatusDefValue(): Dictionary {
        return new Dictionary(this.project.StatusID, this.project.Status);
    }

    get reviewStatusDefValue(): Dictionary {
        return new Dictionary(this.project.ReviewStatusID, this.project.ReviewStatus);
    }

    get representativeDefValue(): User {
        if (!this.project.Representative) {
            return null;
        }

        return {
            FirstName: this.project.Representative.split(' ')[0],
            LastName: this.project.Representative.split(' ')[1],
            Email: this.project.RepresentativeEmail
        } as User;
    }

    get projectTeamDefValue(): Dictionary {
        if (!this.project.TeamID) {
            return null;
        }

        return new Dictionary(this.project.TeamID, this.project.Team);
    }

    get projectTemplateDefValue(): Choice {
        return new Choice(this.project.Template, this.project.Template ? 'Yes' : 'No');
    }

    get isInvalid(): boolean {
        return !this.project ||
            !this.project.Name ||
            !this.project.TypeID ||
            !this.project.StatusID ||
            !this.project.NeedByDate ||
            !this.project.TargetCompletionDate ||
            !this.project.ReviewStatusID ||
            !this.project.ReviewDate;
    }

    get projectAreaDefValue(): Dictionary {
        if (!this.project.AreaID) {
            return null;
        }
        return new Dictionary(this.project.AreaID, this.project.Area);
    }

    // events
    onProjectTypeChanged(event) {
        if (event.value) {
            this.project.Type = event.value.Name;
            this.project.TypeID = event.value.ID;
        }
    }

    onProjectStatusChanged(event) {
        if (event.value) {
            this.project.Status = event.value.Name;
            this.project.StatusID = event.value.ID;
        }
    }

    onProjectTemplateChanged(event) {
        if (event.value) {
            this.project.Template = event.value.ID;
        }
    }

    onTeamChanged(event) {
        if (event.value) {
            this.project.Team = event.value.Name;
            this.project.TeamID = event.value.ID;
        }
    }

    onRepresentativePersonChanged(event) {
        const user = event.value as User;

        if (user) {
            this.project.Representative = user.FullName;
            this.project.RepresentativeEmail = user.Email;
        } else {
            this.project.Representative = null;
            this.project.RepresentativeEmail = null;
        }
    }

    onReviewStatusChanged(event) {
        if (event.value) {
            this.project.ReviewStatus = event.value.Name;
            this.project.ReviewStatusID = event.value.ID;

            if (this.project.ReviewStatusID === ReviewStatus.reviewed) {
                this.project.ReviewDate = new Date();
            }
        }
    }

    onCostCenterCodesChanged(event) {
        if (event.value) {
            this.project.CostCenterCodes = event.value;
        }
    }

    onAreaChanged(event) {
        if (event.value) {
            this.project.Area = event.value.Name;
            this.project.AreaID = event.value.ID;
        }
    }

    // async events
    async onLoadProjectStatuses() {
        if (!this.statuses) {
            this.statuses = await this.dictionaryService
                .getAll(`${Dictionaries.projectStatuses}/dictionary`)
                .toPromise()
                .then(d =>
                    d.filter(i => i.ID !== ProjectStatus.cancelled));
        }
    }

    async onTypeDDClick() {
        if (!this.types) {
            this.types = await this.dictionaryService
                .getAll(`${Dictionaries.projectTypes}`)
                .toPromise();
        }
    }

    async onTeamDDClick() {
        if (!this.teams) {
            this.teams = await this.dictionaryService
                .getAll(`${Dictionaries.teams}/dictionary`)
                .toPromise();
        }
    }

    async onLoadReviewStatuses() {
        if (!this.reviewStatuses) {
            this.reviewStatuses = await this.dictionaryService.getAll(
                `${Dictionaries.reviewStatuses}/dictionary`).toPromise();
        }
    }

    async onAreaDDClick() {
        if (!this.areas) {
            this.areas = await this.dictionaryService
                .getAll(`${Dictionaries.area}`)
                .toPromise();
        }
    }

    onLoadTemplateChoices() {
        if (!this.choices) {
            this.choices = [
                { ID: true, Name: 'Yes' } as Choice,
                { ID: false, Name: 'No' } as Choice
            ];
        }
    }
}
