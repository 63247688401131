import { Component, OnInit, Inject } from '@angular/core';
import { Page } from '_interfaces/page';
import { Equipment, Project } from 'models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonHelper } from '_extentions/common-helper';
import { ProjectService } from '#services/api';

@Component({
  selector: 'app-project-view-dialog',
  templateUrl: './project-view-dialog.component.html',
  styleUrls: ['./project-view-dialog.component.scss']
})
export class ProjectViewDialogComponent implements OnInit, Page {
  // page
  pageTitle = 'View project';
  pageDescription = 'view data below to view project';

  // other properties
  project: Project;
  equipment: Equipment[];

  equipmentColumnsToDisplay: Map<string, string> = new Map([
    ['PurchaseOrderLineNumber', 'PO line'],
    ['EquipmentType', 'Equipment type'],
    ['ProjectPrimaryAFE', 'Project primary AFE'],
    ['IsAribaAFEMismatch', '='],
    ['AribaAFE', 'Ariba AFE'],
    ['AribaCost', 'Ariba Cost'],
    ['CostTransferAFE', 'Cost Transfer AFE'],
    ['SerialNumber', 'Serial #'],
    ['ProjectedFATDate', 'Projected FAT date'],
    ['OrderType', 'PO type'],
    ['DeliveryStatus', 'Status'],
  ]);

  constructor(
      public dialogRef: MatDialogRef<ProjectViewDialogComponent>,
      private projectService: ProjectService,
      @Inject(MAT_DIALOG_DATA) public data: Project) {
  }

  async ngOnInit() {
      this.project = CommonHelper.deepCopy(this.data);
      this.projectService.getEquipment(this.project.ID).subscribe( res => this.equipment = res);
  }

  onCloseClick(): void {
      this.dialogRef.close();
  }
}
