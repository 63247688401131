<div class="page-with-shadow">
  <app-page-header [title]="pageTitle"
                   [description]="pageDescription">
  </app-page-header>

  <form (ngSubmit)="onSubmit()" #drawingNewForm="ngForm" class="col" (keyup.enter)="onSubmit()">
    <app-drawing-template [drawing]="drawing"
                          (isValidChanged)="isValidChanged($event)"
                          (fileChanged)="fileChanged($event)">
    </app-drawing-template>

    <div class="row pull-right">
      <button type="button"
              class="btn btn-default"
              (click)="onCancel()">
        Cancel
      </button>
      <button type="submit"
              class="btn btn-primary margin-left-md"
              [disabled]="!isValid">
        Create new drawing
      </button>
    </div>
  </form>
</div>
