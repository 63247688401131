<form #poListForm="ngForm" class="page-with-shadow">
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>
    
    <mat-toolbar class="page-toolbar" *ngIf="canShowPurchaseOrders">
        <span class="project-count">
            {{purchaseOrders && purchaseOrders.length}} orders
        </span>
        <span class="fill-remaining-space"></span>
        
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-default"
            color="primary"
            [routerLink]="['/tools/procurement-sync']">
            Synchronize
            <mat-icon class="sync-icon">sync</mat-icon>
        </button>
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary margin-left-md" 
            [routerLink]="'/purchase-order-create'">
            Add new purchase order
        </button>
    </mat-toolbar>

    <div *ngIf="canShowPurchaseOrders; else noPOsTemplate">
        <mat-divider></mat-divider>
        <app-filter-panel [paramsCount]="filter.paramsCount" (searchChanged)="onUpdateFilters($event)">
            <div class="row fx-100">
                <mat-form-field class="fx-15">
                    <mat-label>Submit date (from)</mat-label>
                    <input matInput #fromRef 
                        [(ngModel)]="submitDateFrom"
                        [ngModelOptions]="{standalone: true}" 
                        [matDatepicker]="submitDateFromDatePicker"
                        placeholder="submit date from"
                        (ngModelChange)="onFilterChanged()">
                    <mat-datepicker-toggle matSuffix 
                        [for]="submitDateFromDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #submitDateFromDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="fx-15">
                    <mat-label>Submit date (to)</mat-label>
                    <input matInput #toRef 
                        [(ngModel)]="submitDateTo" 
                        [ngModelOptions]="{standalone: true}"
                        [matDatepicker]="submitDateToPicker"
                        placeholder="submit date to"
                        (ngModelChange)="onFilterChanged()">
                    <mat-datepicker-toggle matSuffix 
                        [for]="submitDateToPicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #submitDateToPicker></mat-datepicker>
                </mat-form-field>

                <!-- Vendors filter -->
                <app-vendor-dropdown-multiple
                    #equipmentTypesSelect
                    class="fx-25"
                    (closed)="onFilterChanged()"
                    (changed)="vendorSelectionChange($event)"
                ></app-vendor-dropdown-multiple>

                <mat-slide-toggle class="fx-10 margin-left-md" style="align-self: center;"
                    [(ngModel)]="includeArchived" 
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onFilterChanged()">
                    Archived
                </mat-slide-toggle>
            </div>
        </app-filter-panel>
        <app-po-table
            [data]="purchaseOrders"
            [equipment]="equipment"
            [columnsToDisplay]="columnsToDisplay"
            (rowExpanded)="onPORowExpanded($event)"
            [filter]="filter">
        </app-po-table>
    </div>
</form>

<ng-template #noPOsTemplate>
    <app-empty-list-holder 
        [description]="'No purchase orders added yet.'">
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary"
            [routerLink]="'/purchase-order-create'">
            Add new purchase order
        </button>
    </app-empty-list-holder>
</ng-template>