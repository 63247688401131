import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

import * as dayjs from 'dayjs';
import { CommonHelper } from '_extentions/common-helper';
import { Project, Equipment } from 'models';
import { EquipmentSelectDialogComponent } from '#components/equipment/equipment-select-dialog/equipment-select-dialog.component';
import { ProjectService, EquipmentService } from '#services/api';
import { ToasterService, PrintService } from '#services/shared';
import { ProjectEditComponent } from '#components/project/project-edit-dialog/project-edit-dialog.component';
import { EquipmentCreateDialogComponent } from '#components/equipment/equipment-create-dialog/equipment-create-dialog.component';
import { EquipmentEditArgs } from '#components/equipment/equipment-edit-dialog/equipment-edit-dialog.component';
import { ConfirmationDialogComponent } from '#components/shared/confirmation-dialog/confirmation-dialog.component';
import { ProjectStatus } from 'enums/project-status';
import { rowsAnimation } from 'animations/rows.animation';
import { expandRowAnimation } from 'animations/expand-row.animation';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ReviewStatus } from 'enums/review-status';
import { BulkOperationResult } from 'models';
import { Filter } from 'models';
import { Page } from '_interfaces/page';
import { Role } from 'enums';
import { ProjectViewDialogComponent } from '../project-view-dialog/project-view-dialog.component';
import { take } from 'rxjs/operators';
import { ProjectSelectDialogComponent } from '../project-select-dialog/project-select-dialog.component';

@Component({
    selector: 'app-project-table',
    templateUrl: './project-table.component.html',
    styleUrls: ['./project-table.component.scss'],
    animations: [
        rowsAnimation,
        expandRowAnimation
    ]
})

export class ProjectTableComponent implements OnInit {
    @Input() canExpand = true;
    @Input() canSelect = true;
    @Input() columnsToDisplay: Map<string, string>;
    @Input() data: Project[];
    @Input() isOutlookReview: boolean;

    @Input()
    set filter(value: Filter) {
        this._filter = value;

        this.applyFilter(value);
    }
    get filter() {
        return this._filter;
    }

    @Output() selectedChanged = new EventEmitter();
    @Output() checkedItemsChanged = new EventEmitter<Project[]>();

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort)
    set content(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    _filter: Filter = <Filter>{};
    expandedItem: Project;
    equipment: Equipment[];
    dataSource = new MatTableDataSource<Project>();

    Role = Role;
    selectedItem: Project | null;

    equipmentColumnsToDisplay: Map<string, string> = new Map([
        ['_checked', ''],
        ['Locked', ''],
        ['SerialNumber', 'Serial #'],
        ['IsNonStandard', ''],
        ['EquipmentType', 'Equipment type'],
        ['Vendor', 'Vendor'],
        ['ProjectPrimaryAFE', 'Project primary AFE'],
        ['IsAribaAFEMismatch', '='],
        ['AribaAFE', 'Ariba AFE'],
        ['AribaCost', 'Ariba Cost'],
        ['CostTransferAFE', 'Cost Transfer AFE'],
        ['ProjectedFATDate', 'Projected FAT date'],
        ['PurchaseOrderNumber', 'PO #'],
        ['PurchaseOrderLineNumber', 'PO line'],
        ['OrderType', 'PO type'],
        ['Comment', ''],
        ['DeliveryStatus', 'Status'],
        ['Menu', ''],
    ]);

    constructor(
        public helper: CommonHelper,
        public dialog: MatDialog,
        private projectService: ProjectService,
        private equipmentService: EquipmentService,
        private printService: PrintService,
        private toaster: ToasterService) {
    }

    ngOnInit() {
        this.dataSource.data = this.data;
        setTimeout(() => this.dataSource.paginator = this.paginator);
    }

    get allColumns(): string[] {
        return Array.from(this.columnsToDisplay.keys());
    }

    get dateLeftToStart() {
        if (!this.expandedItem.TargetCompletionDate) {
            return 'unknown';
        }

        const nbd = dayjs(this.expandedItem.TargetCompletionDate);
        const diff = nbd.diff(dayjs(), 'd');

        return diff < 0
            ? 'expired'
            : diff;
    }

    get canShowTable() {
        const hasSearchedItems = this.filter.search
            ? this.dataSource.filteredData.length
            : true;

        const hasFilterParamsItems = this.filter.paramsCount
            ? this.dataSource.data.length
            : true;

        return hasSearchedItems && hasFilterParamsItems;
    }

    getColumnTitle(column: string): string {
        return this.columnsToDisplay.get(column);
    }

    applyFilter(filter: Filter) {
        this.dataSource.filter = filter.search;

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    async changeReviewStatus(ids: number[], newStatus: ReviewStatus) {
        const response = await this.projectService
            .changeReviewStatus(ids, newStatus)
            .toPromise();

        const projects: Project[] = response['ID']
            ? [response]
            : (<BulkOperationResult<Project>>response).SuccessfulItems;

        this.updateDataSource(projects);

        if (response.Warning) {
            this.toaster.showWarn('Warning...', response.Warning);
        } else {
            this.toaster.showSuccess('Success...', 'Review status changed');
        }
    }

    async reloadDetails() {
        const id = this.expandedItem.ID;
        this.equipment = await this.projectService.getEquipment(id).toPromise();

        this.reloadProjectInfo(id);
    }

    async reloadProjectInfo(id: number) {
        const response = await this.projectService.get(id).toPromise();
        this.updateDataSource([response]);
    }

    updateDataSource(projects: Project[]) {
        // update data source
        projects.forEach(p => {
            this.data.forEach((item, i) => {
                if (item.ID === p.ID) {
                    const checked = this.data[i]['_checked'];
                    this.data[i] = p;
                    this.data[i]['_checked'] = checked;

                    // update expanded item
                    if (this.expandedItem && this.expandedItem.ID === p.ID) {
                        this.expandedItem = p;
                    }
                }
            });
        });

        // refresh equipment collection
        this.dataSource.data = this.data;
    }

    reloadSource(projects: Project[]) {
        this.data = projects;
        this.dataSource.data = this.data;
    }

    updateEquipmentDates(equipment: Equipment) {
        equipment.NeedByDate = this.expandedItem.NeedByDate;
        equipment.TargetCompletionDate = this.expandedItem.TargetCompletionDate;
    }

    onRowClick(row) {
        if (this.canExpand) {
            this.onExpandRow(row);
        }

        if (this.canSelect) {
            this.onSelectRow(row);
        }
    }

    // events
    async onExpandRow(row) {
        this.expandedItem = this.expandedItem === row
            ? null
            : row;

        this.equipment = this.expandedItem
            ? await this.projectService.getEquipment(this.expandedItem.ID).toPromise()
            : null;
    }

    onSelectRow(row) {
        this.selectedItem = row;
        this.selectedChanged.emit({ value: row });
    }

    onCheckedAll(event: MatCheckboxChange) {
        this.dataSource.filteredData.forEach(p => {
            p['_checked'] = event.checked;
        });

        this.onChecked();
    }

    onChecked() {
        this.checkedItemsChanged.emit(
            this.dataSource.filteredData
                .filter(p => p['_checked']));
    }

    onEquipmentCollectionChanged(event) {
        this.reloadDetails();
    }

    onMarkAsReviewed(id: number) {
        this.changeReviewStatus([id], ReviewStatus.reviewed);
    }

    onMarkAsNotReviewed(id: number) {
        this.changeReviewStatus([id], ReviewStatus.notReviewed);
    }

    onMarkAsTemplate(id: number) {
        this.projectService.markAsTemplate(id)
            .pipe(take(1))
            .subscribe(data => {
                this.reloadProjectInfo(id);
                this.toaster.showSuccess('Success...', 'Project has been marked as a template');
            });
    }

    onMarkAsNonTemplate(id: number) {
        this.projectService.markAsNonTemplate(id)
            .pipe(take(1))
            .subscribe(data => {
                this.reloadProjectInfo(id);
                this.toaster.showSuccess('Success...', 'Project has been marked as a non-template');
            });
    }

    async onAddExistEquipment() {
        const equipment = await this.projectService.getEquipment(null).toPromise();
        const dialogRef = this.dialog.open(EquipmentSelectDialogComponent, {
            width: '50%',
            maxHeight: '90%',
            minWidth: '400px',
            minHeight: '200px',
            data: equipment
        });

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                this.updateEquipmentDates(result);
                await this.projectService.addCheckedEquipments(this.expandedItem.ID, result.equipments).toPromise();

                this.reloadDetails();
                this.toaster.showSuccess('Success...', 'Equipment has been added.');
            }
        });
    }

    onAddNewEquipment() {
        const newEquipment = new Equipment();
        newEquipment.ProjectID = this.expandedItem.ID;
        newEquipment.ProjectName = this.expandedItem.Name;
        newEquipment.TargetCompletionDate = this.expandedItem.TargetCompletionDate;
        newEquipment.NeedByDate = this.expandedItem.NeedByDate;

        const args: EquipmentEditArgs = {
            equipment: newEquipment,
            purchaseOrder: null
        };

        const dialogRef = this.dialog.open(EquipmentCreateDialogComponent, {
            width: '70%',
            maxHeight: '90%',
            minWidth: '600px',
            minHeight: '400px',
            data: args
        });

        dialogRef.afterClosed().subscribe(async (result: Equipment) => {
            if (result) {
                this.updateEquipmentDates(result);
                await this.equipmentService.create(result).toPromise();

                this.reloadDetails();
                this.toaster.showSuccess('Success...', 'Equipment has been added.');
            }
        });
    }

    async onAddEquipmentFromTemplate() {
        const templates = await this.projectService.getTemplates().toPromise();

        const dialogRef = this.dialog.open(ProjectSelectDialogComponent, {
            width: '50%',
            maxHeight: '90%',
            minWidth: '400px',
            minHeight: '200px',
            data: templates
        });

        dialogRef.afterClosed().subscribe(async (result: Project) => {
            if (result) {
                await this.projectService.addEquipments(this.expandedItem.ID, result.ID).toPromise();

                this.reloadDetails();
                this.toaster.showSuccess('Success...', 'Template equipments have been added.');
            }
        });
    }

    onEditProject(project: Project) {
        const dialogRef = this.dialog.open(ProjectEditComponent, {
            width: '-webkit-fill-available',
            maxHeight: '80%',
            data: project
        });

        dialogRef.afterClosed().subscribe(async (result: Project) => {
            if (result) {
                const res = await this.projectService.update(result).toPromise();
                this.reloadProjectInfo(res.ID);

                this.toaster.showSuccess('Success...', 'Project has been updated.');
            }
        });
    }

    onViewProject(project: Project) {
        this.dialog.open(ProjectViewDialogComponent, {
            width: '-webkit-fill-available',
            maxHeight: '80%',
            data: project
        });
    }

    onCancelProject(id: number) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            minWidth: '350px',
            minHeight: '200px',
            data: <Page>{
                pageTitle: 'Confirmation',
                pageDescription: 'All equipment will be moved to spare. Cancel this project?'
            }
        });

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                const res = await this.projectService.changeStatus(id, ProjectStatus.cancelled).toPromise();
                this.reloadProjectInfo(res.ID);

                this.toaster.showSuccess('Success...', 'Project has been cancelled.');
            }
        });
    }

    onDeleteProject(id: number) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            minWidth: '350px',
            minHeight: '200px',
            data: <Page>{
                pageTitle: 'Confirmation',
                pageDescription: 'Delete this project from the system?'
            }
        });

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                await this.projectService.delete(id).toPromise();

                const idx = this.data.findIndex(i => i.ID === id);
                this.data.splice(idx, 1);
                this.dataSource.data = this.data;

                this.toaster.showSuccess('Success...', 'Project has been deleted.');
            }
        });
    }

    onArchiveProject(id: number) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            minWidth: '350px',
            minHeight: '200px',
            data: <Page>{
                pageTitle: 'Confirmation',
                pageDescription: 'Archive this project?'
            }
        });

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                await this.projectService.archive(id).toPromise();

                const idx = this.data.findIndex(i => i.ID === id);
                this.data.splice(idx, 1);
                this.dataSource.data = this.data;

                this.toaster.showSuccess('Success...', 'Project has been archived.');
            }
        });
    }

    onPrintProject(project: Project) {
        this.projectService.getEquipment(project.ID)
            .pipe(take(1))
            .subscribe(equpments => {
                this.printService.print(project, equpments);
            });
    }

    onSortData(event) {
        // workaround to allow expand rows after sorting
        this.dataSource.data = CommonHelper.deepCopy(this.data);
    }



}
