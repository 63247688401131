import { Component, OnInit, Input } from '@angular/core';
import { PoGap, Filter } from 'models';
import { Role } from 'enums';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '#components/shared/confirmation-dialog/confirmation-dialog.component';
import { Page } from '_interfaces/page';
import { PoGapService } from '#services/api/po-gap.service';
import { Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { ToasterService } from '#services/shared';

@Component({
  selector: 'app-po-gaps-list',
  templateUrl: './po-gaps-list.component.html',
  styleUrls: ['./po-gaps-list.component.scss']
})
export class PoGapsListComponent implements OnInit {
  filter: Filter = <Filter>{};

  @Input() ignored: boolean;

  public poGaps: PoGap[];

  public columnsToDisplay: Map<string, string> = new Map([
    ['PurchaseOrderNumber', 'PO'],
    ['Title', 'Title'],
    ['Description', 'Description'],
    ['Vendor', 'Vendor'],
    ['RequestorName', 'Requestor'],
    ['Status', ''],
    ['Menu', '']
  ]);

  Role = Role;

  constructor(
    private poGapService: PoGapService,
    private dialog: MatDialog,
    private toaster: ToasterService) { }

  ngOnInit() {
    this.poGapService.getAll(this.ignored)
      .pipe(
        map(data => this.filterIgnored(data, this.ignored)),
        take(1)
      )
      .subscribe(data => {
        this.poGaps = data;
      });
  }

  get canShowPoGaps() {
    return this.poGaps && this.poGaps.length;
  }

  filterIgnored(data: PoGap[], ignored: boolean) {
    return (data || []).filter(d => d.Ignored === ignored);
  }

  onSubmit() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '350px',
      minHeight: '200px',
      data: <Page>{
        pageTitle: 'Confirmation',
        pageDescription: 'You are going to update database. Are you sure?'
      }
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        this.sync()
          .pipe(
            tap(data => this.showSyncNotification(data)),
            map(data => this.filterIgnored(data, this.ignored)),
            take(1)
          )
          .subscribe(data => {
            this.poGaps = data;
          });
        }
    });
  }

  refresh(): void {
    window.location.reload();
  }

  sync(): Observable<PoGap[]> {
    return this.poGapService.sync();
  }

  showSyncNotification(gaps: PoGap[]) {
    const actual = gaps || [];

    if (actual.length) {
      this.toaster.showSuccess('Success...', `Data was successfully synchronized (${actual.length} rows).`);
    } else {
      this.toaster.showWarn('Warning...', 'No data was synchronized.');
    }
  }

  onItemChanged(event: { ID: number, Status: string }) {
    const items = this.poGaps.map((item) => {
      if (item.ID !== event.ID) {
        return item;
      }

      return {
        ...item,
        ...event
      } as PoGap;
    });
    this.poGaps = items;
  }

  onSearchChanged(event: { value: string }) {
    if (!event) {
      return;
    }

    this.filter = {
      search: event.value,
      paramsCount: 0
    };
  }
}
