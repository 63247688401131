<div class="col">
    <mat-table
        *ngIf="canShowTable; else noMatchesTemplate"
        matSort
        [dataSource]="dataSource"
        class="mat-table-container">
        <ng-container
            matColumnDef="{{column}}"
            *ngFor="let column of allColumns">

            <ng-container>
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{getColumnTitle(column)}}
                </mat-header-cell>
            </ng-container>

            <mat-header-cell *matHeaderCellDef>
                {{getColumnTitle(column)}}
            </mat-header-cell>

            <mat-cell class="mat-cell-value" *matCellDef="let item">
                <ng-container
                    [ngTemplateOutlet]="columnContent"
                    [ngTemplateOutletContext]="{item: item, column: column}">
                </ng-container>
            </mat-cell>

        </ng-container>

        <mat-header-row *matHeaderRowDef="allColumns; sticky: true"></mat-header-row>
        <mat-row
            *matRowDef="let row; columns: allColumns; let entry"
            class="element-row">
        </mat-row>
    </mat-table>
</div>

<mat-paginator
    [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons>
</mat-paginator>

<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item
            *ngIf="!item.CreatedAt"
            (click)="create(item)">
            <mat-icon>assignment</mat-icon>
            Create
        </button>
        <button mat-menu-item
            *ngIf="!item.Ignored && !item.CreatedAt"
            (click)="ignore(item)">
            <mat-icon>visibility_off</mat-icon>
            Ignore
        </button>
        <button mat-menu-item
            *ngIf="item.Ignored && !item.CreatedAt"
            (click)="unignore(item)">
            <mat-icon>visibility</mat-icon>
            Un-ignore
        </button>
    </ng-template>
</mat-menu>

<ng-template #noMatchesTemplate>
    <app-empty-list-holder
        [icon]="'search'"
        [description]="'No matches'">
    </app-empty-list-holder>
</ng-template>

<ng-template #columnContent let-item="item" let-column="column">
    <ng-container [ngSwitch]="column">
        <span *ngSwitchCase="'Status'">
            <mat-icon *ngIf="item['CreatedAt']">
                assignment
            </mat-icon>

            <mat-icon *ngIf="!item['CreatedAt'] && item['IgnoredAt']">
                visibility_off
            </mat-icon>

            <mat-icon *ngIf="!item['CreatedAt'] && item['UnignoredAt']">
                visibility_on
            </mat-icon>
        </span>

        <span *ngSwitchDefault>
          {{
              helper.isDate(item[column])
              ? (item[column] | dateFormatPipe)
              : (item[column])
          }}
        </span>

        <ng-container *appUserRoleUp="[ Role.Admin ]">
            <ng-container *ngIf="item?.ID > 0 && !item?.CreatedAt">
                <button *ngSwitchCase="'Menu'"
                    mat-button
                    class="menu-button pull-left"
                    (click)="$event.preventDefault()"
                    [matMenuTriggerFor]="menu"
                    [matMenuTriggerData]="{item: item}">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>
