
<div class="col">
  <mat-table *ngIf="canShowTable; else noMatchesTemplate"
             matSort
             [dataSource]="dataSource"
             class="mat-table-container">

    <ng-container matColumnDef="{{column}}"
                  *ngFor="let column of allColumns">
      <ng-container>
        <mat-header-cell  *matHeaderCellDef >

          <mat-checkbox *ngIf="column=='ID'" (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>

          <span *ngIf="column!='ID'" mat-sort-header>{{getColumnTitle(column)}}</span>
        </mat-header-cell>
      </ng-container>

      <mat-header-cell *matHeaderCellDef>
        {{getColumnTitle(column)}}
      </mat-header-cell>

      <mat-cell class="mat-cell-value" *matCellDef="let item">
        <ng-container [ngTemplateOutlet]="columnContent"
                      [ngTemplateOutletContext]="{item: item, column: column}">
        </ng-container>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="allColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: allColumns; let entry"
             class="element-row">
    </mat-row>
  </mat-table>
</div>

<mat-paginator [pageSizeOptions]="[10, 25, 50]"
               showFirstLastButtons>
</mat-paginator>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item
            (click)="onEditDrawing(item)">
      <mat-icon>edit</mat-icon>
      Edit
    </button>

    <button mat-menu-item
            *ngIf="item.IsDisabled"
            (click)="onEnableDrawing(item)">
      <mat-icon>power</mat-icon>
      Enable
    </button>

    <button mat-menu-item
            *ngIf="!item.IsDisabled"
            (click)="onDisableDrawing(item)">
      <mat-icon>power_off</mat-icon>
      Disable
    </button>
  </ng-template>
</mat-menu>

<ng-template #noMatchesTemplate>
  <app-empty-list-holder [icon]="'search'"
                         [description]="'No matches'">
  </app-empty-list-holder>
</ng-template>

<ng-template #columnContent let-item="item" let-column="column">
  <ng-container [ngSwitch]="column">
    <span *ngSwitchCase="'ID'">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event? selection.toggle(item) : null"
                    [checked]="selection.isSelected(item)">
      </mat-checkbox>
    </span>

    <span *ngSwitchCase="'DocumentName'">

      <a (click)="downloadDrawing(item)">{{ item[column] }}</a>
    </span>

    <span *ngSwitchDefault>
      {{ item[column] }}
    </span>

    <span *ngSwitchCase="'IsDisabled'">
      <i *ngIf="!item[column]"
         class="pi pi-circle-on active-icon"></i>

      <i *ngIf="item[column]"
         class="pi pi-circle-off disabled-icon"></i>
    </span>
    <span *ngSwitchCase="'EquipmentTypes'" matTooltip="{{ displayEquipmentType(item[column]) }}">
    <span> {{ displayEquipmentType(item[column]) }}</span>
    </span>
   
    <ng-container *appUserRoleUp="[ Role.Admin ]">
      <ng-container *ngIf="item?.ID > 0">
        <button *ngSwitchCase="'Menu'"
                mat-button
                class="menu-button pull-left"
                (click)="$event.preventDefault()"
                [matMenuTriggerFor]="menu"
                [matMenuTriggerData]="{item: item}">
          <mat-icon>more_vert</mat-icon>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
