import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProcurementTracker } from 'models/procurement/procurement-tracker';
import { HttpClient } from '@angular/common/http';
import { environment } from '#environments/environment';

@Injectable()
export class ProcurementService {
    constructor(private http: HttpClient) { }

    public import(): Observable<ProcurementTracker> {
      return this.http.get<ProcurementTracker>(`${environment.apiEndpoint}/procurement-info/import`);
    }
}
