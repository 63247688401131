import { Component, OnInit, Inject } from '@angular/core';
import { Dictionary } from 'models';
import { Page } from '_interfaces/page';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Role } from 'enums';

export interface AreaEditArgs {
  area: Dictionary;
}

@Component({
  selector: 'area-edit-dialog',
  templateUrl: './area-edit-dialog.component.html',
  styleUrls: ['./area-edit-dialog.component.scss']
})
export class AreaEditDialogComponent implements OnInit, Page {
  // page
  pageTitle = 'Edit area';
  pageDescription = 'edit data below to update area';

  // other properties
  area: Dictionary;
  isValid = false;
  Role = Role;

  constructor(
    public dialogRef: MatDialogRef<AreaEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AreaEditArgs) {
  }

  ngOnInit() {
    this.area = {
      ...this.data.area
    };
  }

  onSaveClick(): void {
    if (this.isValid) {
      this.dialogRef.close(this.area);
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }
}
