<form #vendorListForm="ngForm">
  <mat-toolbar class="page-toolbar" *ngIf="canShowprojectTypes">
      <span class="project-count">
          {{projectTypes && projectTypes.length}} Project Types
      </span>
      <span class="fill-remaining-space"></span>

      <button
          *appUserRoleUp="[ Role.Admin ]"
          class="btn btn-primary margin-left-md"
          [routerLink]="['/project-type-create']">
          Add new Project Type
      </button>
  </mat-toolbar>

  <div *ngIf="canShowprojectTypes; else noVendorsTemplate">
      <mat-divider></mat-divider>

      <app-search-panel
          (searchChanged)="onSearchChanged($event)">
      </app-search-panel>

      <app-project-type-table
          [data]="projectTypes"
          [columnsToDisplay]="projectTypesColumnsToDisplay"
          [filter]="filter"
          (collectionChanged)="onAreaCollectionChanged($event)">
      </app-project-type-table>
  </div>
</form>

<ng-template #noVendorsTemplate>
  <app-empty-list-holder
      [description]="'No project type added yet.'">
      <button
          *appUserRoleUp="[ Role.Admin ]"
          class="btn btn-primary"
          [routerLink]="'/project-type-create'">
          Add new Project Type
      </button>
  </app-empty-list-holder>
</ng-template>
