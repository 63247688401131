<div class="col">
    <mat-table
        *ngIf="!!dataSource.filteredData.length; else noMatchesTemplate"
        cdkDropList
        matSort
        (cdkDropListDropped)="drop($event)"
        [dataSource]="dataSource"
        [trackBy]="ID"
        class="mat-table-container">
        <ng-container
            matColumnDef="{{column}}"
            *ngFor="let column of allColumns">

            <ng-container *ngIf="canSort" [ngSwitch]="column">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <ng-container *ngIf="showCheckAll">
                        <mat-checkbox *ngSwitchCase="'_checked'"
                            (change)="onCheckedAll($event)"
                            (click)="$event.stopPropagation()">
                        </mat-checkbox>
                    </ng-container>

                    <span *ngSwitchDefault>
                        {{getColumnTitle(column)}}
                    </span>
                </mat-header-cell>
            </ng-container>

            <mat-header-cell *matHeaderCellDef [ngSwitch]="column">
                <ng-container *ngIf="showCheckAll">
                    <mat-checkbox *ngSwitchCase="'_checked'"
                        (change)="onCheckedAll($event)"
                        (click)="$event.stopPropagation()">
                    </mat-checkbox>
                </ng-container>

                <span *ngSwitchDefault>
                    {{getColumnTitle(column)}}
                </span>
            </mat-header-cell>

            <mat-cell class="mat-cell-value" *matCellDef="let item">
                <ng-container
                    [ngTemplateOutlet]="columnContent"
                    [ngTemplateOutletContext]="{item: item, column: column}">
                </ng-container>
            </mat-cell>

        </ng-container>

        <mat-header-row *matHeaderRowDef="allColumns; sticky: true"></mat-header-row>
        <mat-row
            cdkDragLockAxis="y"
            cdkDrag
            [cdkDragDisabled]="dragDropDisabled"
            *matRowDef="let row; columns: allColumns;"
            [ngClass]="{ 'selected-row': selectedItem && row.ID === selectedItem.ID }"
            class="element-row"
            (click)="onRowClick(row)">
        </mat-row>
    </mat-table>
</div>

<mat-paginator
    [ngStyle]="{display: showPadination ? 'block' : 'none'}"
    [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons>
</mat-paginator>

<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item *ngIf="canLock(item)"
            (click)="onLockClick(item['ID'])">
            <mat-icon>link</mat-icon>
            Lock
        </button>
        <button mat-menu-item *ngIf="canUnlock(item)"
            (click)="onLockClick(item['ID'], false)">
            <mat-icon>link_off</mat-icon>
            Unlock
        </button>
        <button mat-menu-item
            *ngIf="canSpare(item)"
            (click)="onSpareClick(item['ID'])">
            <mat-icon>remove_circle_outline</mat-icon>
            Make spare
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item
            (click)="onEditEquipment(item)">
            <mat-icon>edit</mat-icon>
            Edit
        </button>
        <button mat-menu-item
            *ngIf="canDelete(item)"
            (click)="onDeleteClick(item['ID'])">
            <mat-icon>delete_forever</mat-icon>
            Delete
        </button>
    </ng-template>
</mat-menu>

<ng-template #noMatchesTemplate>
    <app-empty-list-holder
        [icon]="'search'"
        [description]="'No matches'">
    </app-empty-list-holder>
</ng-template>

<ng-template #columnContent let-item="item" let-column="column">
    <ng-container [ngSwitch]="column">
        <span *ngSwitchCase="'selectEquipment'">
            <form [formGroup]="equipmentFormGroup">
                <mat-checkbox
                    (change)="onCheckedEq($event)"
                    [value]="item"
                    (click)="$event.stopPropagation()">
                </mat-checkbox>
            </form>
        </span>
        <ng-container *ngSwitchCase="'_checked'">
            <mat-checkbox
                [(ngModel)]="item[column]"
                (change)="(onChecked($event))"
                (click)="$event.stopPropagation()"
                [disabled]="!canCheck(item)">
            </mat-checkbox>
        </ng-container>

        <ng-container *ngSwitchCase="'ProjectName'">
            <a *appUserRoleUp="[ Role.Admin ]"
                (click)="onViewProject(item)">
                {{ item[column] }}
            </a>

            <a *appUserRoleUp="[ Role.User ]"
                (click)="onViewProject(item)">
                {{ item[column] }}
            </a>

            <span *appUserRoleUp="[ Role.Vendor ]">
                {{ item[column] }}
            </span>

            <span *ngIf="!item[column]">
                <mat-icon class="flipped small-icon">flare</mat-icon>
                Spare
            </span>
        </ng-container>

        <ng-container *ngSwitchCase="'PurchaseOrderNumber'">
            <a *appUserRoleUp="[ Role.Admin ]"
                (click)="onEditPO(item)">
                {{ item[column] }}
            </a>

            <a *appUserRoleUp="[ Role.User ]"
                (click)="onViewPO(item)">
                {{ item[column] }}
            </a>

            <span *appUserRoleUp="[ Role.Vendor ]">
                {{ item[column] }}
            </span>
        </ng-container>

        <ng-container *ngIf="editDialogPO">
          <ng-container *ngSwitchCase="'PurchaseOrderLineNumber'">
            <input matInput
                    [(ngModel)]="item[column]"
                    (ngModelChange)="poLineChange()"
                    maxlength="50"
                    placeholder="enter line number">
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'Comment'">
            <app-equipment-comment
                *ngIf="item.ID > 0"
                [equipment]="item"
                [columnsToDisplay]="columnsToDisplayInComments">
            </app-equipment-comment>
        </ng-container>

        <ng-container *ngSwitchCase="'_info'">
            <app-equipment-hint
                *ngIf="item.ID > 0"
                [equipment]="item"
                [columnsToDisplay]="columnsToDisplayInHints">
            </app-equipment-hint>
        </ng-container>

        <span *ngSwitchCase="'EquipmentType'">
            <span *appUserRoleUp="[ Role.Admin ]">
                <span *ngIf="canEdit">
                    <a (click)="onEditEquipment(item)">
                        {{ item[column] }}
                    </a>
                </span>

                <span *ngIf="!canEdit">
                    {{ item[column] }}
                </span>
            </span>

            <span *appUserRoleUp="[ Role.User ]">
                <span *ngIf="canEdit">
                    <a (click)="onViewEquipment(item)">
                        {{ item[column] }}
                    </a>
                </span>

                <span *ngIf="!canEdit">
                    {{ item[column] }}
                </span>
            </span>

            <span *appUserRoleUp="[ Role.Vendor ]">
                {{ item[column] }}
            </span>
        </span>

        <span *ngSwitchCase="'IsNonStandard'"
            class="cell-align-center"
            matTooltip="Is non standard">
            <mat-icon *ngIf="item[column]">play_for_work</mat-icon>
        </span>

        <span *ngSwitchCase="'Locked'"
            matTooltip="Locked">
            <mat-icon *ngIf="item[column]">link</mat-icon>
        </span>

        <span *ngSwitchCase="'IsAribaAFEMismatch'">
            {{ item[column] == null ? '?' : (item.IsAribaAFEMismatch ? '≠' : '=') }}
        </span>

        <span *ngSwitchCase="'Installed'"
            matTooltip="Installed">
            <mat-icon *ngIf="item[column]">business</mat-icon>
        </span>

        <span *ngSwitchCase="'AribaCost'">
            {{ item[column] ? (item[column] | currency) : 'N/A' }}
        </span>

        <span *ngSwitchCase="'DeliveryStatus'">
            <ng-container [ngSwitch]="item[column]">
                <mat-icon *ngSwitchCase="'Ordered'" class="status-ordered">lens</mat-icon>
                <mat-icon *ngSwitchCase="'Ready to ship'" class="status-ready-to-ship">lens</mat-icon>
                <mat-icon *ngSwitchCase="'Storage'" class="status-storage">lens</mat-icon>
                <mat-icon *ngSwitchCase="'Shipped'" class="status-shipped">lens</mat-icon>
                <mat-icon *ngSwitchCase="'Not ordered'" class="status-not-ordered">lens</mat-icon>
                {{ item[column] }}
            </ng-container>
        </span>

        <ng-container *appUserRoleUp="[ Role.Admin ]">
            <ng-container *ngIf="item?.ID > 0">
                <button *ngSwitchCase="'Menu'"
                    mat-button
                    class="menu-button pull-left"
                    (click)="$event.preventDefault()"
                    [matMenuTriggerFor]="menu"
                    [matMenuTriggerData]="{item: item}">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </ng-container>
        </ng-container>

        <span *ngSwitchDefault>
            {{
                helper.isDate(item[column])
                ? (item[column] | dateFormatPipe)
                : item[column]
            }}
        </span>
    </ng-container>
</ng-template>
