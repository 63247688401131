<div class="col"
     *ngIf="canShowHistoryRecords; else noHistoryRecordsTemplate">
    <mat-table
        [dataSource]="dataSource"
        class="mat-table-container">

        <ng-container
            matColumnDef="{{column}}"
            *ngFor="let column of activeColumns">

            <mat-header-cell *matHeaderCellDef>
                {{getColumnTitle(column)}}
            </mat-header-cell>

            <mat-cell class="mat-cell-value" *matCellDef="let item">
                <ng-container
                    [ngTemplateOutlet]="columnContent"
                    [ngTemplateOutletContext]="{item: item, column: column}">
                </ng-container>
            </mat-cell>

        </ng-container>

        <mat-header-row *matHeaderRowDef="activeColumns; sticky: true"></mat-header-row>
        <mat-row
            class="element-row"
            *matRowDef="let row; columns: activeColumns;">
        </mat-row>
    </mat-table>
</div>

<ng-template #noHistoryRecordsTemplate>
    <app-empty-list-holder
        [description]="'No history records added yet.'">
    </app-empty-list-holder>
</ng-template>

<ng-template #columnContent let-item="item" let-column="column">
    <ng-container [ngSwitch]="column">
        <span *ngSwitchDefault>
            {{
              (item[column] | dateFormatPipe)
            }}
        </span>
    </ng-container>
</ng-template>
