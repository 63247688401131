import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { Filter } from 'models';
import { Dictionary } from 'models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ToasterService } from '#services/shared';
import { MatDialog } from '@angular/material/dialog';
import { Role } from 'enums';
import { ProjectTypeEditDialogComponent , ProjectTypeEditArgs } from '../project-type-edit-dialog/project-type-edit-dialog.component'
import { ProjectTypeService } from '#services/api/project-type.service';

@Component({
  selector: 'app-project-type-table',
  templateUrl: './project-type-table.component.html',
  styleUrls: ['./project-type-table.component.scss']
})
export class ProjectTypeTableComponent implements OnInit {

  @Input()
  set filter(value: Filter) {
    this._filter = value;

    this.applyFilter(value);
  }
  get filter() {
    return this._filter;
  }

  @Input()
  set data(value: Dictionary[]) {
    this.dataSource.data = value;
  }

  @Input() columnsToDisplay: Map<string, string>;
  @Output() collectionChanged = new EventEmitter();

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  _filter: Filter = <Filter>{};
  dataSource = new MatTableDataSource<Dictionary>();

  Role = Role;

  constructor(
    public dialog: MatDialog,
    private ProjectTypeService: ProjectTypeService,
    private toaster: ToasterService
  ) { }

  ngOnInit() {
    setTimeout(() => this.dataSource.paginator = this.paginator);
  }

  get allColumns(): string[] {
    return Array.from(this.columnsToDisplay.keys());
  }

  get canShowTable() {
    const hasSearchedItems = this.filter.search
      ? this.dataSource.filteredData.length
      : true;

    const hasFilterParamsItems = this.filter.paramsCount
      ? this.dataSource.data.length
      : true;

    return hasSearchedItems && hasFilterParamsItems;
  }

  getColumnTitle(column: string): string {
    return this.columnsToDisplay.get(column);
  }

  applyFilter(filter: Filter) {
    this.dataSource.filter = filter.search;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  reloadSource(projectTypes: Dictionary[]) {
    this.data = projectTypes;
    this.dataSource.data = this.data;
  }

  async onEditProjectType(projectType: Dictionary) {
    const args: ProjectTypeEditArgs = {
      projectType
    };
    const dialogRef = this.dialog.open(ProjectTypeEditDialogComponent, {
      width: '50%',
      maxHeight: '80%',
      data: args
    });

    dialogRef.afterClosed().subscribe(async (result: Dictionary) => {
      if (result) {
        const res = await this.ProjectTypeService.update(result).toPromise();
        this.collectionChanged.emit({ value: res });
        this.toaster.showSuccess('Success...', 'Project Type has been updated.');
      }
    });
  }
}
