import { Injectable } from '@angular/core';

@Injectable()
export class Utils {
    public static guid(): string {
        return Utils.s4() + Utils.s4() + '-' +
            Utils.s4() + '-' +
            Utils.s4() + '-' +
            Utils.s4() + '-' +
            Utils.s4() + Utils.s4() + Utils.s4();
    }

    private static s4(): string {
        const crypto = window.crypto;
        let randomValue = new Uint32Array(10);
        return Math.floor((1 + crypto.getRandomValues(randomValue)[0]) * 0x10000)
            .toString(16)
            .substring(1);
    }
}
