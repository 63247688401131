import { Component } from '@angular/core';
import { Location } from '@angular/common';

import { Page } from '_interfaces/page';
import { Equipment } from 'models';
import { EquipmentService } from '#services/api/equipment.service';
import { ToasterService } from '#services/shared';

@Component({
  selector: 'app-equipment-create',
  templateUrl: './equipment-create.component.html',
  styleUrls: ['./equipment-create.component.scss']
})
export class EquipmentCreateComponent implements Page {
  // page
  pageTitle = 'New equipment';
  pageDescription = 'fill data below to create new equipment';

  // other properties
  equipment: Equipment = new Equipment();
  isValid = false;

  constructor(
    private equipmentService: EquipmentService,
    private location: Location,
    private toaster: ToasterService
     ) {
  }

  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }

  async onSubmit() {
    await this.equipmentService.create(this.equipment).toPromise().then(async (values) => {
     
      if (this.equipment.AssignedDrawing && this.equipment.AssignedDrawing.length > 0) {
       
        const drawingIds: number[] = this.equipment.AssignedDrawing.map(i => i.ID);

        await this.equipmentService.assignDrawing(values.ID, drawingIds).toPromise();
      }

    });


    this.location.back();
    this.toaster.showSuccess('Success...', 'Equipment has been added.');
  }

  onCancel() {
    this.location.back();
  }
}
