import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { EquipmentType } from 'models';
import { EquipmentTypeService } from '#services/api/equipment-type.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-equipment-type-dropdown-multiple',
  templateUrl: './equipment-type-dropdown-multiple.component.html',
  styleUrls: ['./equipment-type-dropdown-multiple.component.scss']
})
export class EquipmentTypeDropdownMultipleComponent implements OnInit {
  @ViewChild('etSelect')
  etSelect;

  @Input() selectedEquipmentTypeIds: Number[];
  defaultEquipmentTypes: EquipmentType[];
  @Input() appearance;
  @Output() closed = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<number[]>();

  // collections
  types: EquipmentType[];

  etControl = new FormControl();

  constructor(
    private equipmentTypeService: EquipmentTypeService
  ) { }

  ngOnInit() {
    if (this.selectedEquipmentTypeIds) {
      this.load().then((value) =>
        this.defaultEquipmentTypes = this.types.filter((item, i) => this.selectedEquipmentTypeIds.includes(item.ID))
      );

    }
  }

  async onLoad() {
    if (!this.types) {
      this.load();
    }
    setTimeout(() => this.etSelect.open());
  }

  async load() {
   
      const types = await this.equipmentTypeService.getAll().toPromise();

      const sorted = types.sort((a, b) => a.Name.localeCompare(b.Name));

    this.types = sorted.map(i =>
      <EquipmentType>{
        ID: i.ID,
        Name: `${i.Name} / ${i.OrderType}`,
        OrderType: i.OrderType
      }
    );
  }

  onChange(event) {
    const ids = event.value.map(i => i.ID);

    this.changed.emit(ids);
  }

  onClose(event) {
    this.closed.emit(true);
  }
}
