import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Page } from '_interfaces/page';
import 'rxjs/add/operator/filter';

@Component({
  selector: 'app-data-manager',
  templateUrl: './data-manager.component.html',
  styleUrls: ['./data-manager.component.scss']
})
export class DataManagerComponent implements Page, OnInit {
  // page
  pageTitle = 'Data manager';
  pageDescription = 'keep data updated';
  tabIndex = 0;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams
      .filter(params => params.tab)
      .subscribe(params => {
        this.tabIndex = params.tab;
      }
    );
  }
}
