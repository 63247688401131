<form #userForm="ngForm" class="col">
    <div class="row">
        <app-user-dropdown class="fx-50"
            [label]="'User principal name'"
            [required]="true"
            [readonly]="!userEditable"
            [defaultValue]="defaultUser"
            (changed)="onUserChanged($event)">
        </app-user-dropdown>

        <app-role-dropdown class="fx-50"
            [parentFormControl]="userForm.control"
            [label]="'User role'"
            [placeholder]="'user role'"
            [required]="true"
            [defaultValue]="defaultRole"
            (changed)="onRoleChanged($event)">
        </app-role-dropdown>
    </div>

    <div class="row" *ngIf="isFabricatorRequired">
        <app-vendor-dropdown class="fx-50"
            [parentFormControl]="userForm.control"
            [label]="'User fabricator'"
            [placeholder]="'user fabricator'"
            [required]="true"
            [defaultValue]="defaultFabricator"
            (changed)="onFabricatorChanged($event)">
        </app-vendor-dropdown>
    </div>
</form>