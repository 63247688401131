import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSizePipe',
})
export class FileSizePipe implements PipeTransform {
    transform(bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

        if (bytes === 0) {
            return '0 Byte';
        }

        const i = parseInt(
            Math.floor(
                Math.log(bytes) / Math.log(1024)
            ).toString(),
            10);

        return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    }
}
