import { BaseModel } from './baseModel';

export class PoGap extends BaseModel {
    PurchaseOrderNumber: string;
    Title: string;
    Description: string;
    Vendor: string;
    RequestorName: string;
    RequestorEmail: string;
    Ignored: boolean;
    SubmitDate?: Date = null;

    IgnoredAt?: Date;
    UnignoredAt?: Date;
    CreatedAt?: Date;
}
