import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Equipment, MilestoneHistory } from 'models';
import { MilestoneHistoryService } from '#services/api/milestone-history.service';
import { CommonHelper } from '_extentions/common-helper';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-equipment-milestone-history',
  templateUrl: './equipment-milestone-history.component.html',
  styleUrls: ['./equipment-milestone-history.component.scss']
})
export class EquipmentMilestoneHistoryComponent implements OnInit, OnDestroy  {
  @Input() equipment: Equipment;

  private destroy$ = new Subject();
  milestonehistoryRecords: MilestoneHistory[];
  displayedColumns: Map<string, string> = new Map([
    ['Date', 'Report Date'],
    ['OrderLongLeadMaterial', 'Order Long Lead Material'],
    ['BeginShopFabrication', 'Begin Shop Fabrication'],
    ['CompletePipingFabrication', 'Complete Piping Fabrication'],
    ['CompleteAssembly', 'Updated Being Reported Out of Date'],
    ['InstallMajorComponentsOnSkid', 'Install Major Components On Skid'],
    ['HydroTest', 'Hydro Test'],
    ['CompleteAssembly', 'CompleteAssembly'],
    ['FactoryAcceptanceTest', 'Factory Acceptance Test'],
    ['CoatingInspection', 'Coating Inspection'],
    ['FinalizeDrawings', 'Finalize Drawings'],
    ['ReadytoShip', 'Ready to Ship'],
    ['CompleteInternalsandProfiling', 'Complete Internals and Profiling']
  ]);

  activeColumns: string[];
  datasourceMilestone: [];
  dataSource: MatTableDataSource<any>;

  constructor(
    public helper: CommonHelper,
   private milestonehistoryService: MilestoneHistoryService
  ) { }

  ngOnInit() {
    this.initHistory();
  }

  initHistory() {


    const equipmentId =
      this.equipment && this.equipment.ID;
    if (!equipmentId) {
      return;
    }

    this.milestonehistoryService.getMilestonerHistory(equipmentId)
    .pipe(take(1))
    .subscribe(data => {

      this.milestonehistoryRecords = data;
      const filledColumns =  this.milestonehistoryRecords.map(this.getTruthyKeys);

      this.activeColumns = new Array<string>();
      filledColumns.forEach(a => this.activeColumns.push(...a));

      this.activeColumns = Array.from(new Set(this.activeColumns));
      this.activeColumns = this.getSortedColumns(this.activeColumns, this.displayedColumns);

      const formattedData = this.getFormattedData();
      this.dataSource = new MatTableDataSource(formattedData);

    });

  }

  get canShowHistoryRecords() {
    return this.milestonehistoryRecords && this.milestonehistoryRecords.length;
  }

  get allColumns(): string[] {
    return Array.from(this.displayedColumns.keys());
  }

  getColumnTitle(column: string): string {
    return this.displayedColumns.get(column);
  }

  ngOnDestroy(): void {
    this.destroy$.next();  // trigger the unsubscribe
    this.destroy$.complete(); // finalize & clean up the subject stream
  }


  getTruthyKeys(obj: any): Array<string> {
    return Object.keys(obj).filter(key => obj[key] !== null);
  }

  getFormattedData(): Array<any> {
    const datatable = [];

    let obj = {};
    let insertobj = false;
    this.milestonehistoryRecords.forEach((element) => {
      this.displayedColumns.forEach((value: string, key: string) => {
        if (element[key] !== null &&  element[key] !== undefined) {
          obj[key] =  element[key];
          insertobj = true;
        }
    });

    if (insertobj) {
      datatable.push(obj);
    }

      insertobj = false;
    obj = {};

    });

    return datatable;
  }

  getSortedColumns (items: Array<string>, sorting: Map<string, string>): Array<string> {
      const result = [];
      sorting.forEach(function(key, value) {
        let found = false;
        items = items.filter(function(item) {
            if (!found && item === value) {
                result.push(item);
                found = true;
                return false;
            } else {
                return true;
            }
        });
      });
      return result;
  }


}
