import { Injectable } from '@angular/core';
import * as deepEqual from 'deep-equal';

@Injectable()
export class CommonHelper {
    static deepCopy<T>(obj: T) {
        if (!obj) {
            return null;
        }

        return JSON.parse(JSON.stringify(obj), this.dateTimeReviver) as T;
    }

    static dateTimeReviver(key, value) {
        let a;
        if (typeof value === 'string') {
            a = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/.exec(value);
            if (a) {
                return new Date(value);
            }
        }
        return value;
    }

    static isString(obj: Object) {
        return typeof (obj) === 'string' || obj instanceof String;
    }

    static objectsAreEqual(x, y) {
        if (!x || !y) {
            return false;
        }

        return deepEqual(x, y);
    }

    static arraysAreEqual(arr1, arr2) {
        if (!arr1 || !arr2) {
            return false;
        }

        if (arr1.length !== arr2.length) {
            return false;
        }

        const objMap = {};

        arr1.forEach((e1) => arr2.forEach((e2) => {
            if (e1 === e2) {
                objMap[e1] = objMap[e1] + 1 || 1;
            }
        }
        ));

        return Object.keys(objMap).map(e => Number(e));
    }

    public isDate(obj: Object) {
        return obj instanceof Date;
    }
}

