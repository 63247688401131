import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DictionaryService } from '#services/api';
import { Dictionaries } from 'enums/dictionary';
import { FormControl } from '@angular/forms';
import { Dictionary } from 'models';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vendor-dropdown-multiple',
  templateUrl: './vendor-dropdown-multiple.component.html',
  styleUrls: ['./vendor-dropdown-multiple.component.scss']
})
export class VendorDropdownMultipleComponent implements OnInit {
  @ViewChild('vendorsSelect')
  vendorsSelect;

  @Output() closed = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<number[]>();

  // collections
  vendors: Dictionary[];

  vendorsControl = new FormControl();

  vendorsFilterControl = new FormControl();


  /* list of vendors filtered by search keyword */
  public filteredVendorsMulti: ReplaySubject<Dictionary[]>
  = new ReplaySubject<Dictionary[]>(1);

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

  constructor(
    private dictionaryService: DictionaryService
  ) { }

  ngOnInit() {

      // listen for search field value changes
      this.vendorsFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterVendorsMulti();
      });

  }

  async onLoad() {
    if (!this.vendors) {
      const fetched = await this.dictionaryService.getAll(`${Dictionaries.fabricators}/dictionary`).toPromise();

      const sorted = fetched.sort((a, b) => a.Name.localeCompare(b.Name));

      this.vendors = sorted;

       // load inital vendors list
       this.filteredVendorsMulti.next(this.vendors.slice());

    }

    setTimeout(() => this.vendorsSelect.open(), 0);
  }

  onChange(event) {
    const ids = event.value.map(i => i.ID);

    this.changed.emit(ids);
  }

  onClose(event) {
    this.closed.emit(true);
  }

  protected filterVendorsMulti() {
    if (!this.vendors) {
      return;
    }

      let search = this.vendorsFilterControl.value;
      if (!search) {
        this.filteredVendorsMulti.next(this.vendors.slice());
        return;
      } else {
        search = search.toLowerCase();
      }

      // filter vendors
      this.filteredVendorsMulti.next(
        this.vendors.filter (vendor => vendor.Name.toLowerCase().indexOf(search) > -1)
        );
    }
}
