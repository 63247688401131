import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ToasterService } from '#services/shared';

@Component({
    selector: 'app-drag-drop',
    templateUrl: './drag-drop.component.html',
    styleUrls: ['./drag-drop.component.scss']
})

export class DragDropComponent {
    @Input() format: string;
    @Output() fileListChanged = new EventEmitter<File>();
    file: File;

    constructor(private toaster: ToasterService) {
    }

    uploadFile(event) {
        // take only first valid file
        let hasInvalidExtentions = false;

        for (let index = 0; index < event.length; index++) {
            const element = event[index];
            const extention = this.getExtention(element.name).toLowerCase();

            if (this.format.toLowerCase() !== extention) {
                hasInvalidExtentions = true;
                continue;
            }

            this.file = element;
            break;
        }

        if (hasInvalidExtentions) {
            this.toaster.showWarn('Wrong file format', `Only ${this.format} extention is allowed.`);
        }

        this.fileListChanged.emit(this.file);
    }

    getExtention(filename: string) {
        return '.' + filename.split('.').pop();
    }
}
