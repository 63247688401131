import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Page } from '_interfaces/page';
import { Project } from 'models';
import { ProjectService } from '#services/api/project.service';
import { ToasterService } from '#services/shared';

@Component({
    selector: 'app-project-create',
    templateUrl: './project-create.component.html',
    styleUrls: ['./project-create.component.scss']
})

export class ProjectCreateComponent implements Page, OnInit {
    // page
    pageTitle = 'New project';
    pageDescription = 'fill data below to create new project';

    // other properties
    newProject: Project = new Project();
    isValid = false;

    constructor(
        private projectService: ProjectService,
        private location: Location,
        private toaster: ToasterService) {
    }

    ngOnInit(): void {
        this.newProject.StatusID = 1;
        this.newProject.Status = 'Draft';
        this.newProject.ReviewStatusID = 1;
        this.newProject.ReviewStatus = 'Not Reviewed';
        this.newProject.ReviewDate = new Date();
    }

    isValidChanged(isValid: boolean) {
        this.isValid = isValid;
    }

    async onSubmit() {
        await this.projectService.create(this.newProject).toPromise();
        this.location.back();
        this.toaster.showSuccess('Success...', 'Project has been added.');
    }

    onCancel() {
        this.location.back();
    }
}
