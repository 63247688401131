import { Component, OnInit, Inject } from '@angular/core';
import { Equipment, PurchaseOrder } from 'models/';
import { Page } from '_interfaces/page';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonHelper } from '_extentions/common-helper';

export interface EquipmentViewArgs {
  equipment: Equipment;
  purchaseOrder: PurchaseOrder;
}

@Component({
  selector: 'app-equipment-view-dialog',
  templateUrl: './equipment-view-dialog.component.html',
  styleUrls: ['./equipment-view-dialog.component.scss']
})
export class EquipmentViewDialogComponent implements OnInit, Page {
  // page
  pageTitle = 'View equipment';
  pageDescription = 'view data below to view equipment';

  // other properties
  equipment: Equipment;
  purchaseOrder: PurchaseOrder;

  constructor(
      public dialogRef: MatDialogRef<EquipmentViewDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: EquipmentViewArgs) {
  }

  ngOnInit() {
      this.equipment = CommonHelper.deepCopy(this.data.equipment);
      this.purchaseOrder = CommonHelper.deepCopy(this.data.purchaseOrder);
  }

  onCloseClick(): void {
      this.dialogRef.close();
  }
}
