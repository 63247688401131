import { Injectable } from '@angular/core';

@Injectable()
export class DateHelper {
    static getCurrentDate(): Date {
        return new Date();
    }

    static getCurrentMonth(): number {
        const today = this.getCurrentDate();
        return today.getMonth();
    }

    static getLastDateOfTheMonth(month: number): Date {
        const today = this.getCurrentDate();
        return new Date(today.getFullYear(), month, 0);
    }

    static getMiddleDateOfTheMonth(): Date {
        const middleDay = 15;
        const today = this.getCurrentDate();
        return new Date(today.getFullYear(), today.getMonth(), middleDay);
    }

    static equal(date1: Date, date2: Date): boolean {
        const d1 = new Date(date1);
        const d2 = new Date(date2);

        return d1.getTime() === d2.getTime();
    }
}
