import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { EquipmentType } from 'models';
import { Filter } from 'models';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EquipmentTypeEditArgs } from '../equipment-type-edit-dialog/equipment-type-edit-dialog.component';
import { EquipmentTypeEditDialogComponent } from '../equipment-type-edit-dialog/equipment-type-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentTypeService } from '#services/api/equipment-type.service';
import { ToasterService } from '#services/shared';
import { Role } from 'enums';

@Component({
  selector: 'app-equipment-type-table',
  templateUrl: './equipment-type-table.component.html',
  styleUrls: ['./equipment-type-table.component.scss']
})
export class EquipmentTypeTableComponent implements OnInit {
  @Input()
  set filter(value: Filter) {
    this._filter = value;

    this.applyFilter(value);
  }
  get filter() {
    return this._filter;
  }

  @Input()
  set data(value: EquipmentType[]) {
    this.dataSource.data = value;
  }

  @Input() columnsToDisplay: Map<string, string>;
  @Output() collectionChanged = new EventEmitter();

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  _filter: Filter = <Filter>{};
  dataSource = new MatTableDataSource<EquipmentType>();

  Role = Role;

  constructor(
    public dialog: MatDialog,
    private equipmentTypeService: EquipmentTypeService,
    private toaster: ToasterService
  ) { }

  ngOnInit() {
    setTimeout(() => this.dataSource.paginator = this.paginator);
  }

  get allColumns(): string[] {
    return Array.from(this.columnsToDisplay.keys());
  }

  get canShowTable() {
    const hasSearchedItems = this.filter.search
      ? this.dataSource.filteredData.length
      : true;

    const hasFilterParamsItems = this.filter.paramsCount
      ? this.dataSource.data.length
      : true;

    return hasSearchedItems && hasFilterParamsItems;
  }

  getColumnTitle(column: string): string {
    return this.columnsToDisplay.get(column);
  }

  applyFilter(filter: Filter) {
    this.dataSource.filter = filter.search;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  reloadSource(equipmentTypes: EquipmentType[]) {
    this.data = equipmentTypes;
    this.dataSource.data = this.data;
  }

  async onEditEquipmentType(equipmentType: EquipmentType) {
    const args: EquipmentTypeEditArgs = {
      equipmentType: equipmentType
    };
    const dialogRef = this.dialog.open(EquipmentTypeEditDialogComponent, {
      width: '50%',
      maxHeight: '80%',
      data: args
    });

    dialogRef.afterClosed().subscribe(async (result: EquipmentType) => {
      if (result) {
        const res = await this.equipmentTypeService.update(result).toPromise();
        this.collectionChanged.emit({ value: res });
        this.toaster.showSuccess('Success...', 'Equipment type has been updated.');
      }
    });
  }
}
