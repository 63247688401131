import { environment } from '#environments/environment';
import { FileUploaderService } from '#services/api/file-uploader.service';
import { ToasterService } from '#services/shared';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetFilterBase } from 'models';
import { Drawing } from 'models/drawing';
import { Page } from '_interfaces/page';


@Component({
  selector: 'app-drawing-create',
  templateUrl: './drawing-create.component.html',
  styleUrls: ['./drawing-create.component.scss']
})
export class DrawingCreateComponent extends GetFilterBase implements OnInit ,Page{
// page
pageTitle = 'Add new Drawing';
pageDescription = 'edit data below to create drawing';

// other properties
drawing: Drawing = new Drawing();
isValid = false;
file: File;

  constructor(
    private router: Router,
    private toaster: ToasterService,
    private fileUploader: FileUploaderService
  ) { super(); }

  ngOnInit() {    
    this.drawing.ID=0;
  }

  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }

  fileChanged(file: File) {
    this.file = file;
  }

  async onSubmit() {
    await this.upload() as Drawing;

    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 6 } });
    this.toaster.showSuccess('Success...', 'Drawing has been added.');
  }

  onCancel() {
    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 6 } });
  }

  onCleanData() {
    this.file = null;
  }

  async upload() {
    const formData = new FormData();
    formData.append('file', this.file);
    this.updateHttpFilters();

    return await this.fileUploader.upload(
        `${environment.apiEndpoint}/drawing`,
        formData, this.httpParams).toPromise();
    }

    updateHttpFilters() {
      super.clearFilterParams();
      if (this.drawing.EquipmentTypeID) {
        this.drawing.EquipmentTypeID.forEach((id:number) =>{
          this.httpParams=  this.httpParams.append('EquipmentTypeID',JSON.stringify(id));
        })
    }

      
      if (this.drawing.Name) {
        super.addFilterParam('name', this.drawing.Name);
      }
    }
}
