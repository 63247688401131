<div>
	<app-page-header
		[title]="pageTitle"
		[description]="pageDescription">
  </app-page-header>

	<div mat-dialog-content>
    <mat-tab-group>
      <mat-tab label="General">
        <app-po-template
          [purchaseOrder]="purchaseOrder"
                (isValidChanged)="isValidChanged($event)">
        </app-po-template>
      </mat-tab>
      <mat-tab label="Equipments">
        <app-equipment-table
            *ngIf="equipment && equipment.length; else noEquipmentsTemplate"
            [columnsToDisplay]="equipmentColumnsToDisplay"
            [data]="equipment"
            [editDialogPO]="true"
            [canEdit]="true"
            (collectionChanged)="onEquipmentCollectionChanged($event)"
            style="height: 340px; overflow: auto">
        </app-equipment-table>
      </mat-tab>
    </mat-tab-group>
	</div>

	<mat-divider></mat-divider>

	<div mat-dialog-actions class="row pull-right">
		<button
			class="btn btn-default"
			(click)="onCancelClick()">
			Cancel
		</button>
		<button
			*appUserRoleUp="[ Role.Admin ]"
			[disabled]="!isValid"
			class="btn btn-primary margin-left-md"
			[mat-dialog-close]="{po: purchaseOrder, eq: equipment}">
			Apply changes
		</button>
	</div>
</div>

<ng-template #noEquipmentsTemplate>
  No equipments added
</ng-template>
