import { NgModule } from '@angular/core';
import { SharedModule } from '#services/shared/shared.module';
import { UserService } from '#services/api/user.service';
import { ProjectService } from './project.service';
import { EquipmentService } from './equipment.service';
import { DictionaryService } from './dictionary.service';
import { PurchaseOrderService } from './purchase-order.service';
import { FileUploaderService } from './file-uploader.service';
import { EquipmentTypeService } from './equipment-type.service';
import { VendorService } from './vendor.service';
import { UserDirectoryService } from './user-directory.service';
import { ProcurementService } from './procurement.service';
import { PoGapService } from './po-gap.service';
import { MilestoneHistoryService } from './milestone-history.service';
import { MilestoneTypeService } from './milestone-type.service';
import { AreaService } from './area.service';
import { ProjectTypeService } from './project-type.service';
import { DashboardService } from './dashboard.service';
import { LinkedPOInfoService } from '#services/api/linkedPOInfo.service';
import { DrawingService } from './drawing.service';

@NgModule({
  declarations: [
  ],
  imports: [
    SharedModule
  ],
  providers: [
    UserService,
    UserDirectoryService,
    ProjectService,
    PurchaseOrderService,
    EquipmentService,
    DictionaryService,
    FileUploaderService,
    EquipmentTypeService,
    VendorService,
    ProcurementService,
    PoGapService,
    MilestoneHistoryService,
    MilestoneTypeService,
    AreaService,
    ProjectTypeService,
    DashboardService,
    LinkedPOInfoService,
    DrawingService
  ]
})

export class ApiModule { }
