<div class="page-with-shadow">
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>
    
    <form (ngSubmit)="onSubmit()" #projectForm="ngForm" class="col" (keyup.enter)="onSubmit()">
        <app-user-template
            [user]="newUser"
            (isValidChanged)="isValidChanged($event)">
        </app-user-template>
    
        <div class="row pull-right">
            <button type="button" 
                class="btn btn-default" 
                (click)="onCancel()">
                Cancel
            </button>
            <button type="submit" 
                class="btn btn-primary margin-left-md" 
                [disabled]="!isValid">
                Create new user
            </button>
        </div>
    </form>
</div>