<mat-accordion>
    <mat-expansion-panel [disabled]="true" [expanded]="filterPanelIsOpen" class="mat-elevation-z0">

        <!-- Tools panel -->
        <mat-expansion-panel-header style="padding: 10px 0 0 0">
            <mat-panel-title style="margin: 0">
                <mat-toolbar class="filter-toolbar">
                    <button mat-button aria-label="filter" (click)="onToogleFilterPanel()">
                        <mat-icon class="filter-list-icon">
                            filter_list
                        </mat-icon>
                        {{filterPanelIsOpen ? 'Hide' : 'Show'}} filters
                        <span class="filters-active-title">{{ filtersActiveTitle }}</span>
                    </button>

                    <span class="fill-remaining-space"></span>

                    <mat-form-field class="fx-20 filter-panel">
                        <input 
                            matInput
                            [(ngModel)]="search"
                            (keydown)="$event.stopPropagation()" 
                            placeholder="Search">
                        <button     
                            *ngIf="!!search"
                            matSuffix 
                            mat-icon-button
                            matTooltip="Clear filter"
                            aria-label="Clear" 
                            (click)="onClearClick()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </mat-toolbar>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- Filters -->
        <div class="row">
            <ng-content></ng-content>
        </div>
    </mat-expansion-panel>
</mat-accordion>