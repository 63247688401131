import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { CRUDService } from '#services/api/crud.service';

@Injectable()
export class LinkedPOInfoService extends CRUDService<any> {
    constructor(http: HttpClient) {
        super(http, `${environment.apiEndpoint}`);
    }

    public removeLinkedPOInfo(equipmentId: number): Observable<any[]> {
        return this.http.delete<any>(`${this.url}/linkedpoinfo/${equipmentId}`);
    }

    public addNew(poLineItem: any): Observable<any[]> {
        return this.http.post<any>(`${this.url}/linkedpoinfo`, poLineItem);
    }      
}
