<nav class="navbar navbar-subsite centered" role="navigation">
    <!--navbar-fixed-top-->
    <div class="container-fluid">

        <div class="collapse navbar-collapse js-navbar-collapse centered row font-gotham   navbar-mobile dropdown-menu *dropdown-menu-mobile"
            id="navbar-brand-centered-subsite">
            <div class="mobile-nav-container vertical-align flex-center centered">
                <!--width-1280-->
                <ul class="nav navbar-nav primary" 
                    *appUserRoleUp="[ Role.Admin, Role.Vendor, Role.User ]">
                    
                    <li class="col-sm-4 dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue"
                        *appUserRoleUp="[ Role.Admin, Role.User ]"
                        routerLink="/projects">
                        <a>Projects</a>
                    </li>

                    <li class="col-sm-4 dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue"
                        *appUserRoleUp="[ Role.Admin, Role.Vendor, Role.User ]"
                        routerLink="/equipments">
                        <a>Equipment</a>
                    </li>

                    <li class="col-sm-4 dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue"
                        *appUserRoleUp="[ Role.Admin, Role.User ]"
                        routerLink="/purchase-orders">
                        <a>Purchase orders</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>