<div class="page-with-shadow">
    <app-page-header [title]="pageTitle" [description]="pageDescription">
    </app-page-header>

    <mat-tab-group>
        <mat-tab label="PO Gaps">
            <app-po-gaps-list
                [ignored]="false"
            ></app-po-gaps-list>
        </mat-tab>
        <mat-tab label="Ignored POs">
            <app-po-gaps-list
                [ignored]="true"
            ></app-po-gaps-list>
        </mat-tab>
    </mat-tab-group>
</div>