import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss']
})
export class SearchPanelComponent {
  @Output() searchChanged = new EventEmitter();

  _value: string;
  set value(value: string) {
    this._value = value;
    this.searchChanged.emit({ value: value });
  }
  get value() {
    return this._value;
  }
}
