import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '#environments/environment';
import { DashboardSummary } from 'models/dashboard';

@Injectable()
export class DashboardService {
    constructor(private http: HttpClient) { }

    public get(): Observable<DashboardSummary> {
      return this.http.get<DashboardSummary>(`${environment.apiEndpoint}/home`);
    }
}
