import { Component, OnInit } from '@angular/core';
import { VendorService } from '#services/api/vendor.service';
import { ToasterService } from '#services/shared';
import { Dictionary } from 'models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vendor-create',
  templateUrl: './vendor-create.component.html',
  styleUrls: ['./vendor-create.component.scss']
})
export class VendorCreateComponent implements OnInit {
  // page
  pageTitle = 'Add new vendor';
  pageDescription = 'edit data below to create vendor';

  // other properties
  newVendor: Dictionary = new Dictionary(0, null);
  isValid = false;

  constructor(
    private vendorService: VendorService,
    private router: Router,
    private toaster: ToasterService
  ) { }

  ngOnInit() {
  }

  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }

  async onSubmit() {
    await this.vendorService.create(this.newVendor).toPromise();

    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 2 } });
    this.toaster.showSuccess('Success...', 'Vendor has been added.');
  }

  onCancel() {
    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 2 } });
  }
}
