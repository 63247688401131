<form #purchaseOrderForm="ngForm" class="col">
    <div class="row">
        <mat-form-field appearance="outline" class="fx-50">
            <mat-label>Purchase order number</mat-label>
			<input matInput 
                [(ngModel)]="purchaseOrder.PurchaseOrderNumber" 
				[ngModelOptions]="{standalone: true}" 
				[required]="!!!purchaseOrder.RequestNumber" 
				maxlength="20"
				placeholder="enter PO number">
		</mat-form-field>

		<mat-form-field appearance="outline" class="fx-50">
            <mat-label>Request number</mat-label>
            <input matInput 
                [(ngModel)]="purchaseOrder.RequestNumber" 
				[ngModelOptions]="{standalone: true}" 
				[required]="!!!purchaseOrder.PurchaseOrderNumber"
				maxlength="10"
                placeholder="enter request number">
		</mat-form-field>
	</div>
	
	<div class="row">
		<app-autocomplete-dropdown class="fx-50"
			[parentFormControl]="purchaseOrderForm.control"
			[options]="fabricators"
			[label]="'Vendor'"
			[displayProperty]="'Name'"
			[defaultValue]="fabricatorDefValue"  
			[placeholder]="'select vendor'"
			[required]="true"
			(selectedChanged)='onFabricatorChanged($event)'
			(clicked)="onLoadFabricators()">
		</app-autocomplete-dropdown>

		<mat-form-field appearance="outline" class="fx-50">
			<mat-label>Submit date</mat-label>
			<input matInput #sdRef 
				[(ngModel)]="purchaseOrder.SubmitDate" 
				[ngModelOptions]="{standalone: true}" 
				[matDatepicker]="submitDatePicker" 
				[required]="!!purchaseOrder.PurchaseOrderNumber" 
				placeholder="supplier FAT date">
			<mat-datepicker-toggle matSuffix
				[for]="submitDatePicker">
			</mat-datepicker-toggle>
			<mat-datepicker #submitDatePicker></mat-datepicker>
		</mat-form-field>
	</div>
</form>
