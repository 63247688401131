import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { LinkedPOInfoService } from '#services/api';
import { ToasterService } from '#services/shared';
import { EquipmentService } from '#services/api/equipment.service';
// import { Equipment } from 'models';
@Component({
  selector: 'app-associated-purchase-order-line-items',
  templateUrl: './associated-purchase-order-line-items.component.html',
  styleUrls: ['./associated-purchase-order-line-items.component.scss']
})
export class associatedPurchaseOrderLineItemsComponent implements OnInit {

  @Input() columnsToDisplay: any;
  @Input() equipmentId: number;
  @Output() poDeleted: EventEmitter<number> = new EventEmitter<number>();
  @Output() poItemAdded: EventEmitter<number> = new EventEmitter<number>();
  linkNewPOItem = false;
  dataSource: any;
  equipment: any;

  constructor(
    private linkedPOInfoService: LinkedPOInfoService,
    private toaster: ToasterService,
    private equipmentService: EquipmentService
  ) {}

  ngOnInit(){
    this.getPOLinkedItems();
  }

  get allColumns(): string[] {
    return Array.from(this.columnsToDisplay.keys());
  }

  get canShowPOLinkedItems() {
    return this.dataSource && this.dataSource.length;
  }

  getColumnTitle(column: string): string {
    return this.columnsToDisplay.get(column);
  }

  async getPOLinkedItems() {
    if(this.equipmentId != undefined)
    {
      this.equipment = await this.equipmentService.getEquipmentById(this.equipmentId).toPromise();
      this.dataSource = this.equipment.LinkedPOsInformation;
      const sum = this.dataSource.reduce(function (total, currentValue) {
        return total + currentValue.UnitPrice;
      }, 0);
      this.poItemAdded.emit(sum);
    }
  }

  async onDeletePO(item: any) {
    await this.linkedPOInfoService.removeLinkedPOInfo(item.ID).toPromise();
    this.poDeleted.emit(item.UnitPrice);
    this.toaster.showSuccess('Success...', 'Linked item has been removed.');
    this.getPOLinkedItems();
  }

  hidePOItemForm(event) {
    this.linkNewPOItem = event;
  }

  onLinkNewPOLinkItem(event) {
    this.getPOLinkedItems();
    this.hidePOItemForm(false);
  }
}
