<mat-tab-group>
    <!-- General tab -->
    <mat-tab label="General">
        <form #generalTabForm="ngForm" class="col">
            <div class="row">
                <mat-form-field appearance="outline" class="fx-25">
                    <mat-label>Serial number</mat-label>
                    <input matInput
                        [(ngModel)]="equipment.SerialNumber"
                        [ngModelOptions]="{standalone: true}"
                        maxlength="50"
                        placeholder="enter serial number">
                </mat-form-field>
                <app-autocomplete-dropdown                  
                    [parentFormControl]="generalTabForm.control"                                  
                    [options]="types"
                    [label]="'Equipment type'"
                    [displayWith]="displayEquipmentType"
                    [filterByFields]="'Name|OrderType'"
                    [defaultValue]="equipmentTypeDefValue"
                    [placeholder]="'select equipment type'"
                    [required]="true"                    
                    (selectedChanged)='onEquipmentTypeChanged($event)'
                    (clicked)="onLoadEquipmentTypes()"
                    class="fx-33">
                </app-autocomplete-dropdown>
                <span class="fill-remaining-space"></span>
                <app-autocomplete-dropdown
                    [parentFormControl]="generalTabForm.control"
                    [options]="deliveryStatuses"
                    [label]="'Delivery status'"
                    [displayProperty]="'Name'"
                    [defaultValue]="deliveryStatus"
                    [placeholder]="'select delivery status'"
                    [required]="true"
                    (selectedChanged)='onDeleveryStatusChanged($event)'
                    (clicked)="onLoadDeliveryStatuses()"
                    class="fx-25">
                </app-autocomplete-dropdown>
            </div>

            <mat-divider></mat-divider>
            <div class="row">
                <app-autocomplete-dropdown
                    [readonly]="!canEditProject"
                    [parentFormControl]="generalTabForm.control"
                    [options]="projects"
                    [label]="'Project name'"
                    [displayProperty]="'Name'"
                    [placeholder]="'select project name'"
                    [defaultValue]="projectDefValue"
                    (selectedChanged)='onProjectChanged($event)'
                    (clicked)="onLoadProjects()"
                    class="fx-50">
                </app-autocomplete-dropdown>
                <span class="fill-remaining-space"></span>
                <div class="row">
                    <mat-slide-toggle
                        [disabled]="!equipment.ProjectID"
                        [(ngModel)]="equipment.Locked"
                        [ngModelOptions]="{standalone: true}">
                        {{equipment.Locked ? 'Locked' : 'Unlocked'}}
                    </mat-slide-toggle>
                    <mat-checkbox
                        [(ngModel)]="equipment.IsNonStandard"
                        [ngModelOptions]="{standalone: true}"
                        class="margin-left-md">
                        Is non standard
                    </mat-checkbox>
                </div>
            </div>
            <mat-divider></mat-divider>

            <div class="row">
                <div class="col fx-50">
                    <span class="input-group-name">General information</span>
                    <div class="row">
                        <mat-form-field appearance="outline" class="fx-33">
                            <mat-label>Purchase order line</mat-label>
                            <input matInput
                                [(ngModel)]="equipment.PurchaseOrderLineNumber"
                                [ngModelOptions]="{standalone: true}"
                                type="text"
                                maxlength="3"
                                placeholder="PO line number">
                        </mat-form-field>
                        <app-autocomplete-dropdown
                            [readonly]="!canEditPurchaseOrder"
                            [parentFormControl]="generalTabForm.control"                           
                            [options]="purchaseOrders"
                            [label]="'Request № / Purchase order'"
                            [displayProperty]="'Name'"
                            [placeholder]="'select purchase order'"
                            [defaultValue]="purchaseOrderDefValue"
                            (selectedChanged)='onPOChanged($event)'
                            (clicked)="onLoadPOs()"
                            class="fill-remaining-space">
                        </app-autocomplete-dropdown>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="outline" class="fx-100">
                            <mat-label>Material description</mat-label>
                            <textarea matInput
                                [(ngModel)]="equipment.MaterialDescription"
                                [ngModelOptions]="{standalone: true}"
                                placeholder="enter material description"
                                maxlength="100"
                                rows="3">
                            </textarea>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="outline" class="fx-100">
                            <mat-label>Vendor comments</mat-label>
                            <textarea matInput
                                [(ngModel)]="equipment.VendorComment"
                                [ngModelOptions]="{standalone: true}"
                                placeholder="vendor comments"
                                readonly
                                rows="3">
                            </textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col fx-50">
                    <span class="input-group-name">Equipment dates</span>
                    <div class="row">
                        <mat-form-field appearance="outline" class="fx-33">
                            <mat-label>Projected FAT date</mat-label>
                            <input matInput #pfatRef
                                [(ngModel)]="equipment.ProjectedFATDate"
                                [ngModelOptions]="{standalone: true}"
                                [matDatepicker]="projectedFATDate"
                                placeholder="projected FAT date"
                                matTooltip="Latest FAT date">
                            <mat-datepicker-toggle matSuffix
                                [for]="projectedFATDate" >
                            </mat-datepicker-toggle>
                            <mat-datepicker #projectedFATDate></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="fx-33">
                            <mat-label>Supplier FAT date</mat-label>
                            <input matInput #sfatRef
                                [(ngModel)]="equipment.SupplierFATDate"
                                [ngModelOptions]="{standalone: true}"
                                [matDatepicker]="supplierFATDateDatePicker"
                                placeholder="supplier FAT date"
                                matTooltip="Original FAT date">
                            <mat-datepicker-toggle matSuffix
                                [for]="supplierFATDateDatePicker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #supplierFATDateDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="fx-33">
                            <mat-label>Actual Ship date</mat-label>
                            <input matInput #asdRef
                                [(ngModel)]="equipment.ActualShipDate"
                                [ngModelOptions]="{standalone: true}"
                                [matDatepicker]="actualShipDatePicker"
                                readonly
                                placeholder="actual ship date"
                                matTooltip="Actual ship date">
                            <mat-datepicker-toggle matSuffix
                                disabled
                                [for]="actualShipDatePicker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #actualShipDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <span class="input-group-name">Project dates</span>
                    <div class="row">
                        <mat-form-field appearance="outline" class="fx-50">
                            <mat-label>Need By date</mat-label>
                            <input matInput #nbdRef
                                [(ngModel)]="equipment.NeedByDate"
                                [ngModelOptions]="{standalone: true}"
                                [matDatepicker]="needByDatePicker"
                                [required]="!!equipment.ProjectID"
                                placeholder="need by date"
                                matTooltip="Due date for fabrication">
                            <mat-datepicker-toggle matSuffix
                                [for]="needByDatePicker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #needByDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="fx-50">
                            <mat-label>Target Completion date</mat-label>
                            <input matInput #tcdRef
                                [(ngModel)]="equipment.TargetCompletionDate"
                                [ngModelOptions]="{standalone: true}"
                                [matDatepicker]="targetCompletionDatePicker"
                                [required]="!!equipment.ProjectID"
                                placeholder="target completion date"
                                matTooltip="Project drop dead date">
                            <mat-datepicker-toggle matSuffix
                                [for]="targetCompletionDatePicker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #targetCompletionDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="fx-100">
                            <mat-label>General comments</mat-label>
                            <textarea matInput
                                [(ngModel)]="equipment.Comment"
                                [ngModelOptions]="{standalone: true}"
                                maxlength="500"
                                rows="3"
                                placeholder="enter your general comments here">
                            </textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="row">
              <div class="col fx-50">
                <span class="input-group-name">Cost Transfer AFE</span>
                <mat-form-field appearance="outline" class="fx-40">
                  <mat-label>Cost Transfer AFE</mat-label>
                  <input matInput
                      [ngModel]="equipment.CostTransferAFE"
                      (ngModelChange)="equipment.CostTransferAFE=$event"
                      [ngModelOptions]="{standalone: true}"
                      type="text"
                      maxlength="35"
                      placeholder="Cost Transfer AFE">
              </mat-form-field>
                </div>
            </div>
        </form>
    </mat-tab>

    <!-- Procurement tab -->
    <mat-tab label="Procurement">
        <form #procurementForm="ngForm" class="col">
            <span class="input-group-name">Ariba information</span>
            <div class="row">
                <mat-form-field appearance="outline" class="fx-50">
                    <mat-label>Ariba AFE</mat-label>
                    <input matInput
                        [ngModel]="equipment.AribaAFE"
                        [ngModelOptions]="{standalone: true}"
                        readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="fx-50">
                    <mat-label>Ariba Cost</mat-label>
                    <input matInput
                        [(ngModel)]="equipment.AribaCost"
                        [ngModelOptions]="{standalone: true}"
                        readonly>
                    <span matSuffix>$</span>
                </mat-form-field>
            </div>

            <div class="row">
                <mat-form-field appearance="outline" class="fx-50">
                    <mat-label>Ariba Status</mat-label>
                    <input matInput
                        [(ngModel)]="equipment.AribaStatus"
                        [ngModelOptions]="{standalone: true}"
                        readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="fx-50">
                    <mat-label>Total Ariba Cost</mat-label>
                    <input matInput
                        [(ngModel)]="totalAribaCost"
                        [ngModelOptions]="{standalone: true}"
                        readonly>
                    <span matSuffix>$</span>
                </mat-form-field>
            </div>
        </form>
        <div class="row">
            <span class="input-group-name">Associated purchase order line items</span>
        </div>
        <div class="row">
            <app-associated-purchase-order-line-items
            [columnsToDisplay]="columnsToDisplay"
            [equipmentId]="equipment.ID"
            (poDeleted)="recalculateTotalAriba($event)"
            (poItemAdded)="itemAdded($event)"
            >
            </app-associated-purchase-order-line-items>
        </div>
    </mat-tab>

   <mat-tab label="History">
        <app-equipment-history
            [equipment]="equipment">
        </app-equipment-history>
    </mat-tab>
     <mat-tab label="Milestone">
         <app-equipment-milestone-history
          [equipment]="equipment">
        </app-equipment-milestone-history>
    </mat-tab>

    <mat-tab label="Drawing">
      <app-equipment-drawing
      [equipment]="equipment">
      </app-equipment-drawing>
   </mat-tab>
</mat-tab-group>


