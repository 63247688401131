import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToasterService } from '#services/shared';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private toaster: ToasterService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let msg = '';

                    if (error.error instanceof ErrorEvent) {
                        msg = `Error: ${error.error.message}`;
                    } else if (error.error && error.error.ModelState) {
                        const errors = Object.keys(error.error.ModelState).map(key => {
                            return error.error.ModelState[key];
                        });

                        errors.forEach(er => {
                            this.toaster.showError('Error', er);
                        });

                        return throwError(msg);
                    } else if (error.error && error.error.ExceptionMessage) {
                        msg = error.error.ExceptionMessage;
                    } else if (error.error && error.error.Message) {
                        msg = error.error.Message;
                    } else {
                        msg = error.message;
                    }

                    this.toaster.showError('Error', msg);
                    return throwError(msg);
                })
            );
    }
}
