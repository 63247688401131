<form #poLineItem="ngForm" class="col">
    <span class="input-group-name">Associate new PO line item</span>
    <div class="row">
        <mat-form-field appearance="outline" class="fx-50">
            <mat-label>Purchase Order Number</mat-label>
            <input matInput
                [(ngModel)]="purchaseOrderNumber"
                [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <mat-form-field appearance="outline" class="fx-50">
            <mat-label>Purchase Order Line Number</mat-label>
            <input matInput
                [(ngModel)]="purchaseOrderLineNumber"
                [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>
    <div class="row pull-right">
        <button 
            class="btn btn-default"
            (click)="onCancelClick()">
            Cancel
        </button>
        <button 
            [disabled]="!isValid"
            class="btn btn-primary margin-left-md"
            (click)="onSaveClick()">
            Save 
        </button>
    </div>    
</form>