import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '#environments/environment';
import { PoGap } from 'models';

@Injectable()
export class PoGapService {
    constructor(private http: HttpClient) { }

    public getAll(ignored: boolean): Observable<PoGap[]> {
      const options = {
        params: {
          ignored: ignored.toString()
        }
      };
      return this.http.get<PoGap[]>(`${environment.apiEndpoint}/purchase-order-gaps`, options);
    }

    public sync(): Observable<PoGap[]> {
      return this.http.get<PoGap[]>(`${environment.apiEndpoint}/purchase-order-gaps/synchronize`);
    }

    public ignore(id: number): Observable<PoGap> {
      return this.http.put<PoGap>(`${environment.apiEndpoint}/purchase-order-gaps/${id}/ignore`, null);
    }

    public unignore(id: number): Observable<PoGap> {
      return this.http.put<PoGap>(`${environment.apiEndpoint}/purchase-order-gaps/${id}/unignore`, null);
    }
}
