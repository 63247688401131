import { Component, OnInit, Inject } from '@angular/core';
import { EquipmentType } from 'models';
import { Page } from '_interfaces/page';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Role } from 'enums';

export interface EquipmentTypeEditArgs {
  equipmentType: EquipmentType;
}

@Component({
  selector: 'app-equipment-type-edit-dialog',
  templateUrl: './equipment-type-edit-dialog.component.html',
  styleUrls: ['./equipment-type-edit-dialog.component.scss']
})
export class EquipmentTypeEditDialogComponent implements OnInit, Page {
  // page
  pageTitle = 'Edit equipment type';
  pageDescription = 'edit data below to update equipment type';

  // other properties
  equipmentType: EquipmentType;
  isValid = false;
  Role = Role;

  constructor(
    public dialogRef: MatDialogRef<EquipmentTypeEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EquipmentTypeEditArgs) {
  }

  ngOnInit() {
    this.equipmentType = {
      ...this.data.equipmentType
    };
  }

  onSaveClick(): void {
    if (this.isValid) {
      this.dialogRef.close(this.equipmentType);
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }
}
