import { Directive, HostListener, ComponentRef, Input, ElementRef, OnInit } from '@angular/core';
import { OverlayRef, Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { TooltipComponent } from '#components/shared/tooltip/tooltip.component';
import { ToolTipMessage } from 'models';

@Directive({ selector: '[appTooltip]' })
export class AppTooltipDirective implements OnInit {
    @Input('appTooltip') hints: string;
    private overlayRef: OverlayRef;

    constructor(
        private overlayPositionBuilder: OverlayPositionBuilder,
        private elementRef: ElementRef,
        private overlay: Overlay) { }

    ngOnInit() {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([{
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
            }]);

        this.overlayRef = this.overlay.create({ positionStrategy });
    }

    @HostListener('mouseenter')
    show() {
        const tooltipPortal = new ComponentPortal(TooltipComponent);
        const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(tooltipPortal);

        if (this.hints) {
            tooltipRef.instance.hints = <ToolTipMessage[]>JSON.parse(this.hints);
        }
    }

    @HostListener('mouseout')
    hide() {
        this.overlayRef.detach();
    }
}
