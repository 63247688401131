<form #userListForm="ngForm">
    <mat-toolbar class="page-toolbar" *ngIf="canShowPoGaps">
        <span class="project-count">
            {{ poGaps && poGaps.length}} purchase orders
        </span>
        <span class="fill-remaining-space"></span>

        <button
            class="btn btn-default"
            color="primary"
            (click)="refresh()">
            <mat-icon class="sync-icon">refresh</mat-icon>
            Refresh
        </button>

        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-default margin-left-md"
            color="primary"
            (click)="onSubmit()">
            Synchronize
            <mat-icon class="sync-icon">sync</mat-icon>
        </button>
    </mat-toolbar>

    <div *ngIf="canShowPoGaps; else noPoGapsTemplate">
        <mat-divider></mat-divider>

        <app-search-panel 
            (searchChanged)="onSearchChanged($event)">
        </app-search-panel>

        <app-po-gaps-table
            [data]="poGaps"
            [columnsToDisplay]="columnsToDisplay"
            [filter]="filter"
            (itemChanged)="onItemChanged($event)">
        </app-po-gaps-table>
    </div>
</form>

<ng-template #noPoGapsTemplate>
    <form (ngSubmit)="onSubmit()" class="col">
        <app-empty-list-holder 
            [description]="'To synchronize procurement data use the button below'"
            [icon]="'sync'">
            <button 
                *appUserRoleUp="[ Role.Admin ]"
                type="submit"
                class="btn btn-primary">
                Synchronize data
            </button>
        </app-empty-list-holder>
    </form>
</ng-template>