import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CRUDService } from '#services/api/crud.service';
import { environment } from '#environments/environment';
import { Dictionary } from 'models';

@Injectable()
export class AreaService extends CRUDService<Dictionary> {
    constructor(http: HttpClient) {
        super(http, `${environment.apiEndpoint}/area`);
    }
}
