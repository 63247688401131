import { Component, OnInit } from '@angular/core';
import { AreaService } from '#services/api/area.service';
import { Role } from 'enums';
// import { Dictionary } from 'models'};
import { Filter } from 'models';
import { take } from 'rxjs/operators';

@Component({
    selector: 'area-list',
    templateUrl: './area-list.component.html',
    styleUrls: ['./area-list.component.scss']
    // -----------------------------------------------------------------------------
    // It will help to increase performance for big collections
    // TODO need to be supported for data change methods (lock, edit, spare etc.)
    // Google it for details:
    // changeDetection: ChangeDetectionStrategy
    // -----------------------------------------------------------------------------
})

export class AreaListComponent implements OnInit {

    areas: any;
    Role = Role;

    filter: Filter = <Filter>{};

    public areaColumnsToDisplay: Map<string, string> = new Map([
        ['Name', 'Area'],
        ['Menu', '']
      ]);

    constructor(private AreaService: AreaService) {}

    ngOnInit() {
        this.initAreas();
    }

    get canShowAreas() {
        return this.areas && this.areas.length;
    }

    initAreas() {
        this.AreaService
        .getAll()
        .pipe(
          take(1)
        ).subscribe(data => {
          this.areas = data;
        });
    }

    onSearchChanged(event: { value: string }) {
        if (!event) {
          return;
        }

        this.filter = {
          search: event.value,
          paramsCount: 0
        };
      }

      onAreaCollectionChanged(event) {
        if (event) {
          this.initAreas();
        }
      }
}
