import { Component, OnInit } from '@angular/core';
import { DrawingService } from '#services/api/drawing.service';

import { Role } from 'enums';
import { Drawing } from 'models/drawing';
import { Filter } from 'models';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-drawing-list',
  templateUrl: './drawing-list.component.html',
  styleUrls: ['./drawing-list.component.scss']
})
export class DrawingListComponent implements OnInit {
  filter: Filter = <Filter>{};
  Role = Role;


  // collections
  drawings: Drawing[];

  public drawingColumnsToDisplay: Map<string, string> = new Map([
    ['Name', 'Drawing Name'],
    ['EquipmentTypes', 'Applicable Equipment Type'],
    ['DocumentName', 'Link to document'],
    ['IsDisabled', 'Status'],
    ['Menu', '']
  ]);

  constructor(
    private drawingService: DrawingService
  ) { }

  ngOnInit() {
    this.initDrawings();
  }

  initDrawings() {
    this.drawingService
      .getAll()
      .pipe(
        take(1)
      ).subscribe(data => {
        this.drawings = data;
      });
  }

  get canShowDrawings() {
    return this.drawings && this.drawings.length;
  }

  onDrawingCollectionChanged(event) {
    console.log('onDrawingCollectionChanged1', event);
    if (event) {
      console.log('onDrawingCollectionChanged2', event);
      this.initDrawings();
    }
  }

  onSearchChanged(event: { value: string }) {
    if (!event) {
      return;
    }

    this.filter = {
      search: event.value,
      paramsCount: 0
    };
  }
}
