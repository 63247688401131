import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Project } from 'models';
import { CRUDService } from '#services/api/crud.service';
import { Equipment } from 'models';
import { ReviewStatus } from 'enums/review-status';

@Injectable()
export class ProjectService extends CRUDService<Project> {
    constructor(http: HttpClient) {
        super(http, `${environment.apiEndpoint}/projects`);
    }

    public getEquipment(projectId: number): Observable<Equipment[]> {
        return this.http.get<Equipment[]>(`${this.url}/${projectId}/equipments`);
    }

    public getTemplates(): Observable<Project[]> {
        return this.getAll(null, new HttpParams().append('Template', true.toString()));
    }

    public addEquipment(projectId: number, equipmentId: number): Observable<Equipment[]> {
        return this.http.put<any>(`${this.url}/${projectId}/add-equipment/${equipmentId}`, null);
    }

    public addEquipments(projectId: number, templateId: number): Observable<any> {
        return this.http.put<any>(`${this.url}/${projectId}/add-equipments/${templateId}`, null);
    }

    public addCheckedEquipments(projectId: number, equiments: any): Observable<any> {
        return this.http.put<any>(`${this.url}/${projectId}/add-multiple-equipment`, equiments);
    }

    public changeStatus(projectId: number, statusId: number): Observable<any> {
        return this.http.put<any>(`${this.url}/${projectId}/change-status/${statusId}`, null);
    }

    public archive(id: number): Observable<any> {
        return this.http.put<any>(`${this.url}/${id}/archive`, null);
    }

    public changeReviewStatus(ids: number[], status: ReviewStatus) {
        return this.http.put<any>(`${this.url}/change-review-status/${status}`, ids);
    }

    public markAsTemplate(id: number): Observable<any> {
        return this.http.put<any>(`${this.url}/${id}/mark-as-template`, null);
    }

    public markAsNonTemplate(id: number): Observable<any> {
        return this.http.put<any>(`${this.url}/${id}/unmark-as-template`, null);
    }
}
