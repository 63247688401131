<form #outlookForm="ngForm" class="page-with-shadow">
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>
    
    <ng-container *appUserRoleUp="[ Role.Admin ]">
        <mat-toolbar class="page-toolbar" *ngIf="projects && projects.length">
            <span class="project-count">{{projects && projects.length}} projects</span>
            <span class="fill-remaining-space"></span>
            <button class="btn btn-default" color="primary" [routerLink]="['/tools/reshuffling']">
                Reshuffling
                <mat-icon class="shuffle-icon">shuffle</mat-icon>
            </button>
            <button class="btn btn-primary margin-left-md" [routerLink]="['/project-create']">
                Add new project
            </button>
        </mat-toolbar>
    </ng-container>

    <div *ngIf="canShowProjects; else noProjectsTemplate">
        <mat-divider></mat-divider>
        <app-filter-panel [paramsCount]="filtersCount" (searchChanged)="onUpdateFilters($event)">
            <div class="row fx-100">
                <mat-form-field class="fx-15">
                    <mat-label>Reviewed date (from)</mat-label>
                    <input matInput #fromRef 
                        [(ngModel)]="reviewedDateFrom"
                        [ngModelOptions]="{standalone: true}" 
                        [matDatepicker]="reviewedFromDatePicker"
                        placeholder="reviewed from"
                        (ngModelChange)="onFilterChanged()">
                    <mat-datepicker-toggle matSuffix 
                        [for]="reviewedFromDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #reviewedFromDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="fx-15">
                    <mat-label>Reviewed date (to)</mat-label>
                    <input matInput #nbdRef 
                        [(ngModel)]="reviewedDateTo" 
                        [ngModelOptions]="{standalone: true}"
                        [matDatepicker]="reviewedToDatePicker"
                        placeholder="reviewed to"
                        (ngModelChange)="onFilterChanged()">
                    <mat-datepicker-toggle matSuffix 
                        [for]="reviewedToDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #reviewedToDatePicker></mat-datepicker>
                </mat-form-field>

                <app-autocomplete-dropdown class="fx-15 margin-left-md"
                    [parentFormControl]="outlookForm.control" 
                    [options]="statuses"
                    [label]="'Review status'"
                    [displayProperty]="'Name'"
                    [placeholder]="'select review status'"
                    [defaultValue]="statusDefValue"
                    [appearance]="'outline'"
                    (selectedChanged)='onFilterStatusChanged($event)'
                    (clicked)="onLoadStatuses()">
                </app-autocomplete-dropdown>
                
                <app-autocomplete-dropdown class="fx-15"
                    [parentFormControl]="outlookForm.control" 
                    [options]="projectStatuses"
                    [label]="'Project status'"
                    [displayProperty]="'Name'"
                    [placeholder]="'select project status'"
                    [appearance]="'outline'"
                    (selectedChanged)='onFilterProjectStatusChanged($event)'
                    (clicked)="onLoadProjectStatuses()">
                </app-autocomplete-dropdown>

                <app-autocomplete-dropdown class="fx-15"
                    [parentFormControl]="outlookForm.control" 
                    [options]="types"
                    [label]="'Project type'"
                    [displayProperty]="'Name'"
                    [placeholder]="'select project type'"
                    [appearance]="'outline'"
                    (selectedChanged)='onFilterTypeChanged($event)'
                    (clicked)="onLoadTypes()">
                </app-autocomplete-dropdown>

                <app-user-dropdown class="fx-20 margin-left-md"
                    [appearance]="'outline'"
                    [label]="'Representative person'"
                    (changed)="onRepresentativePersonChanged($event)">
                </app-user-dropdown>
            </div>
        </app-filter-panel>
        <app-project-table
            [data]="projects"
            [columnsToDisplay]="projectColumnsToDisplay"
            [filter]="filter"
            [isOutlookReview]="true"
            (checkedItemsChanged)="checkedItemsChanged($event)">
        </app-project-table>
    </div>
</form>

<ng-template #noProjectsTemplate>
    <app-empty-list-holder
        [description]="'No projects added yet.'">
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary" 
            [routerLink]="'/project-create'">
            Add new project
        </button>
    </app-empty-list-holder>
</ng-template>