import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Equipment, PurchaseOrder } from 'models';
import { Page } from '_interfaces/page';
import { CommonHelper } from '_extentions/common-helper';
import { Role } from 'enums';

export interface EquipmentEditArgs {
    equipment: Equipment;
    purchaseOrder: PurchaseOrder;
}

@Component({
    selector: 'app-equipment-edit-dialog',
    templateUrl: './equipment-edit-dialog.component.html',
    styleUrls: ['./equipment-edit-dialog.component.scss']
})

export class EquipmentEditDialogComponent implements OnInit, Page {
    // page
    pageTitle = 'Edit equipment';
    pageDescription = 'edit data below to update equipment';

    // other properties
    equipment: Equipment;
    purchaseOrder: PurchaseOrder;
    isValid = false;
    Role = Role;

    constructor(
        public dialogRef: MatDialogRef<EquipmentEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EquipmentEditArgs) {
    }

    ngOnInit() {
        this.equipment = CommonHelper.deepCopy(this.data.equipment);
        this.purchaseOrder = CommonHelper.deepCopy(this.data.purchaseOrder);
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    isValidChanged(isValid: boolean) {
        this.isValid = isValid;
    }
}
