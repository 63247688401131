import { Component, OnInit, Input, EventEmitter, Output, DoCheck, ViewChild } from '@angular/core';
import { DictionaryService, ProjectService, PurchaseOrderService } from '#services/api/index';
import { Equipment, Project, Dictionary, PurchaseOrder } from 'models';
import { Dictionaries } from 'enums/dictionary';
import { EquipmentTypeService } from '#services/api/equipment-type.service';
import { EquipmentType } from 'models';
import { take } from 'rxjs/operators';
import { Page } from '_interfaces/page';
import { ConfirmationDialogComponent } from '#components/shared/confirmation-dialog/confirmation-dialog.component';
import { AutocompleteDropdownComponent } from '#components/shared/autocomplete-dropdown/autocomplete-dropdown.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-equipment-template',
    templateUrl: './equipment-template.component.html',
    styleUrls: ['./equipment-template.component.scss']
})

export class EquipmentTemplateComponent implements OnInit, DoCheck {
    @Input() equipment: Equipment;
    @Input() purchaseOrder: PurchaseOrder;
    @Input() canEditProject = true;
    @Input() canEditPurchaseOrder = true;
    @Output() isValidChanged = new EventEmitter();
    @ViewChild(AutocompleteDropdownComponent)
    equipmentTypeAutoComplete: AutocompleteDropdownComponent<EquipmentType>;
    
    _deliveryStatus: Dictionary;
    types: EquipmentType[];
    deliveryStatuses: Dictionary[];
    projects: Project[];
    purchaseOrders: Dictionary[];    
    
    columnsToDisplay: Map<string, string> = new Map([
        ['PurchaseOrderNumber', 'Purchase Order Number'],
        ['PurchaseOrderLineNumber', 'PO Line Item'],
        ['WBSElement', 'Ariba AFE'],
        ['UnitPrice', 'Ariba Cost'],
        ['Menu', '']
    ]);
    poLineItemsTotalCost = 0.0;
    totalAribaCost = 0.0;
   
    constructor(
        private dictionaryService: DictionaryService,
        private projectService: ProjectService,
        private purchaseOrderService: PurchaseOrderService,
        private equipmentTypeService: EquipmentTypeService,
        private dialog: MatDialog) {
    }

    async ngOnInit() {
        this.initDeliveryStatus();
        this.calculateTotalAribaCost();
    }

    ngDoCheck(): void {
        this.isValidChanged.emit(!this.isInvalid);
    }

    get deliveryStatus() {
        return this._deliveryStatus;
    }
    set deliveryStatus(value: Dictionary) {
        this._deliveryStatus = value;
    }

    get purchaseOrderDefValue(): Dictionary {
        if (!this.equipment.PurchaseOrderID) {
            return null;
        }

        const poRequestNum = this.purchaseOrder.RequestNumber || '';
        const poNum = this.purchaseOrder.PurchaseOrderNumber || '';

        return new Dictionary(
            this.equipment.PurchaseOrderID,
            `${poRequestNum} / ${poNum}`);
    }

  get equipmentTypeDefValue(): Dictionary {
        const {
            EquipmentTypeID,
            EquipmentType,
            OrderType
        } = this.equipment;

        return EquipmentTypeID
            ? {
                ID: EquipmentTypeID,
                Name: EquipmentType,
                OrderType: OrderType
            } as EquipmentType
            : null;
    }

    get projectDefValue(): Project {
        if (!this.equipment.ProjectID) {
            return null;
        }

        const project = new Project();
        project.ID = this.equipment.ProjectID;
        project.Name = this.equipment.ProjectName;
        project.NeedByDate = this.equipment.NeedByDate;
        project.TargetCompletionDate = this.equipment.TargetCompletionDate;
        return project;
    }

    get isInvalid(): boolean {
        if (!this.equipment) {
            return false;
        }

        const isDatesValid = !!this.equipment.ProjectID
            ? !!this.equipment.TargetCompletionDate && !!this.equipment.NeedByDate
            : true;

        return !isDatesValid ||
            !this.equipment.EquipmentTypeID ||
            !this.equipment.StatusDeliveryID;
    }

    async setPOStatus(hasPurchaseOrder: boolean) {
        await this.onLoadDeliveryStatuses();

        this.deliveryStatus = hasPurchaseOrder
            ? this.deliveryStatuses.find((i) => i.Name === 'Ordered')
            : this.deliveryStatuses.find((i) => i.Name === 'Not ordered');

        this.equipment.StatusDeliveryID = this.deliveryStatus.ID;
    }

    initDeliveryStatus() {
        if (this.equipment) {
            this.deliveryStatus = new Dictionary(
                this.equipment.StatusDeliveryID,
                this.equipment.DeliveryStatus
            );
        } else {
            this.deliveryStatus = this.deliveryStatuses.find(
                (i) => i.Name === 'Ordered');
        }
    }

    // display
    displayEquipmentType(equipmentType: EquipmentType) {
        return `${equipmentType.Name} / ${equipmentType.OrderType}`;
    }

    // events
    onEquipmentTypeChanged(event) {
        const value = event.value as EquipmentType;        

      if (value) {
        if (this.equipment.AssignedDrawing != null && this.equipment.AssignedDrawing.length > 0 && this.equipment.AssignedDrawing[0].EquipmentTypes[0].ID != value.ID) {
          this.confirmEquipmentTypeChange(value);
        }
        else {
          this.equipment.EquipmentTypeID = value.ID;
          this.equipment.EquipmentType = value.Name;
          this.equipment.OrderType = value.OrderType;
        }
      }
    }

    confirmEquipmentTypeChange(value:EquipmentType)
    {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            maxWidth: '450px',
            maxHeight: '250px',
            disableClose:true,
            data: <Page>{
              pageTitle: 'Confirmation',
              pageDescription: `Are you sure you want to change equipment type? If you continue, you will lose your assigned drawing.`
            }
          });
      
          dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
              this.equipment.AssignedDrawing = []; 
              this.equipment.EquipmentTypeID = value.ID;
              this.equipment.EquipmentType = value.Name;
              this.equipment.OrderType = value.OrderType;       
              return;
            }
              let prevVal = this.equipmentTypeDefValue;
              this.equipmentTypeAutoComplete.autocompleteControl.setValue(prevVal);           
            
          });         
    }

    onProjectChanged(event) {
        const project = event.value as Project;
        if (!project) {
            this.removeProject(this.equipment);
            return;
        }

        if (project.ID !== this.equipment.ProjectID) {
            this.updateProject(this.equipment, project);
            return;
        }
    }

    removeProject(equipment: Equipment) {
        equipment.ProjectID = null;
        equipment.ProjectName = null;
        equipment.Locked = false;
        equipment.NeedByDate = null;
        equipment.TargetCompletionDate = null;
    }

    updateProject(equipment: Equipment, project: Project) {
        equipment.ProjectID = project.ID;
        equipment.ProjectName = project.Name;
        equipment.Locked = true;
        equipment.NeedByDate = project.NeedByDate;
        equipment.TargetCompletionDate = project.TargetCompletionDate;
    }

    onDeleveryStatusChanged(event) {
        if (event.value) {
            this.equipment.StatusDeliveryID = (<Dictionary>event.value).ID;
        }
    }

    async onPOChanged(event) {
        const dValue = event.value as Dictionary;

        if (dValue) {
            this.purchaseOrder = await this.purchaseOrderService.get(dValue.ID).toPromise();
        } else {
            this.purchaseOrder = null;
        }

        if (!this.hasPoChanged(this.equipment, this.purchaseOrder)) {
            return;
        }

        this.equipment.PurchaseOrderID = this.purchaseOrder
            ? this.purchaseOrder.ID
            : null;

        this.equipment.PurchaseOrderNumber = this.purchaseOrder
            ? this.purchaseOrder.PurchaseOrderNumber
            : null;

        this.setPOStatus(!!dValue);
    }

    hasPoChanged(equipment: Equipment, purchaseOrder: PurchaseOrder): boolean {
        const { ID: PurchaseOrderId } = (purchaseOrder || new PurchaseOrder());

        return equipment.PurchaseOrderID !== PurchaseOrderId;
    }

    onLoadEquipmentTypes() {
        if (!this.types) {
            this.equipmentTypeService
                .getAll()
                .pipe(take(1))
                .subscribe(data => {
                    this.types = data;
                });
        }
    }

    async onLoadDeliveryStatuses() {
        if (!this.deliveryStatuses) {
            this.deliveryStatuses = await this.dictionaryService.getAll(
                `${Dictionaries.deliveryStatuses}/dictionary`).toPromise();
        }
    }

    async onLoadProjects() {
        if (!this.projects) {
            this.projects = await this.projectService.getAll().toPromise();
        }
    }

    async onLoadPOs() {
        if (!this.purchaseOrders) {
            this.purchaseOrders = await this.dictionaryService.getAll(
                `${Dictionaries.purchaseOrders}/dictionary`).toPromise();
        }
    }

    calculateTotalAribaCost() {
        if (this.equipment.LinkedPOsInformation != undefined)
        {
            if(this.equipment && this.equipment.LinkedPOsInformation.length != 0) {
                this.equipment.LinkedPOsInformation.forEach(res => {
                    this.poLineItemsTotalCost = this.poLineItemsTotalCost + res.UnitPrice;
                });
                const total = this.equipment.AribaCost + this.poLineItemsTotalCost;
                this.totalAribaCost = total;
            } else {
                this.totalAribaCost = this.equipment.AribaCost;
            }
        }

    }

    recalculateTotalAriba(event) {
      this.totalAribaCost -= event;
    }

    itemAdded(event) {
      this.totalAribaCost = this.equipment.AribaCost + event;
    }
}
