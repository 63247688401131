import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { CommonHelper } from '_extentions/common-helper';
import { Page } from '_interfaces/page';
import { ProjectService } from '#services/api/project.service';
import { Dictionary, User, Project } from 'models';
import { DictionaryService } from '#services/api';
import { Dictionaries } from 'enums/dictionary';
import { ProjectTableComponent } from '../project-table/project-table.component';
import { FormControl } from '@angular/forms';
import { AFE } from 'enums/afe';
import { GetFilterBase } from 'models';
import { Role } from 'enums';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss']
})

export class ProjectListComponent extends GetFilterBase implements OnInit, Page {
    @ViewChild(ProjectTableComponent)
    projectTable: ProjectTableComponent;

    @ViewChild('statusSelect')
    statusSelect;

    // page
    pageTitle = 'Projects';
    pageDescription = 'factory model facilities';

    // collections
    projects: Project[];
    statuses: Dictionary[];
    types: Dictionary[];

    // filters
    includeArchived: boolean;
    includeTemplate: boolean;
    containsAFE: AFE;
    needByDateFrom: Date;
    needByDateTo: Date;
    statusID: number;
    typeID: Dictionary;
    user: User;
    afes: Dictionary[] = [];

    // other
    Role = Role;

    public projectColumnsToDisplay: Map<string, string> = new Map([
        ['Number', 'Project #'],
        ['Name', 'Name'],
        ['Representative', 'Representative Person'],
        ['CDName', 'C&D Name'],
        ['Type', 'Type'],
        ['NeedByDate', 'Need by date'],
        ['Status', 'Status'],
        ['HasNonStandardEquipments', ''],
        ['Comment', ''],
        ['Archived', ''],
        ['EquipmentsNeed', 'Eq. Goal'],
        ['Menu', '']
    ]);

    statusControl = new FormControl();

    constructor(
        private router: Router,
        private projectService: ProjectService,
        public helper: CommonHelper,
        private dictionaryService: DictionaryService
    ) {
        super();
    }

    ngOnInit() {
        this.loadProjects();
        this.initAFE();
    }

    get canShowProjects() {
        return (this.projects && this.projects.length)
            || this.filter.paramsCount > 0;
    }

    async loadProjects() {
        this.updateHttpFilters();
        this.projects = await this.projectService.getAll(null, this.httpParams).toPromise();
    }

    initAFE() {
        for (const i in AFE) {
            if (typeof AFE[i] === 'number') {
                this.afes.push(new Dictionary(<any>AFE[i], i));
            }
        }
    }

    // events
    onCreateProject() {
        this.router.navigate(['/project-create/']);
    }

    onEditProject(id: number) {
        this.router.navigate(['/project-details/', { id: id }]);
    }

    async onLoadStatuses() {
        if (!this.statuses) {
            this.statuses = await this.dictionaryService.getAll(
                `${Dictionaries.projectStatuses}/dictionary`).toPromise();
        }

        setTimeout(() => {
            // force to open select list
            this.statusSelect.open();
        }, 0);
    }

    async onLoadTypes() {
        if (!this.types) {
            this.types = await this.dictionaryService.getAll(
                `${Dictionaries.projectTypes}/dictionary`).toPromise();
        }
    }

    // filters
    statusSelectClosed(event) {
        this.onFilterChanged();
    }

    statusSelectionChange(event) {
        const ids = event.value.map(i => i.ID);

        for (let i = 0; i < this.statuses.length; i++) {
            this.statuses[i]['_checked'] = ids.includes(this.statuses[i].ID);
        }
    }

    onFilterTypeChanged(event) {
        if (!event.value && !this.typeID) {
            return;
        }

        this.typeID = event.value
            ? event.value.ID
            : null;

        this.onFilterChanged();
    }

    onFilterAFEChanged(event) {
        if (!event.value && !this.containsAFE) {
            return;
        }

        this.containsAFE = event.value
            ? event.value.ID
            : null;

        this.onFilterChanged();
    }

    onRepresentativePersonChanged(event) {
        if (!event.value && !this.user) {
            return;
        }

        this.user = event.value as User;

        this.onFilterChanged();
    }

    async onFilterChanged() {
        await this.loadProjects();

        super.onUpdateFilters(this.filter.search);
        this.projectTable.reloadSource(this.projects);
    }

    updateHttpFilters() {
        super.clearFilterParams();

        if (!!this.containsAFE) {
            super.addFilterParam('containsAFE', (this.containsAFE === AFE.Included).toString());
        }

        if (this.includeArchived) {
            super.addFilterParam('includeArchived', true.toString());
        }

        if (this.includeTemplate) {
            super.addFilterParam('template', true.toString());
        }

        if (this.user) {
            super.addFilterParam('representativeEmail', this.user.Email);
        }

        if (this.typeID) {
            super.addFilterParam('type', this.typeID.toString());
        }

        if (this.needByDateFrom) {
            super.addFilterParam('fromNeedByDate', this.needByDateFrom.toLocaleDateString());
        }

        if (this.needByDateTo) {
            super.addFilterParam('toNeedByDate', this.needByDateTo.toLocaleDateString());
        }

        if (this.statuses) {
            const statusIDs = this.statuses
                .filter(s => s['_checked'])
                .map(i => i.ID);

            statusIDs.forEach(id => {
                super.addFilterParam('statuses', id.toString());
            });
        }
    }
}
