import { IFilter } from './filter-base';
import { Filter } from './filter';

export abstract class PostFilterBase implements IFilter {
    filter: Filter = <Filter>{};
    private store: {} = {};

    onUpdateFilters(value: string) {
        this.filter = <Filter>{
            search: value,
            paramsCount: Object.keys(this.store).length
        };
    }

    addFilterParam(key: string, value: any) {
        this.store[key] = value;
    }

    getBodyParams() {
        return this.store;
    }

    clearFilterParams() {
        this.store = {};
    }
}
