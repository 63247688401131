import { BaseModel } from "./baseModel";
import { Dictionary } from "./dictionary";

export class Drawing extends BaseModel {  
   
    Name: string = null;
    DocumentName: string = null;

    DisplayEquipmentType: string = null;
    IsDisabled: boolean = false;
    EquipmentTypes: Dictionary[] = []; 
    EquipmentTypeID:number[] = [];
}
