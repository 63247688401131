<div>
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>

    <div mat-dialog-content>
        <app-equipment-template
            [equipment]="equipment"
            [purchaseOrder]="purchaseOrder">
        </app-equipment-template>
    </div>

    <mat-divider></mat-divider>

    <div mat-dialog-actions class="row pull-right">
        <button 
            class="btn btn-default"
            (click)="onCloseClick()">
            Close
        </button>
    </div>
</div>