import { Component, OnInit, Input } from '@angular/core';
import { Equipment } from 'models/';
import { DateFormatPipe } from 'pipes/date-format.pipes';
import { ToolTipMessage } from 'models';

@Component({
  selector: 'app-equipment-hint',
  templateUrl: './equipment-hint.component.html',
  styleUrls: ['./equipment-hint.component.scss']
})
export class EquipmentHintComponent implements OnInit {
  @Input() equipment: Equipment;
  @Input() columnsToDisplay: Map<string, string>;

  defaultColumnsToDisplay: Map<string, string> = new Map([
    ['SerialNumber', ''],
    ['PurchaseOrderNumber', ''],
    ['PurchaseOrderLineNumber', ''],
    ['ProjectedFATDate', ''],
    ['NeedByDate', ''],
    ['ActualShipDate', ''],
    ['IsNonStandard', ''],
    ['AribaStatus', '']
  ]);

  hints: string;

  ngOnInit() {
    if (this.equipment) {
      this.hints = this.buildHints(this.equipment);
    }
  }

  buildHints(item: Equipment): string {
    if (item.ID <= 0) {
        return null;
    }

    return JSON.stringify(
      this.formatHints(item, this.columnsToDisplay ? this.columnsToDisplay : this.defaultColumnsToDisplay )
    );
  }

  formatHints(item: Equipment, columnsToDisplay: Map<string, string>): ToolTipMessage[] {
    const columns: ToolTipMessage[] = [];
    if (columnsToDisplay.has('Vendor')) {
      columns.push( this.getVendor(item) );
    }
    if (columnsToDisplay.has('DeliveryStatus')) {
      columns.push( this.getDeliveryStatus(item) );
    }
    if (columnsToDisplay.has('SerialNumber')) {
      columns.push( this.getSerialNumber(item));
    }
    if (columnsToDisplay.has('PurchaseOrderNumber')) {
      columns.push( this.getPurchaseOrderNumber(item));
    }
    if (columnsToDisplay.has('PurchaseOrderLineNumber')) {
      columns.push( this.getPurchaseOrderLineNumber(item));
    }
    if (columnsToDisplay.has('ProjectedFATDate')) {
      columns.push( this.getProjectedFATDate(item));
    }
    if (columnsToDisplay.has('NeedByDate')) {
      columns.push( this.getNeedByDate(item));
    }
    if (columnsToDisplay.has('ActualShipDate')) {
      columns.push( this.getActualShipDate(item));
    }
    if (columnsToDisplay.has('IsNonStandard')) {
      columns.push( this.getNonStandard(item));
    }
    if (columnsToDisplay.has('AribaStatus')) {
      columns.push( this.getAribaStatus(item));
    }

    return columns;
  }

  getDeliveryStatus(item: Equipment): ToolTipMessage {
    return new ToolTipMessage(item.DeliveryStatus ? 'Status: ' : '', `${item.DeliveryStatus || ''}`);
  }

  getVendor(item: Equipment): ToolTipMessage {
    return new ToolTipMessage(item.Vendor ? 'Vendor: ' : '', `${item.Vendor || ''}`);
  }

  getProjectedFATDate(item: Equipment): ToolTipMessage {
    const projectedDate = this.formatProjectedFATDate(item);

    return new ToolTipMessage(projectedDate ? 'Projected FAT Date: ' : '', `${projectedDate || ''}`);
  }

  formatProjectedFATDate(item: Equipment): string {
    const pipe = new DateFormatPipe();

    return item.ProjectedFATDate ? pipe.transform(item.ProjectedFATDate.toString()) : null;
  }

  getNeedByDate(item: Equipment): ToolTipMessage {
    const needByDate = this.formatNeedByDate(item);

    return new ToolTipMessage(needByDate ? 'Need By Date: ' : '', `${needByDate || ''}`);
  }

  formatNeedByDate(item: Equipment): string {
    const pipe = new DateFormatPipe();

    return item.NeedByDate ? pipe.transform(item.NeedByDate.toString()) : null;
  }

  getActualShipDate(item: Equipment): ToolTipMessage {
    const actualShipDate = this.formatActualShipDate(item);

    return new ToolTipMessage(actualShipDate ? 'Actual Ship Date: ' : '', `${actualShipDate || ''}`);
  }

  formatActualShipDate(item: Equipment): string {
    const pipe = new DateFormatPipe();

    return item.ActualShipDate ? pipe.transform(item.ActualShipDate.toString()) : null;
  }

  getSerialNumber(item: Equipment): ToolTipMessage {
    return new ToolTipMessage(item.SerialNumber ? 'Serial #: ' : '', `${item.SerialNumber || ''}`);
  }

  getPurchaseOrderNumber(item: Equipment): ToolTipMessage {
    return new ToolTipMessage(item.PurchaseOrderNumber ? 'PO #: ' : '', `${item.PurchaseOrderNumber || ''}`);
  }

  getPurchaseOrderLineNumber(item: Equipment): ToolTipMessage {
    return new ToolTipMessage(item.PurchaseOrderLineNumber ? 'PO line #: ' : '', `${item.PurchaseOrderLineNumber || ''}`);
  }

  getNonStandard(item: Equipment): ToolTipMessage {
    return new ToolTipMessage('Is Non Standard: ', `${item.IsNonStandard ? 'Yes' : 'No'}`);
  }

  getAribaStatus(item: Equipment): ToolTipMessage {
    return new ToolTipMessage(item.AribaStatus ? 'Ariba Status: ' : '', `${item.AribaStatus || ''}`);
  }
}
