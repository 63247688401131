export enum Dictionaries {
    projectTypes = 'project-types',
    projectStatuses = 'project-statuses',
    equipmentTypes = 'equipment-types',
    deliveryStatuses = 'delivery-statuses',
    reviewStatuses = 'review-statuses',
    purchaseOrders = 'purchase-orders',
    orderTypes = 'order-types',
    fabricators = 'fabricators',
    teams = 'teams',
    roles = 'roles',
    area = 'area'
}
