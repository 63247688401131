import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Page } from '_interfaces/page';
import { ToasterService } from '#services/shared';
import { FileUploaderService } from '#services/api/file-uploader.service';
import { MilestoneTracker } from 'models';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '#components/shared/confirmation-dialog/confirmation-dialog.component';
import { environment } from '#environments/environment';
import { GetFilterBase } from 'models';
import { Dictionary } from 'models';
import { DateHelper } from '_extentions/date-helper';

@Component({
    selector: 'app-milestone-import',
    templateUrl: './milestone-import.component.html',
    styleUrls: ['./milestone-import.component.scss']
})

export class MilestoneImportComponent extends GetFilterBase implements Page {

    constructor(
        private location: Location,
        private toaster: ToasterService,
        public dialog: MatDialog,
        private fileUploader: FileUploaderService
    ) {
        super();
    }
    // page
    pageTitle = 'Milestone tool';
    pageDescription = 'import your milestone data';

    // form properties
    selectedFabricatorId: number;
    reportDate: Date;
    file: File;

    // other properties
    parsedData: MilestoneTracker;

    // cached to reduce the number of DateHelper calls
    lastDateOfTheMonth: Date = this.getLastDateOfTheMonth();
    middleDateOfTheMonth: Date = this.getMiddleDateOfTheMonth();

    // [min] allows to reduce the number of [matDatepickerFilter] calls from 4800 to 380 times
    minReportDate: Date = this.middleDateOfTheMonth < this.lastDateOfTheMonth
        ? this.middleDateOfTheMonth
        : this.lastDateOfTheMonth;

    // [max] allows to reduce the number of [matDatepickerFilter] calls from 3600 to 380 times
    maxReportDate: Date = this.middleDateOfTheMonth > this.lastDateOfTheMonth
        ? this.middleDateOfTheMonth
        : this.lastDateOfTheMonth;

    fileListChanged(file: File) {
        this.file = file;
    }

    onCancel() {
        this.location.back();
    }

    onCleanData() {
        this.parsedData = null;
        this.file = null;
    }

    updateHttpFilters() {
        super.clearFilterParams();

        if (this.selectedFabricatorId) {
            super.addFilterParam('fabricator', this.selectedFabricatorId.toString());
        }

        if (this.reportDate) {
            super.addFilterParam('reportDate', this.reportDate.toLocaleDateString());
        }
    }

    onFabricatorChanged(event) {
        if (event.value) {
            this.selectedFabricatorId = (<Dictionary>event.value).ID;
        }
    }

    async upload() {
        const formData = new FormData();
        formData.append('file', this.file);

        this.updateHttpFilters();

        return await this.fileUploader.upload(
            `${environment.apiEndpoint}/milestone-tracker/import`,
            formData, this.httpParams).toPromise();
    }

    async onSubmit() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            minWidth: '350px',
            minHeight: '200px',
            data: <Page>{
                pageTitle: 'Confirmation',
                pageDescription: 'You are going to update database. Are you sure?'
            }
        });

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                this.parsedData = await this.upload() as MilestoneTracker;

                this.showUploadNotification(this.parsedData);
            }
        });
    }


    showUploadNotification(results: MilestoneTracker) {
        const hasImported = results.EquipmentsSuccessfullyUpdated;
        const hasErrors = results.Errors.length;

        if (hasImported && !hasErrors) {
            this.toaster.showSuccess('Success...', 'Data was imported successfully.');
        }

        if (hasImported && hasErrors) {
            this.toaster.showWarn('Warning...', 'Data was imported partially.');
        }

        if (!hasImported && hasErrors) {
            this.toaster.showError('Error...', 'Data can not be imported.');
        }
    }

    getReportDateFilter = (selectedDate: Date): boolean => {
        return DateHelper.equal(selectedDate, this.lastDateOfTheMonth)
            || DateHelper.equal(selectedDate, this.middleDateOfTheMonth);
    }

    getMiddleDateOfTheMonth(): Date {
        return DateHelper.getMiddleDateOfTheMonth();
    }

    getLastDateOfTheMonth(): Date {
        const currentDate = DateHelper.getCurrentDate();
        const middleDate = DateHelper.getMiddleDateOfTheMonth();
        const currentMonth = DateHelper.getCurrentMonth();

        return currentDate > middleDate
            ? DateHelper.getLastDateOfTheMonth(currentMonth + 1)
            : DateHelper.getLastDateOfTheMonth(currentMonth);
    }
}
