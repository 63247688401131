<form #drawingForm="ngForm" class="col">
  <div class="row">
    <mat-form-field appearance="outline" class="fx-50">
      <mat-label>Name</mat-label>
      <input matInput
             [(ngModel)]="drawing.Name"
             [ngModelOptions]="{standalone: true}"
             maxlength="50"
             placeholder="enter name">
    </mat-form-field>

    <!-- Equipment type filter -->
    <app-equipment-type-dropdown-multiple [appearance]="appearance"
                                          class="fx-50"
                                          [selectedEquipmentTypeIds]="selectedEquipmentTypeIds"
                                          (closed)="onFilterChanged()"
                                          (changed)="equipmentTypesSelectionChange($event)">
    </app-equipment-type-dropdown-multiple>
    <br />
  </div>
  <ng-template *ngIf="this.drawing.ID > 0 && this.drawing.DocumentName; then resultTemplate; else dragDropTemplate;"></ng-template>
</form>

<ng-template #dragDropTemplate>
  <app-drag-drop [format]="'.pdf'"
                 (fileListChanged)="fileListChanged($event)">

    <app-empty-list-holder [description]="file && file.name || 'Select your file'"
                           [icon]="'cloud_upload'"
                           class="col drag-drop-container">
      <p *ngIf="!file">or drag & drop it</p>
      <p *ngIf="file" class="{{ file.size >= 5242880 && 'big-file-size' }}">
        File size: {{ file.size | fileSizePipe }}
      </p>
    </app-empty-list-holder>

  </app-drag-drop>
</ng-template>

<ng-template #resultTemplate>
  <div class="row" style="display: inline-block;margin-left: 1.5%;">
    <a (click)="downloadDrawing(drawing)">
      {{ this.drawing.DocumentName}}
    </a>
    <button
            *ngIf="this.drawing.DocumentName"
            matSuffix
            mat-icon-button
            matTooltip="Clear filter"
            aria-label="Clear"
            (click)="clearFile()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</ng-template>
