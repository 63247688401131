import { Component, DoCheck, Input, Output, EventEmitter } from '@angular/core';
import { LinkedPOInfoService } from '#services/api';
import { ToasterService } from '#services/shared';
import { POLineItem } from 'models';

@Component({
  selector: 'app-link-po-info-form',
  templateUrl: './link-po-info-form.component.html',
  styleUrls: ['./link-po-info-form.component.scss']
})
export class linkPOInfoFormComponent implements DoCheck {

  @Input() equipmentId: number;
  @Input() existingPO: Array<any>;
  @Output() onCancel = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<POLineItem>();

  purchaseOrderNumber: number;
  purchaseOrderLineNumber: number;
  isValid = false;

  poLineItem: POLineItem = new POLineItem();

  constructor(
  private linkedPOInfoService: LinkedPOInfoService,
  private toaster: ToasterService,
  ) {}

  ngDoCheck(): void {
    if(this.purchaseOrderLineNumber) {
      this.poLineItem.PurchaseOrderNumber = this.purchaseOrderNumber;
      this.poLineItem.PurchaseOrderLineNumber = this.purchaseOrderLineNumber;
      this.poLineItem.EquipmentID = this.equipmentId;
      this.isValid = true;
    }
}

  onCancelClick(){
    this.onCancel.emit(false);
  }

  async onSaveClick() {
    let exists = this.existingPO.filter(
    po => po.PurchaseOrderLineNumber === this.purchaseOrderLineNumber && po.PurchaseOrderNumber === this.purchaseOrderNumber);
    if (exists.length === 0) {
      await this.linkedPOInfoService.addNew(this.poLineItem)
      .toPromise();
      this.toaster.showSuccess('Success...', 'PO line item has been linked.');
      this.onSave.emit(this.poLineItem);
    } else {
      this.toaster.showError('Error...', 'This Purchase Order Line item has already been added.');
    }
  }
}
