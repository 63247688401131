import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { CostTransferTracker } from 'models';
import { ToasterService } from '#services/shared';
import { MatDialog } from '@angular/material/dialog';
import { FileUploaderService } from '#services/api/file-uploader.service';
import { environment } from '#environments/environment';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '#components/shared/confirmation-dialog/confirmation-dialog.component';
import { Page } from '_interfaces/page';
import { take, tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-cost-transfer-import',
  templateUrl: './cost-transfer-import.component.html',
  styleUrls: ['./cost-transfer-import.component.scss']
})
export class CostTransferImportComponent {

  constructor(
    private location: Location,
    private toaster: ToasterService,
    public dialog: MatDialog,
    private fileUploader: FileUploaderService
  ) { }

  // page
  pageTitle = 'Cost transfer tool';
  pageDescription = 'import your cost transfer data';

  // form properties
  file: File;

  // other properties
  parsedData: CostTransferTracker;

  fileListChanged(file: File) {
    this.file = file;
  }

  onCancel() {
    this.location.back();
  }

  onCleanData() {
    this.parsedData = null;
    this.file = null;
  }

  onSubmit() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '350px',
      minHeight: '200px',
      data: <Page>{
        pageTitle: 'Confirmation',
        pageDescription: 'You are going to update database. Are you sure?'
      }
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        this.upload()
          .pipe(
            map(data => {
              data.Errors = data.Errors.sort(this.compare);
              data.Warnings = data.Warnings.sort(this.compare);
              return data;
            }),
            tap(data => this.showUploadNotification(data)),
            take(1)
          )
          .subscribe(data => {
            this.parsedData = data;
          });
      }
    });
  }

  compare(a: { LineNumber }, b: { LineNumber }): number {
    return a.LineNumber - b.LineNumber;
  }

  upload(): Observable<CostTransferTracker> {
    const endpoint = `${environment.apiEndpoint}/cost-transfer-tracker/import`;
    const formData = new FormData();
    formData.append('file', this.file);

    return this
      .fileUploader
      .upload(endpoint, formData)
      .pipe(
        map((data: CostTransferTracker) => {
          data.Errors = data.Errors || [];
          data.Warnings = data.Warnings || [];
          return data;
        })
      );
  }

  showUploadNotification(results: CostTransferTracker) {
    const hasImported = results.EquipmentsSuccessfullyUpdated;
    const hasErrors = results.Errors.length;

    if (hasImported && !hasErrors) {
      this.toaster.showSuccess('Success...', 'Data was imported successfully.');
    }

    if (hasImported && hasErrors) {
      this.toaster.showWarn('Warning...', 'Data was imported partially.');
    }

    if (!hasImported && hasErrors) {
      this.toaster.showError('Error...', 'Data can not be imported.');
    }
  }
}
