<div id="fab-dismiss" 
     *ngIf="fabTogglerState==='active'" 
     (click)="onToggleFab()">
</div>

<div 
    *ngIf="fabButtons && fabButtons.length" 
    [@inOutAnimation]
    class="fab-container">
    <button 
        mat-fab 
        class="fab-toggler"
        (click)="onToggleFab()">
        <i 
            class="material-icons" 
            [@fabToggler]="{value: fabTogglerState}">
            add
        </i>
    </button>
    <div *ngIf="isToogled" [@speedDialStagger]="fabButtons.length">
        <button 
            *ngFor="let btn of fabButtons" 
            mat-mini-fab 
            class="fab-secondary"
            color="secondary"
            (click)="btn.event(); hideItems()"
            matTooltip="{{ btn.tooltip }}"
            matTooltipPosition="before">
            <mat-icon *ngIf="btn.icon; else noIconTemplate">
                {{ btn.icon }}
            </mat-icon>
            <ng-template #noIconTemplate>{{ btn.letter }}</ng-template>
        </button>
    </div>
</div>