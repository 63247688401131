import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { User } from 'models';
import { UserDirectoryService } from '#services/api';
import { FormControl } from '@angular/forms';
import { map, startWith, debounceTime, filter, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss']
})
export class UserDropdownComponent implements OnInit {
  items: Observable<User[]>;

  myControl = new FormControl();

  @Input() appearance = 'outline';
  @Input() label: string;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() defaultValue: User;
  @Output() changed = new EventEmitter();

  constructor(private userService: UserDirectoryService) { }

  ngOnInit() {
    if (this.defaultValue) {
      this.myControl.setValue(this.userService.decorate(this.defaultValue));
    }

    this.items = this.myControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        filter(value => typeof value === 'string' && !!value),
        switchMap(value => this.getUsers(value))
      );
  }

  displayFn(item: User) {
    return item && item.FullNameEmail;
  }

  onItemChanged(event: MatAutocompleteSelectedEvent) {
    this.changed.emit({ value: event.option.value });
  }

  onItemCleared() {
    this.myControl.setValue(null);
    this.changed.emit({ value: null });
  }

  getUsers(query: string) {
    return this.userService.getAll(query).pipe(
      map(users => users.map(this.userService.decorate))
    );
  }
}
