
  <div class="col" *ngIf="canShowDrawings; else noDrawingTemplate">
    <mat-toolbar class="page-toolbar add-space" *ngIf="canShowDrawings">
      <button *appUserRoleUp="[Role.Admin]"
              class="btn btn-primary margin-left-md"
              (click)="onAddDrawing();">
        Assign Drawing
      </button>
      <mat-form-field class="fx-33 filter-panel" style="display: block !important;">
        <input matInput
               placeholder="Search"
               (keyup)="onKey($event)">
      </mat-form-field>
    </mat-toolbar>


    <app-drawing-table [data]="drawings"
                       [columnsToDisplay]="drawingColumnsToDisplay"
                       [filter]="filter"
                       (collectionChanged)="onDrawingCollectionChanged($event)">
    </app-drawing-table>
  </div>


<ng-template #noDrawingTemplate>
  <app-empty-list-holder [description]="'No Drawing assigned yet.'">
    <button *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary"
            (click)="onAddDrawing();">
      Assign Drawing
    </button>
  </app-empty-list-holder>
</ng-template>

