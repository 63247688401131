import { Component, OnInit, Inject } from '@angular/core';

import { Equipment } from 'models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Page } from '_interfaces/page';
import { Filter } from 'models';

@Component({
    selector: 'app-equipment-select-dialog',
    templateUrl: './equipment-select-dialog.component.html',
    styleUrls: ['./equipment-select-dialog.component.scss']
})
export class EquipmentSelectDialogComponent implements OnInit, Page {
    // page
    pageTitle = 'Equipment';
    pageDescription = 'select equipment';

    // other properties
    equipments: Equipment[];
    selectedEquipment: Equipment;
    filter: Filter = <Filter>{};

    public equipmentColumnsToDisplay: Map<string, string> = new Map([
        ['selectEquipment', ''],
        ['IsNonStandard', ''],
        ['EquipmentType', 'Equipment type'],
        ['SerialNumber', 'Serial number'],
        ['Vendor', 'Vendor'],
        ['ProjectName', 'Project name'],
        ['ProjectedFATDate', 'Projected FAT date'],
        ['DeliveryStatus', 'Status']
    ]);

    constructor(
        public dialogRef: MatDialogRef<EquipmentSelectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Equipment[]) {
    }

    async ngOnInit() {
        this.equipments = this.data;
    }

    onItemClick(event) {
        this.selectedEquipment = event.value;
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    onKey(event) {
        this.filter = <Filter>{
            search: event.target.value
        };
    }
}
