<div class="col">
    <mat-table matSort multiTemplateDataRows
        *ngIf="canShowTable; else noMatchesTemplate"
        (matSortChange)="onSortData($event)"
        [dataSource]="dataSource"
        class="mat-table-container"
        style="overflow: hidden">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of allColumns">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                <ng-container [ngSwitch]="column">
                    <mat-checkbox *ngSwitchCase="'_checked'"
                        (change)="onCheckedAll($event)"
                        (click)="$event.stopPropagation()">
                    </mat-checkbox>

                    <span *ngSwitchDefault>
                        {{getColumnTitle(column)}}
                    </span>
                </ng-container>
            </mat-header-cell>
            <mat-cell class="mat-cell-value" *matCellDef="let item">
                <ng-container
                    [ngTemplateOutlet]="columnContent"
                    [ngTemplateOutletContext]="{item: item, column: column}">
                </ng-container>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="allColumns; sticky: true"></mat-header-row>

        <!-- Expanded Content Column -->
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let detail" class="delails-info--expanded row">
                <ng-container *ngIf="expandedItem?.ID === detail?.ID">
                    <div class="col details-info-left-content">
                        <p class="bold-text" style="margin: 0">
                            {{detail.Name}}
                        </p>
                        <span>
                            Team: {{detail.Team}}
                        </span>
                        <span>
                            Cost center codes: {{ (detail.CostCenterCodes || []).join(', ') }}
                        </span>
                        <span>
                            AFE: {{detail.PrimaryFundAFE}}
                        </span>
                        <a *appUserRoleUp="[ Role.Admin ]"
                            (click)="onEditProject(expandedItem)">
                            view details >
                        </a>
                        <a *appUserRoleUp="[ Role.User ]"
                            (click)="onViewProject(expandedItem)">
                            view details >
                        </a>

                        <br>
                        <span>
                            equipment needs
                            <p  class="bold-text">{{expandedItem.EquipmentsNeed}}</p>
                        </span>
                        <span>
                            equipment ordered
                            <p  class="bold-text">{{expandedItem.EquipmentsOrdered}}</p>
                        </span>
                        <span>
                            equipment to be allocated
                            <p  class="bold-text">{{expandedItem.EquipmentsNeed - expandedItem.EquipmentsOrdered}}</p>
                        </span>
                        <span>
                            Days left to start up
                            <span *ngIf="dateLeftToStart !== 'expired' && dateLeftToStart !== 'unknown'; else wrongTimeToGo">
                                <p  class="bold-text">{{dateLeftToStart}} <span>days</span></p>
                            </span>
                        </span>
                    </div>
                    <div *ngIf="expandedItem" class="col details-info-right-content">
                        <ng-container *appUserRoleUp="[ Role.Admin ]">
                            <div
                                *ngIf="equipment && equipment.length"
                                class="row pull-right">
                                <button mat-button
                                    (click)="onAddNewEquipment()"
                                    class="add-equipment-button">
                                    <mat-icon>add</mat-icon>
                                    Add new equipment
                                </button>
                                <button mat-button
                                    (click)="onAddExistEquipment()"
                                    class="add-equipment-button margin-left-md">
                                    <mat-icon>add</mat-icon>
                                    Add exist equipment
                                </button>
                                <button mat-button
                                    (click)="onAddEquipmentFromTemplate()"
                                    class="add-equipment-button margin-left-md">
                                    <mat-icon>add</mat-icon>
                                    Add equipment from a template
                                </button>
                            </div>
                        </ng-container>

                        <app-equipment-table
                            *ngIf="equipment && equipment.length; else noEquipmentsTemplate"
                            [columnsToDisplay]="equipmentColumnsToDisplay"
                            [data]="equipment"
                            [showPadination]="false"
                            [bulkDisabled]="false"
                            (collectionChanged)="onEquipmentCollectionChanged($event)"
                            style="height: 340px; overflow: auto">
                        </app-equipment-table>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>

        <mat-row
            *matRowDef="let row; columns: allColumns"
            matRipple
            class="element-row"
            [@rowsAnimation]=""
            [class.expanded]="expandedItem?.ID === row?.ID"
            [ngClass]="{ 'selected-row': selectedItem && row.ID === selectedItem.ID }"
            (click)="onRowClick(row)">
        </mat-row>
        <mat-row
            class="details-row"
            *matRowDef="let row; columns: ['expandedDetail']"
            [@detailExpand]="row?.ID === expandedItem?.ID ? 'expanded' : 'collapsed'">
        </mat-row>
    </mat-table>
</div>

<mat-paginator
    [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons>
</mat-paginator>

<ng-template #columnContent let-item="item" let-column="column">
    <ng-container [ngSwitch]="column">
        <span *ngSwitchCase="'_checked'">
            <mat-checkbox
                [(ngModel)]="item[column]"
                (change)="(onChecked($event))"
                (click)="$event.stopPropagation()">
            </mat-checkbox>
        </span>

        <span *ngSwitchCase="'Name'">
            <mat-icon *ngIf="item.Template" title="The project is a template">
                star
            </mat-icon>

            {{ item.Name }}
        </span>

        <span *ngSwitchCase="'EquipmentsNeed'">
            {{ item.EquipmentsOrdered || 0 }}/{{ item[column] || 0 }}
        </span>

        <span *ngSwitchCase="'Comment'" matTooltip="{{item[column]}}">
            <mat-icon *ngIf="item[column]" class="flipped small-icon">
                chat_bubble_outline
            </mat-icon>
        </span>

        <span *ngSwitchCase="'Archived'" class="cell-align-center">
            <mat-icon *ngIf="item[column]" title="The project is archived">folder</mat-icon>
        </span>

        <span *ngSwitchCase="'HasNonStandardEquipments'" class="cell-align-center"
            matTooltip="Has non standard equipment">
            <mat-icon *ngIf="item[column]">play_for_work</mat-icon>
        </span>

        <span *ngSwitchCase="'HasAribaAFEMismatchEquipments'">
            <mat-icon *ngIf="item[column] === null"
                class="info-color"
                title="The project has no information about AFE missmatch equipments">
                sync_problem
            </mat-icon>

            <mat-icon *ngIf="item[column] === true"
                class="warning-color"
                title="The project has AFE missmatch equipments">
                warning
            </mat-icon>
        </span>

        <span *ngSwitchCase="'ReviewStatus'" matTooltip="{{item[column]}}">
            <ng-container [ngSwitch]="item[column]">
                <mat-icon *ngSwitchCase="'Not reviewed'" class="status-red">adjust</mat-icon>
                <mat-icon *ngSwitchCase="'In progress'" class="status-yellow">adjust</mat-icon>
                <mat-icon *ngSwitchCase="'Reviewed'" class="status-green">adjust</mat-icon>
            </ng-container>
        </span>

        <ng-container *appUserRoleUp="[ Role.Admin ]">
            <button *ngSwitchCase="'Menu'"
                mat-button
                (click)="$event.stopPropagation()"
                class="menu-button pull-left"
                [matMenuTriggerFor]="menu"
                [matMenuTriggerData]="{item: item}">
                <mat-icon>more_vert</mat-icon>
            </button>
        </ng-container>

        <span *ngSwitchDefault>
            {{
                helper.isDate(item[column])
                ? (item[column] | dateFormatPipe)
                : (item[column])
            }}
        </span>
    </ng-container>
</ng-template>


<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item
            (click)="onEditProject(item)">
            <mat-icon>edit</mat-icon>
            Edit
        </button>
        <button mat-menu-item
            (click)="onCancelProject(item['ID'])">
            <mat-icon>cancel_outline</mat-icon>
            Cancel
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item
            *ngIf="!item.Template"
            (click)="onMarkAsTemplate(item['ID'])">
            <mat-icon>star</mat-icon>
            Mark as a template
        </button>
        <button mat-menu-item
            *ngIf="item.Template"
            (click)="onMarkAsNonTemplate(item['ID'])">
            <mat-icon>star_border</mat-icon>
            Mark as a non-template
        </button>
        <mat-divider></mat-divider>
        <ng-container *ngIf="isOutlookReview">
            <button mat-menu-item
                (click)="onMarkAsReviewed(item['ID'])">
                <mat-icon>done</mat-icon>
                Reviewed
            </button>
            <button mat-menu-item
                (click)="onMarkAsNotReviewed(item['ID'])">
                <mat-icon>clear</mat-icon>
                Not Reviewed
            </button>
            <mat-divider></mat-divider>
        </ng-container>
        <button mat-menu-item
            (click)="onPrintProject(item)">
            <mat-icon>print</mat-icon>
            Print
        </button>
        <button mat-menu-item
            (click)="onArchiveProject(item['ID'])">
            <mat-icon>archive</mat-icon>
            Archive
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item
            (click)="onDeleteProject(item['ID'])">
            <mat-icon>delete_forever</mat-icon>
            Delete
        </button>
    </ng-template>
</mat-menu>

<ng-template #noMatchesTemplate>
    <app-empty-list-holder
        [icon]="'search'"
        [description]="'No matches'">
    </app-empty-list-holder>
</ng-template>

<ng-template #noEquipmentsTemplate>
    <app-empty-list-holder
        [description]="'No equipment added yet.'">
        <div *appUserRoleUp="[ Role.Admin ]" class="row">
            <span>Add
                <button mat-button
                    (click)="onAddNewEquipment()"
                    class="add-equipment-button">
                    new equipment
                </button>,
                or add
                <button mat-button
                    (click)="onAddEquipmentFromTemplate()"
                    class="add-equipment-button">
                    equipment from a template
                </button>,
                or select an
                <button mat-button
                    (click)="onAddExistEquipment()"
                    class="add-equipment-button">
                    exist equipment
                </button>
                from the list.
            </span>
        </div>
    </app-empty-list-holder>
</ng-template>

<ng-template #wrongTimeToGo>
    <p class="bold-text">{{dateLeftToStart}}</p>
</ng-template>
