<mat-form-field class="cost-centers-select-list">
    <mat-chip-grid #chipList aria-label="Cost center selection">
      <mat-chip *ngFor="let code of codes" [selectable]="selectable"
               [removable]="removable" (removed)="remove(code)">
        {{code}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input placeholder="enter cost center codes..."
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="addOnBlur"
             (matChipInputTokenEnd)="add($event)">
    </mat-chip-grid>
  </mat-form-field>