import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from 'models';
import { environment } from '#environments/environment';

@Injectable()
export class UserDirectoryService {
    constructor(private http: HttpClient) { }

    public getAll(term: string): Observable<User[]> {
        const options = { params: { freeText: term } };
        return this.http.get<User[]>(`${environment.apiEndpoint}/users-directory`, options);
    }

    decorate(user: User): User {
        return {
            ...user,
            FullName: `${user.FirstName} ${user.LastName}`,
            FullNameEmail: `${user.FirstName} ${user.LastName} (${user.Email})`
        };
    }
}
