export class FabButton {
    event: Function;
    tooltip: string;
    icon: string;
    letter: string;

    constructor(event: Function, tooltip: string, icon: string, letter: string = null) {
        this.event = event;
        this.tooltip = tooltip;
        this.icon = icon;
        this.letter = letter;
    }
}
