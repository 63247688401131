<form #vendorListForm="ngForm">
    <mat-toolbar class="page-toolbar" *ngIf="canShowMilestoneTypes">
        <span class="project-count">
            {{milestoneTypes && milestoneTypes.length}} Milestone Types
        </span>
        <span class="fill-remaining-space"></span>

        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary margin-left-md"
            [routerLink]="['/milestone-type-create']">
            Add new milestone
        </button>
    </mat-toolbar>

    <div *ngIf="canShowMilestoneTypes; else noVendorsTemplate">
        <mat-divider></mat-divider>
        
        <app-search-panel 
            (searchChanged)="onSearchChanged($event)">
        </app-search-panel>
        
        <milestone-type-table
            [data]="milestoneTypes"
            [columnsToDisplay]="milestoneColumnsToDisplay"
            [filter]="filter"
            (collectionChanged)="onMilestoneCollectionChanged($event)">
        </milestone-type-table>
    </div>
</form>

<ng-template #noVendorsTemplate>
    <app-empty-list-holder
        [description]="'No Milestone added yet.'">
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary" 
            [routerLink]="'/vendor-create'">
            Add new milestone
        </button>
    </app-empty-list-holder>
</ng-template>