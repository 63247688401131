import { Component, Output, EventEmitter } from '@angular/core';
import { Role } from 'enums';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SideNavListComponent {
  @Output() sidenavClose = new EventEmitter();

  Role = Role;

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }
}
