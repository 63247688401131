<ng-container *appUserRoleUp="[ Role.Vendor ]">
    <app-sidenav-vendor (itemClick)="onSidenavClose()"></app-sidenav-vendor>
</ng-container>

<ng-container *appUserRoleUp="[ Role.Admin ]">
    <app-sidenav-buadmin (itemClick)="onSidenavClose()"></app-sidenav-buadmin>
</ng-container>

<ng-container *appUserRoleUp="[ Role.User ]">
    <app-sidenav-user (itemClick)="onSidenavClose()"></app-sidenav-user>
</ng-container>