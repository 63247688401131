import { Component, Input } from '@angular/core';
import { ToolTipMessage } from 'models';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    animations: [
        trigger(
            'inOutAnimation', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('.15s ease-out', style({ height: 'auto', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ height: 'auto', opacity: 1 }),
                animate('.1s ease-in', style({ height: 0, opacity: 0 }))
            ])
        ])
    ]
})
export class TooltipComponent {
    @Input() hints: ToolTipMessage[];
}
