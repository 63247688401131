import { Component } from '@angular/core';
import { Role } from 'enums';

@Component({
    selector: 'app-equipment-list',
    templateUrl: './equipment-list.component.html',
    styleUrls: ['./equipment-list.component.scss']
})

export class EquipmentListComponent {
    Role = Role;
}
