import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-empty-list-holder',
    templateUrl: './empty-list.component.html',
    styleUrls: ['./empty-list.component.scss']
})
export class EmptyListComponent implements OnInit {
    @Input() description: string;
    @Input() icon = 'build';

    ngOnInit() {
        if (!this.description) {
            throw new Error('property \'description\' is missed.');
        }
    }
}
