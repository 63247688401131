import { Component, OnInit, Inject } from '@angular/core';
import { Equipment, Project } from 'models';
import { Page } from '_interfaces/page';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonHelper } from '_extentions/common-helper';
import { Role } from 'enums';
import { ProjectService } from '#services/api';


@Component({
    selector: 'app-project-edit-dialog',
    templateUrl: './project-edit-dialog.component.html',
    styleUrls: ['./project-edit-dialog.component.scss']
})
export class ProjectEditComponent implements OnInit, Page {
    // page
    pageTitle = 'Edit project';
    pageDescription = 'update data below to edit project';

    // other properties
    project: Project;
    isValid = false;
    Role = Role;
    equipment: Equipment[];

    equipmentColumnsToDisplay: Map<string, string> = new Map([
      ['PurchaseOrderLineNumber', 'PO line'],
      ['EquipmentType', 'Equipment type'],
      ['ProjectPrimaryAFE', 'Project primary AFE'],
      ['IsAribaAFEMismatch', '='],
      ['AribaAFE', 'Ariba AFE'],
      ['AribaCost', 'Ariba Cost'],
      ['CostTransferAFE', 'Cost Transfer AFE'],
      ['SerialNumber', 'Serial #'],
      ['ProjectedFATDate', 'Projected FAT date'],
      ['OrderType', 'PO type'],
      ['DeliveryStatus', 'Status'],
    ]);

    constructor(
        public dialogRef: MatDialogRef<ProjectEditComponent>,
        private projectService: ProjectService,
        @Inject(MAT_DIALOG_DATA) public data: Project) {
    }

    async ngOnInit() {
        this.project = CommonHelper.deepCopy(this.data);
        this.projectService.getEquipment(this.project.ID).subscribe( res => this.equipment = res);
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    isValidChanged(isValid: boolean) {
        this.isValid = isValid;
    }
}
