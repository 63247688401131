import { BaseModel } from './baseModel';

export class Equipment extends BaseModel {
    SerialNumber: string = null;

    SupplierFATDate?: Date = null;
    ProjectedFATDate?: Date = null;
    TargetCompletionDate?: Date = null;
    ActualShipDate?: Date = null;
    NeedByDate?: Date = null;

    Comment: string = null;
    MaterialDescription: string = null;
    MaterialSerialNumber: string = null;

    Vendor: string = null;
    VendorComment: string = null;

    Locked = false;
    IsNonStandard = false;
    Installed = false;

    ProjectID?: number;
    ProjectName: string = null;

    PurchaseOrderLineNumber: string = null;
    PurchaseOrderID?: number = null;
    PurchaseOrderNumber: string = null;

    StatusDeliveryID: number = null;
    DeliveryStatus: string = null;

    EquipmentTypeID: number = null;
    EquipmentType: string = null;

    OrderType: string = null;

    ProjectRepresentative: string = null;
    ProjectPrimaryAFE: string = null;

    AribaAFE: string = null;
    AribaCost: number = null;
    CostTransferAFE: string = null;
    AribaStatus: string = null;

    IsAribaAFEMismatch?: boolean = null;

    LinkedPOsInformation?: Array<any>;

    AssignedDrawing?:any[];
}
