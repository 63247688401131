import { Component, OnInit, Input, EventEmitter, ViewChild, Output } from '@angular/core';
import { User, Filter } from 'models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Role } from 'enums';
import { UserEditArgs, UserEditDialogComponent } from '../user-edit-dialog/user-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '#services/api';
import { ToasterService } from '#services/shared';
import { ConfirmationDialogComponent } from '#components/shared/confirmation-dialog/confirmation-dialog.component';
import { Page } from '_interfaces/page';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit {
  @Input()
  set filter(value: Filter) {
    this._filter = value;

    this.applyFilter(value);
  }
  get filter() {
    return this._filter;
  }

  @Input()
  set data(value: User[]) {
    this.dataSource.data = value;
  }

  @Input() columnsToDisplay: Map<string, string>;
  @Output() collectionChanged = new EventEmitter();

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  _filter: Filter = <Filter>{};
  dataSource = new MatTableDataSource<User>();

  Role = Role;

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private toaster: ToasterService
  ) { }

  ngOnInit() {
    setTimeout(() => this.dataSource.paginator = this.paginator);
  }

  get allColumns(): string[] {
    return Array.from(this.columnsToDisplay.keys());
  }

  get canShowTable() {
    const hasSearchedItems = this.filter.search
      ? this.dataSource.filteredData.length
      : true;

    const hasFilterParamsItems = this.filter.paramsCount
      ? this.dataSource.data.length
      : true;

    return hasSearchedItems && hasFilterParamsItems;
  }

  getColumnTitle(column: string): string {
    return this.columnsToDisplay.get(column);
  }

  applyFilter(filter: Filter) {
    this.dataSource.filter = filter.search;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onEditUser(user: User) {
    const args: UserEditArgs = {
      user: user
    };
    const dialogRef = this.dialog.open(UserEditDialogComponent, {
      width: '50%',
      maxHeight: '80%',
      data: args
    });

    dialogRef.afterClosed().subscribe(async (result: User) => {
      if (result) {
        const res = await this.userService.update(result).toPromise();
        this.collectionChanged.emit({ value: res });
        this.toaster.showSuccess('Success...', `User '${user.Email}' has been updated.`);
      }
    });
  }

  onEnableUser(user: User) {
    const { ID: id, Email: email } = user;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        minWidth: '350px',
        minHeight: '200px',
        data: <Page>{
            pageTitle: 'Confirmation',
            pageDescription: `Enable '${email}' user?`
        }
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
        if (result) {
            await this.userService.enable(id).toPromise();
            this.collectionChanged.emit({ value: user });
            this.toaster.showSuccess('Success...', `User '${email}' has been enabled.`);
        }
    });
  }

  onDisableUser(user: User) {
    const { ID: id, Email: email } = user;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        minWidth: '350px',
        minHeight: '200px',
        data: <Page>{
            pageTitle: 'Confirmation',
            pageDescription: `Enable '${email}' user?`
        }
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
        if (result) {
            await this.userService.disable(id).toPromise();
            this.collectionChanged.emit({ value: user });
            this.toaster.showSuccess('Success...', `User '${email}' has been disabled.`);
        }
    });
  }
}
