import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { CRUDService } from '#services/api/crud.service';
import { Equipment, Reshuffle, EquipmentHistory, BulkOperationResult } from 'models';
import { DeliveryStatus } from 'enums';
import { Drawing } from 'models/drawing';

@Injectable()
export class EquipmentService extends CRUDService<Equipment> {
  constructor(http: HttpClient) {
    super(http, `${environment.apiEndpoint}/equipments`);
  }

  public lock(equipmentId: number): Observable<Equipment> {
    return this.http.put<any>(`${this.url}/${equipmentId}/lock`, null);
  }

  public bulkLock(ids: number[]): Observable<BulkOperationResult<Equipment>> {
    return this.http.put<any>(`${this.url}/${ids}/bulk-lock`, null);
  }

  public bulkUnlock(ids: number[]): Observable<BulkOperationResult<Equipment>> {
    return this.http.put<any>(`${this.url}/${ids}/bulk-unlock`, null);
  }

  public bulkSpare(ids: number[]): Observable<BulkOperationResult<Equipment>> {
    return this.http.put<any>(`${this.url}/${ids}/bulk-spare`, null);
  }

  public bulkChangeStatus(ids: number[], status: DeliveryStatus): Observable<BulkOperationResult<Equipment>> {
    return this.http.put<any>(`${this.url}/${ids}/bulk-change-status/${status}`, null);
  }

  public multipleUpdate(equipment: Equipment): Observable<Equipment[]> {
    return this.http.put<any>(`${this.url}/multipleUpdate`, equipment);
  }

  public unlock(equipmentId: number): Observable<Equipment> {
    return this.http.put<any>(`${this.url}/${equipmentId}/unlock`, null);
  }

  public spare(equipmentId: number): Observable<Equipment> {
    return this.http.put<any>(`${this.url}/${equipmentId}/spare`, null);
  }

  public getReshufflingOptions(equipmentTypeId: number): Observable<Reshuffle[]> {
    return this.http.get<any>(`${this.url}/${equipmentTypeId}/reshuffle`);
  }

  public reshuffle(reshuffleCollection: Reshuffle[]): Observable<any> {
    return this.http.post<any>(`${this.url}/reshuffle`, reshuffleCollection);
  }

  public demandSupply(equipmentTypeId: number, fabricatorId: number): Observable<Reshuffle[]> {
    const params = new HttpParams()
      .append('typeID', equipmentTypeId.toString())
      .append('fabricatorID', fabricatorId.toString());
    const options = { params };
    return this.http.get<any>(`${this.url}/reshuffle-view-only-mode`, options);
  }

  public getAll(body: any = null): Observable<any[]> {
    return this.http.post<any>(`${this.url}/filter`, body);
  }

  public getHistory(equipmentId: number): Observable<EquipmentHistory[]> {
    return this.http.get<any>(`${this.url}/${equipmentId}/allocation-history`);
  }
  public getEquipmentById(equipmentId: number): Observable<EquipmentHistory[]> {
    return this.http.get<any>(`${this.url}/${equipmentId}`);
  }

  public assignDrawing(equipmentId: number, drawingIds:number[], options:any=null): Observable<any> {
    return this.http.put<any>(`${this.url}/${equipmentId}/assign-drawing`,drawingIds, { params: options });
  }

  public getDrawing(equipmentId: number): Observable<Drawing[]> {
    return this.http.get<any>(`${this.url}/${equipmentId}/drawing`);
  }
}
