import { Component, OnInit, Input } from '@angular/core';
import { Equipment } from 'models/';
import { ToolTipMessage } from 'models';

@Component({
  selector: 'app-equipment-comment',
  templateUrl: './equipment-comment.component.html',
  styleUrls: ['./equipment-comment.component.scss']
})
export class EquipmentCommentComponent implements OnInit {
  @Input() equipment: Equipment;
  @Input() columnsToDisplay: Map<string, string>;

  defaultColumnsToDisplay: Map<string, string> = new Map([
    ['Comment', ''],
    ['VendorComment', '']
  ]);

  comments: string;

  ngOnInit() {
    if (this.equipment) {
      this.comments = this.buildComments(this.equipment);
    }
  }

  buildComments(item: Equipment): string {
    if (item.ID <= 0) {
      return null;
    }

    const comments = this.formatComments(item, this.columnsToDisplay ? this.columnsToDisplay : this.defaultColumnsToDisplay);
    if (!comments.length) {
      return null;
    }

    return JSON.stringify(comments);
  }

  formatComments(item: Equipment, columnsToDisplay: Map<string, string>): ToolTipMessage[] {
    const columns: ToolTipMessage[] = [];

    if (columnsToDisplay.has('Comment')) {
      const comment = this.getComment(item);
      if (comment) {
        columns.push( comment );
      }
    }

    if (columnsToDisplay.has('VendorComment')) {
      const vendorComment = this.getVendorComment(item);
      if (vendorComment) {
        columns.push( vendorComment );
      }
    }

    return columns;
  }

  getComment(item: Equipment): ToolTipMessage {
    if (!item.Comment) {
      return null;
    }

    return new ToolTipMessage('General comments: ', item.Comment);
  }

  getVendorComment(item: Equipment): ToolTipMessage {
    if (!item.VendorComment) {
      return null;
    }

    return new ToolTipMessage('Vendor comments: ', item.VendorComment);
  }
}
