import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Dictionary } from 'models';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DictionaryService } from '#services/api';
import { Dictionaries } from 'enums';

@Component({
  selector: 'app-vendor-dropdown',
  templateUrl: './vendor-dropdown.component.html',
  styleUrls: ['./vendor-dropdown.component.scss']
})
export class VendorDropdownComponent implements OnInit {
  items: Dictionary[];

  @Input() parentFormControl: FormControl;
  @Input() appearance = 'outline';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() defaultValue: Dictionary;
  @Output() changed = new EventEmitter();

  constructor(private dictionaryService: DictionaryService) { }

  ngOnInit() {
    this.initFabricators();
  }

  initFabricators() {
    this.fetchFabricators()
      .pipe(take(1))
      .subscribe(data => {
        this.setFabricators(data);
        this.setDefaultFabricator(data);
        this.setFabricatorsReadonly(data);
      });
  }

  fetchFabricators(): Observable<Dictionary[]> {
    return this.dictionaryService.getAll(`${Dictionaries.fabricators}/dictionary`);
  }

  setFabricators(fabricators: Dictionary[]) {
    this.items = fabricators;
  }

  setDefaultFabricator(fabricators: Dictionary[]) {
    if (!this.defaultValue) {
      this.defaultValue = fabricators.length === 1 ? fabricators[0] : null;
    }
  }

  setFabricatorsReadonly(fabricators: Dictionary[]) {
    this.readonly = fabricators.length === 1;
  }

  onItemChanged(event) {
    this.changed.emit({ value: event.value });
  }
}
