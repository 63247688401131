import { Component, OnInit, Inject } from '@angular/core';
import { Page } from '_interfaces/page';
import { Equipment, PurchaseOrder } from 'models/';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonHelper } from '_extentions/common-helper';
import { PurchaseOrderService } from '#services/api';

@Component({
  selector: 'app-po-view-dialog',
  templateUrl: './po-view-dialog.component.html',
  styleUrls: ['./po-view-dialog.component.scss']
})
export class PoViewDialogComponent implements OnInit, Page {
  // page
  pageTitle = 'View material supply';
  pageDescription = 'view data below to view the purchase order';

  // other properties
  purchaseOrder: PurchaseOrder;
  equipment: Equipment[];
  isValid = false;

  equipmentColumnsToDisplay: Map<string, string> = new Map([
    ['PurchaseOrderLineNumber', 'PO line'],
    ['EquipmentType', 'Equipment type'],
    ['ProjectName', 'Project name'],
    ['SerialNumber', 'Serial #'],
    ['DeliveryStatus', 'Status'],
  ]);

  constructor(
      public dialogRef: MatDialogRef<PoViewDialogComponent>,
      private poService: PurchaseOrderService,
      @Inject(MAT_DIALOG_DATA) public data: PurchaseOrder) {
  }

  ngOnInit() {
      this.purchaseOrder = CommonHelper.deepCopy(this.data);
      this.poService.getEquipment(this.purchaseOrder.ID).subscribe( res => this.equipment = res);
  }

  onCloseClick(): void {
      this.dialogRef.close();
  }
}
