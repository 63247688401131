import { BaseModel } from './baseModel';

export class Project extends BaseModel {
    Number: string = null;
    PlaceholderNumber: string = null;

    Name: string = null;
    CDName: string = null;

    Representative: string = null;
    RepresentativeEmail: string = null;

    TargetCompletionDate?: Date = null;
    NeedByDate?: Date = null;

    KeyName: string = null;
    KeyNameFreeForm: string = null;
    StandardName: string = null;

    PreFundedAFE: string = null;
    PrimaryFundAFE: string = null;

    Comment: string = null;

    TeamID?: number = null;
    Team: string = null;

    StatusID: number = null;
    Status: string = null;

    ReviewStatusID: number = null;
    ReviewStatus: string = null;
    ReviewDate: Date = null;

    TypeID: number = null;
    Type: string = null;
    Template: boolean;

    EquipmentsNeed: number = null;
    EquipmentsOrdered: number = null;
    HasNonStandardEquipments = false;

    HasAribaAFEMismatchEquipments?: boolean = null;

    CostCenterCodes: string[] = [];

    Area: string = null;
    AreaID: number = null;
}
