<div class="page-with-shadow">
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>

    <form #reshufflingForm="ngForm" class="col">
        <div class="row">
            <app-autocomplete-dropdown
                [parentFormControl]="reshufflingForm.control"
                [options]="equipmentTypes"
                [label]="'Equipment type'"
                [displayProperty]="'Name'"
                [placeholder]="'select equipment type'"
                [required]="true"
                (selectedChanged)='onEquipmentTypeChanged($event)'
                (clicked)="onLoadEquipmentTypes()"
                class="fx-50">
            </app-autocomplete-dropdown>

            <app-vendor-dropdown class="fx-50"
                [parentFormControl]="reshufflingForm.control"
                [label]="'Vendor'"
                [placeholder]="'select vendor'"
                [required]="true"
                (changed)="onFabricatorChanged($event)">
            </app-vendor-dropdown>
        </div>

        <br/>
        <mat-divider></mat-divider>

        <div class="row" *ngIf="demandItemsCount || supplyItemsCount; else noOptionsTemplate">
            <div class="col fx-50">
                <span class="input-group-name">Demand ({{ demandItemsCount }})</span>
                <app-equipment-table 
                    [columnsToDisplay]="demandColumnsToDisplay" 
                    [data]="demandItems"
                    [showPadination]="false"
                    [canSort]="false"
                    [canDeleteEquipment]="false">
                </app-equipment-table>
            </div>
            <div class="col vertical-splitter">
                <span class="vertical-line fill-remaining-space"></span>
            </div>
            <div class="col fill-remaining-space">
                <span class="input-group-name">Supply ({{ supplyItemsCount }})</span>
                <app-equipment-table 
                    #supplyTable
                    [columnsToDisplay]="supplyColumnsToDisplay" 
                    [data]="supplyItems"
                    [dragDropDisabled]="false"
                    [swapDisabled]="false"
                    [showPadination]="false"
                    [showCheckAll]="false"
                    [canSort]="false"
                    [canSelect]="false">
                </app-equipment-table>
            </div>
        </div>
    </form>
</div>

<ng-template #noOptionsTemplate>
    <app-empty-list-holder 
        [description]="'No options to perform reshuffle'">
       <span *ngIf="!selectedTypeId">
           Select equipment type from the list.
       </span>
       <span *ngIf="selectedTypeId">
            Cannot find options for selected type of equipment.
        </span>
    </app-empty-list-holder>
</ng-template>