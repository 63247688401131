<div>
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>
    
    <div mat-dialog-content (keyup.enter)="onSaveClick()">
        <app-user-template *ngIf="user"
            [user]="user"
            [userEditable]="false"
            (isValidChanged)="isValidChanged($event)">
        </app-user-template>
    </div>
    
    <mat-divider></mat-divider>
    
    <div mat-dialog-actions class="row pull-right">
        <button 
            class="btn btn-default"
            (click)="onCancelClick()">
            Cancel
        </button>
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            [disabled]="!isValid"
            class="btn btn-primary margin-left-md"
            [mat-dialog-close]="user">
            Apply changes 
        </button>
    </div>
</div>