import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-filter-panel',
    templateUrl: './filter-panel.component.html',
    styleUrls: ['./filter-panel.component.scss']
})
export class FilterPanelComponent {
    @Output() searchChanged = new EventEmitter();
    @Input() paramsCount: number;

    filterPanelIsOpen = false;

    _search: string;
    set search(value: string) {
        this._search = value;
        this.searchChanged.emit(value);
    }
    get search() {
        return this._search;
    }

    get filtersActiveTitle() {
        let countTitle = '(no active filters)';

        if (this.paramsCount) {
            countTitle = this.paramsCount > 1
                ? `(${this.paramsCount} filters active)`
                : `(${this.paramsCount} filter active)`;
        }

        return countTitle;
    }

    onToogleFilterPanel() {
        this.filterPanelIsOpen = !this.filterPanelIsOpen;
    }

    onClearClick() {
        this.search = null;
    }
}
