import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';

import { AuthService } from '#services/shared';
import { Role } from 'enums';

@Directive({ selector: '[appUserRoleUp]' })
export class UserRoleUpDirective implements OnInit {
    constructor(
        private templateRef: TemplateRef<any>,
        private authService: AuthService,
        private viewContainer: ViewContainerRef
    ) { }

    userRoles: Role[];

    @Input()
    set appUserRoleUp(roles: Role[]) {
        this.userRoles = roles;
    }

    ngOnInit() {
        let hasAccess = false;

        if (this.userRoles) {
            hasAccess = this.userRoles.some(r => this.authService.hasRole(r));
        }

        if (hasAccess) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
