<mat-nav-list>
    <div class="col">
        <h3 matSubheader>General</h3>
        <a mat-list-item routerLink="/home" (click)="onItemClick()">
            <mat-icon>home</mat-icon>
            <span class="nav-caption">Home</span>
        </a>
        <a mat-list-item routerLink="/projects" (click)="onItemClick()">
            <mat-icon>vertical_split</mat-icon>
            <span class="nav-caption">Projects</span>
        </a>
        <a mat-list-item routerLink="/equipments" (click)="onItemClick()">
            <mat-icon>view_list</mat-icon>
            <span class="nav-caption">Equipment</span>
        </a>
        <a mat-list-item routerLink="/purchase-orders" (click)="onItemClick()">
            <mat-icon>assignment</mat-icon>
            <span class="nav-caption">Purchase orders</span>
        </a>
        <br>

        <mat-divider></mat-divider>
    </div>
</mat-nav-list>

<mat-nav-list>
    <div class="col">
        <h3 matSubheader>Tools</h3>
        <a mat-list-item routerLink="/tools/reshuffling" (click)="onItemClick()">
            <mat-icon>shuffle</mat-icon>
            <span class="nav-caption">Reshuffling</span>
        </a>
        <a mat-list-item routerLink="/tools/milestone-import" (click)="onItemClick()">
            <mat-icon>cloud_upload</mat-icon>
            <span class="nav-caption">Milestone import</span>
        </a>
        <a mat-list-item routerLink="/tools/cost-transfer-import" (click)="onItemClick()">
            <mat-icon>swap_horiz</mat-icon>
            <span class="nav-caption">Cost transfer import</span>
        </a>
        <a mat-list-item routerLink="/tools/outlook-review" (click)="onItemClick()">
            <mat-icon>remove_red_eye</mat-icon>
            <span class="nav-caption">Outlook review</span>
        </a>
        <a mat-list-item routerLink="/tools/data-manager" (click)="onItemClick()">
            <mat-icon>edit</mat-icon>
            <span class="nav-caption">Data manager</span>
        </a>
        <a mat-list-item routerLink="/tools/po-gaps" (click)="onItemClick()">
            <mat-icon>find_replace</mat-icon>
            <span class="nav-caption">PO Gaps</span>
        </a>
        <a mat-list-item routerLink="/tools/procurement-sync" (click)="onItemClick()">
            <mat-icon>sync</mat-icon>
            <span class="nav-caption">Procurement sync</span>
        </a>
        <a mat-list-item routerLink="/tools/demand-supply" (click)="onItemClick()">
            <mat-icon>assessment</mat-icon>
            <span class="nav-caption">Demand & supply</span>
        </a>
        <a mat-list-item routerLink="/tools/dashboard" (click)="onItemClick()">
            <mat-icon>dashboard</mat-icon>
            <span class="nav-caption">Dashboard</span>
        </a>
        <br>

        <mat-divider></mat-divider>
    </div>
</mat-nav-list>