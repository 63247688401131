
<div *ngIf="canShowDrawings; else noDrawingTemplate">
  <app-page-header [title]="pageTitle"
                   [description]="pageDescription">
  </app-page-header>

  <form #drawingEquipment="ngForm">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="fx-33 filter-panel">
          <input matInput
                 placeholder="Search"
                 (keyup)="onKey($event)">
        </mat-form-field>
      </div>
      <div class="col-md-6" style="padding-top:3%;" *ngIf="this.selection.selected.length>0">
        Selected Drawing : {{this.selection.selected.length}}
      </div>
    </div>  

    <app-drawing-table [data]="drawings"
                       [columnsToDisplay]="drawingColumnsToDisplay"
                       [filter]="filter"
                       (collectionChanged)="onDrawingCollectionChanged($event)"
                       [selection]="selection">
    </app-drawing-table>

  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-actions class="row pull-right">
    <button class="btn btn-default"
            (click)="onCancelClick()">
      Cancel
    </button>
    <button type="submit" *appUserRoleUp="[ Role.Admin ]"
            [mat-dialog-close]="this.selection"
            class="btn btn-primary margin-left-md"
            (click)="onSave()">
      Save
    </button>
  </div>


</form>
</div>
<ng-template #noDrawingTemplate>
  <app-empty-list-holder [description]="'No Drawing added yet.'">
  </app-empty-list-holder>
  <div mat-dialog-actions class="row pull-right">
    <button class="btn btn-primary margin-left-md"
            (click)="onCancelClick()">
      Close
    </button>
  </div>
</ng-template>

