import { BaseModel } from './baseModel';

export class PurchaseOrder extends BaseModel {
    PurchaseOrderNumber: string = null;
    RequestNumber: string = null;
    SubmitDate?: Date = null;

    FabricatorID: number = null;
    Fabricator: string = null;

    EquipmentsOrdered: number;
    EquipmentsOfProjects: number;

    HasAribaAFEMismatchEquipments?: boolean = null;
}
