import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Equipment, PurchaseOrder } from 'models';
import { Page } from '_interfaces/page';
import { EquipmentEditArgs } from '../equipment-edit-dialog/equipment-edit-dialog.component';
import { CommonHelper } from '_extentions/common-helper';

@Component({
    selector: 'app-equipment-create-dialog',
    templateUrl: './equipment-create-dialog.component.html',
    styleUrls: ['./equipment-create-dialog.component.scss']
})

export class EquipmentCreateDialogComponent implements OnInit, Page {
    // page
    pageTitle = 'Add new equipment';
    pageDescription = 'fill data below to create new equipment';

    // other properties
    equipment: Equipment;
    purchaseOrder: PurchaseOrder;
    canEditProject: boolean;
    canEditPurchaseOrder: boolean;
    isValid = false;

    constructor(
        public dialogRef: MatDialogRef<EquipmentCreateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EquipmentEditArgs) {
    }

    ngOnInit() {
        this.purchaseOrder = CommonHelper.deepCopy(this.data.purchaseOrder);
        this.equipment = CommonHelper.deepCopy(this.data.equipment);
        this.canEditProject = !this.equipment.ProjectID;
        this.canEditPurchaseOrder = !this.equipment.PurchaseOrderID;
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    isValidChanged(isValid: boolean) {
        this.isValid = isValid;
    }
}
