import { Component, OnInit, ViewChild } from '@angular/core';

import { Page } from '_interfaces/page';
import { PurchaseOrder, Equipment } from 'models';
import { PurchaseOrderService } from '#services/api';
import { MatDialog } from '@angular/material/dialog';
import { PoTableComponent } from '../po-table/po-table.component';
import { FormControl } from '@angular/forms';
import { GetFilterBase } from 'models';
import { Role } from 'enums';

@Component({
    selector: 'app-po-list',
    templateUrl: './po-list.component.html',
    styleUrls: ['./po-list.component.scss']
})
export class PoListComponent extends GetFilterBase implements OnInit, Page {
    @ViewChild(PoTableComponent)
    poTable: PoTableComponent;

    @ViewChild('vendorsSelect')
    vendorsSelect;

    // page
    pageTitle = 'Purchase orders';
    pageDescription = 'list of purchase orders';

    // collections
    selectedVendorIds: number[];

    // filters
    submitDateFrom: Date;
    submitDateTo: Date;
    includeArchived: boolean;

    // other properties
    equipment: Equipment[];
    purchaseOrders: PurchaseOrder[];
    columnsToDisplay: Map<string, string> = new Map([
        ['PurchaseOrderNumber', 'Order #'],
        ['RequestNumber', 'Request #'],
        ['SubmitDate', 'Submit date'],
        ['Fabricator', 'Vendor'],
        ['Archived', ''],
        ['HasAribaAFEMismatchEquipments', ''],
        ['EquipmentsOrdered', 'Eq.'],
        ['Menu', '']
    ]);

    Role = Role;

    vendorsControl = new FormControl();

    constructor(
        private poService: PurchaseOrderService,
        public dialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.loadPurchaseOrders();
    }

    get canShowPurchaseOrders() {
        return (this.purchaseOrders && this.purchaseOrders.length)
            || this.filter.paramsCount > 0;
    }

    async loadPurchaseOrders() {
        this.updateHttpFilters();
        this.purchaseOrders = await this.poService.getAll(null, this.httpParams).toPromise();
    }

    async loadProjectDetails(poId: number) {
        this.equipment = await this.poService.getEquipment(poId).toPromise();
    }

    get allColumns(): string[] {
        return Array.from(this.columnsToDisplay.keys());
    }

    onPORowExpanded(event) {
        const row = event.value as PurchaseOrder;

        if (row) {
            this.loadProjectDetails(row.ID);
        }
    }

    // filters
    async onFilterChanged() {
        await this.loadPurchaseOrders();

        super.onUpdateFilters(this.filter.search);
        this.poTable.reloadSource(this.purchaseOrders);
    }

    vendorSelectionChange(ids) {
        this.selectedVendorIds = ids;
    }

    updateHttpFilters() {
        super.clearFilterParams();

        if (this.submitDateFrom) {
            super.addFilterParam('fromSubmitDate', this.submitDateFrom.toLocaleDateString());
        }

        if (this.submitDateTo) {
            super.addFilterParam('toSubmitDate', this.submitDateTo.toLocaleDateString());
        }

        if (this.includeArchived) {
            super.addFilterParam('includeArchived', true.toString());
        }

        if (this.selectedVendorIds && this.selectedVendorIds.length) {
            this.selectedVendorIds.forEach(id => {
                super.addFilterParam('fabricators', id.toString());
            });
        }
    }
}
