import { Component } from '@angular/core';
import { MilestoneTypeService } from '#services/api/milestone-type.service';
import { ToasterService } from '#services/shared';
import { Dictionary } from 'models';
import { Router } from '@angular/router';

@Component({
  selector: 'milestone-type-create',
  templateUrl: './milestone-type-create.component.html',
  styleUrls: ['./milestone-type-create.component.scss']
})
export class MilestoneTypeCreateComponent {
  // page
  pageTitle = 'Add new Milestone Type';
  pageDescription = 'edit data below to create milestone';

  // other properties
  newMilestone: Dictionary = new Dictionary(0, null);
  isValid = false;

  constructor(
    private milestoneTypeService: MilestoneTypeService,
    private router: Router,
    private toaster: ToasterService
  ) { }

  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }

  async onSubmit() {
    await this.milestoneTypeService.create(this.newMilestone).toPromise();

    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 3 } });
    this.toaster.showSuccess('Success...', 'Milestone has been added.');
  }

  onCancel() {
    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 3 } });
  }
}
