import { Component, OnInit } from '@angular/core';
import { User, Filter } from 'models';
import { Role } from 'enums';
import { take, map } from 'rxjs/operators';
import { UserService } from '#services/api';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  filter: Filter = <Filter>{};

  // collections
  users: User[];

  public columnsToDisplay: Map<string, string> = new Map([
    ['FullName', 'Full name'],
    ['Email', 'Email'],
    ['CAI', 'CAI'],
    ['Role', 'Role'],
    ['IsDisabled', 'Status'],
    ['Menu', 'Menu']
  ]);

  Role = Role;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.initUsers();
  }

  initUsers() {
    this.userService
      .getAll()
      .pipe(
        take(1),
        map(users => users.map(this.decorate))
      )
      .subscribe(data => {
        this.users = data;
      });
  }

  decorate(user: User) {
    return {
      ...user,
      FullName: `${user.FirstName} ${user.LastName}`,
      Role: user.Fabricator ? `${user.Role} (${user.Fabricator})` : `${user.Role}`
    };
  }

  get canShowUsers() {
    return this.users && this.users.length;
  }

  onUserCollectionChanged(event) {
    if (event) {
      this.initUsers();
    }
  }

  onSearchChanged(event: { value: string }) {
    if (!event) {
      return;
    }

    this.filter = {
      search: event.value,
      paramsCount: 0
    };
  }
}
