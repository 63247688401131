import { Component, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';

@Component({
    selector: 'app-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements AfterViewChecked {
    isBusy = false;

    constructor(
        private loadingIndicatorSvc: LoadingIndicatorService,
        private cdRef: ChangeDetectorRef) {
    }
    ngAfterViewChecked() {
        // to prevent ExpressionChangedAfterItHasBeenCheckedError
        const isVisible = this.loadingIndicatorSvc.isVisible();
        if (isVisible !== this.isBusy) { // check if it change, tell CD update view
            this.isBusy = isVisible;
            this.cdRef.detectChanges();
        }
    }
}
