import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { CRUDService } from '#services/api/crud.service';
import { PurchaseOrder } from 'models';
import { Equipment } from 'models';
@Injectable()
export class PurchaseOrderService extends CRUDService<PurchaseOrder> {
    constructor(http: HttpClient) {
        super(http, `${environment.apiEndpoint}/purchase-orders`);
    }

    public getEquipment(purchaseOrderId: number): Observable<Equipment[]> {
        return this.http.get<Equipment[]>(`${this.url}/${purchaseOrderId}/equipments`);
    }

    public addEquipment(purchaseOrderId: number, equipmentId: number): Observable<Equipment[]> {
        return this.http.put<any>(`${this.url}/${purchaseOrderId}/add-equipment/${equipmentId}`, null);
    }

    public archive(id: number): Observable<any> {
        return this.http.put<any>(`${this.url}/${id}/archive`, null);
    }
    public addCheckedEquipmentsPurchaseOrders(purchaseOrderId: number, equiments: any): Observable<any> {
        return this.http.put<any>(`${this.url}/${purchaseOrderId}/add-multiple-equipment`, equiments);
    }
}
