import { Component, Inject, OnInit } from '@angular/core';
import { Role } from 'enums';
import { Drawing } from 'models/drawing';
import { Page } from '_interfaces/page';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetFilterBase } from 'models';

export interface DrawingEditArgs {
  drawing: Drawing;
}

@Component({
  selector: 'app-drawing-edit-dialog',
  templateUrl: './drawing-edit-dialog.component.html',
  styleUrls: ['./drawing-edit-dialog.component.scss']
})

export class DrawingEditDialogComponent extends GetFilterBase implements OnInit, Page {

  // page
  pageTitle = 'Edit Drawing';
  pageDescription = 'edit data below to update drawing';
  file: File;

  // other properties
  drawing: Drawing;
  isValid = false;
  Role = Role;

  constructor(
    public dialogRef: MatDialogRef<DrawingEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DrawingEditArgs) {
    super();
  }

  ngOnInit() {
    this.drawing = {
      ...this.data.drawing
    };
  }

  fileChanged(file: File) {
    this.file = file;
  }

  async onSaveClick() {
    if (this.isValid) {
      const formData = new FormData();
      formData.append('file', this.file);
      this.updateHttpFilters();
      const data: any = { "formData":formData, "param": this.httpParams}
      this.dialogRef.close(data);
   }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }

  updateHttpFilters() {
    super.clearFilterParams();
    if (this.drawing.EquipmentTypeID) {
      this.drawing.EquipmentTypeID.forEach((id: number) => {
        this.httpParams = this.httpParams.append('EquipmentTypeID', JSON.stringify(id));
      })
    }


    if (this.drawing.Name) {
      super.addFilterParam('name', this.drawing.Name);
    }

    if (this.drawing.ID) {
      super.addFilterParam('iD', JSON.stringify(this.drawing.ID));
    }
  }
}
