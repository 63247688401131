import { DrawingService } from '#services/api';
import { DoCheck } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PostFilterBase } from 'models';
import { Drawing } from 'models/drawing';

@Component({
  selector: 'app-drawing-template',
  templateUrl: './drawing-template.component.html',
  styleUrls: ['./drawing-template.component.scss']
})

export class DrawingTemplateComponent extends PostFilterBase implements DoCheck {
  appearance: string = "outline";
  @Input() drawing: Drawing;
  file: File;
  @Output() isValidChanged = new EventEmitter();
  @Output() fileChanged = new EventEmitter();

  selectedEquipmentTypeIds: number[];
  constructor(
    private drawingService: DrawingService
  ) {
    super();
  }

  ngOnInit() {
    this.drawing.EquipmentTypeID =
      this.drawing.EquipmentTypes.map(i => i.ID);
    this.selectedEquipmentTypeIds = this.drawing.EquipmentTypes.map(i => i.ID);
  }

  ngDoCheck(): void {
    this.isValidChanged.emit(!this.isValid);
  }

  get isValid(): boolean {
    if (!this.drawing) {
      return false;
    }
    return !this.drawing.Name || this.drawing.EquipmentTypeID.length == 0 || !this.drawing.DocumentName;
  }

  equipmentTypesSelectionChange(ids) {
    this.drawing.EquipmentTypeID = ids;
  }

  fileListChanged(file: File) {
    this.fileChanged.emit(file);
    this.file = file;
    this.drawing.DocumentName = file.name;
  }

  async onFilterChanged() {

    this.onUpdateFilters(this.filter.search);

  }

  downloadDrawing(item: any) {
    this.drawingService.getDrawingFile(item.ID).subscribe(
      data => {

        const blob = new Blob([data], { type: 'application/octet-stream' });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = '_blank';
        link.download = item.DocumentName;
        link.click();
      });
  }

  clearFile()
  {
    this.drawing.DocumentName=''; 
    this.file=null;
  }
}
