<mat-form-field class="fx-100" appearance="{{ appearance }}">
    <mat-label>{{ label }}</mat-label>

    <input type="text"
        placeholder="enter name or CAI"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
        [readonly]="readonly"
        [required]="required">

    <mat-icon 
        *ngIf="!readonly && !myControl.value"
        matSuffix 
        class="pointer">
        keyboard_arrow_down
    </mat-icon>

    <button
        *ngIf="!readonly && myControl.value"
        matSuffix
        mat-icon-button
        (click)="onItemCleared()">
        <mat-icon>close</mat-icon>
    </button>

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onItemChanged($event)">
        <mat-option *ngFor="let item of items | async" [value]="item" title="{{ item.FullNameEmail }}">
            {{ item.FullNameEmail }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>