import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { CRUDService } from '#services/api/crud.service';
import { MilestoneHistory } from 'models/milestone/milestone-history';

@Injectable()
export class MilestoneHistoryService extends CRUDService<MilestoneHistory> {

  constructor(http: HttpClient) {
    super(http, `${environment.apiEndpoint}/milestone-history`);
}
  public getMilestonerHistory(id: number): Observable<any> {
    return this.http.get<MilestoneHistory[]>(`${this.url}/${id}`);
  }

}
