import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { Page } from '_interfaces/page';
import { EquipmentService } from '#services/api/equipment.service';
import { ToasterService } from '#services/shared/toaster.service';
import { Dictionaries } from 'enums/dictionary';
import { DictionaryService } from '#services/api/dictionary.service';
import { Dictionary, Reshuffle, Equipment } from 'models';
import { ConfirmationDialogComponent } from '#components/shared/confirmation-dialog/confirmation-dialog.component';
import { EquipmentTableComponent } from '#components/equipment/equipment-table/equipment-table.component';

@Component({
    selector: 'app-reshuffling',
    templateUrl: './reshuffling.component.html',
    styleUrls: ['./reshuffling.component.scss']
})

export class ReshufflingComponent implements Page {
    @ViewChild('supplyTable')
    supplyTable: EquipmentTableComponent;

    // page
    pageTitle = 'Reshuffling';
    pageDescription = 'select equipment type to get reshuffle options';

    // other properties
    reshuffleCollection: Reshuffle[];
    demandItems: Equipment[];
    supplyItems: Equipment[];
    selectedSuppyItems: Equipment[];
    equipmentTypes: Dictionary[];
    selectedTypeId: number;
    hasChanges: boolean;

    demandColumnsToDisplay: Map<string, string> = new Map([
        ['Locked', ''],
        ['ProjectName', 'Project'],
        ['Vendor', 'Vendor'],
        ['ProjectedFATDate', 'Projected FAT date'],
        ['NeedByDate', 'Need By Date'],
        ['IsNonStandard', ''],
        ['_info', ''],
        ['Comment', ''],
        ['DeliveryStatus', 'Status'],
        ['Menu', '']
    ]);

    supplyColumnsToDisplay: Map<string, string> = new Map([
        ['_checked', ''],
        ['ProjectName', 'Project'],
        ['Vendor', 'Vendor'],
        ['ProjectedFATDate', 'Projected FAT date'],
        ['NeedByDate', 'Need By Date'],
        ['IsNonStandard', ''],
        ['_info', ''],
        ['Comment', ''],
        ['DeliveryStatus', 'Status']
    ]);

    constructor(
        private dictionaryService: DictionaryService,
        private equipmentService: EquipmentService,
        private location: Location,
        public dialog: MatDialog,
        private toaster: ToasterService) {
    }

    get canApplyChanges() {
        return this.reshuffleCollection &&
            this.reshuffleCollection.length &&
            !this.hasChanges;
    }

    get supplyItemsCount(): number {
        return !this.supplyItems
            ? 0
            : this.supplyItems
                .filter(i => i.ID > 0)
                .length;
    }

    get demandItemsCount(): number {
        return !this.demandItems
            ? 0
            : this.demandItems
                .filter(i => i.ID > 0)
                .length;
    }

    get dummyEquipment() {
        const dummy = new Equipment();
        const crypto = window.crypto;
        let randomValue = new Uint32Array(10);
        dummy.ProjectName = ' ';
        dummy.ID = Math.ceil(crypto.getRandomValues(randomValue)[0] * -1000000);

        return dummy;
    }

    syncManualChanges() {
        this.reshuffleCollection.length = 0;
        const maxAmount = this.supplyItems.length > this.demandItems.length
            ? this.supplyItems.length
            : this.demandItems.length;

        for (let i = 0; i < maxAmount; i++) {
            const item = new Reshuffle();

            if (this.demandItems.length > i) {
                item.Demand = this.removeDummy(this.demandItems[i]);
            }
            if (this.supplyItems.length > i) {
                item.Supply = this.removeDummy(this.supplyItems[i]);
            }

            this.reshuffleCollection.push(item);
        }
    }

    async reload() {
        this.reshuffleCollection = await this.equipmentService
            .getReshufflingOptions(this.selectedTypeId)
            .toPromise();

        this.supplyItems = this.reshuffleCollection
            .map(i => i.Supply || this.dummyEquipment);

        this.demandItems = this.reshuffleCollection
            .map(i => i.Demand || this.dummyEquipment);

        this.hasChanges = false;
    }

    removeDummy(equipment: Equipment) {
        return equipment.ID > 0
            ? equipment
            : null;
    }

    async onSubmit() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            minWidth: '350px',
            minHeight: '190px',
            data: <Page>{
                pageTitle: 'Confirmation',
                pageDescription: 'You cannot revert this operation. Are you sure?'
            }
        });

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                this.syncManualChanges();
                await this.equipmentService.reshuffle(this.reshuffleCollection).toPromise();

                this.location.back();
                this.toaster.showSuccess('Success...', 'Reshuffling has been performed.');
            }
        });
    }

    async onLoadEquipmentTypes() {
        if (!this.equipmentTypes) {
            this.equipmentTypes = await this.dictionaryService.getAll(
                `${Dictionaries.equipmentTypes}/dictionary`).toPromise();
        }
    }

    onEquipmentTypeChanged(event) {
        if (event.value) {
            this.selectedTypeId = (<Dictionary>event.value).ID;
            this.reload();
        }
    }

    onDemandCollectionChanged() {
        this.hasChanges = true;
    }

    onSupplyCollectionChanged(event) {
        if (event.value) {
            this.supplyItems = event.value;
        }
    }

    onCancel() {
        this.location.back();
    }
}
