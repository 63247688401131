import { Component, OnInit, OnDestroy } from '@angular/core';
import { speedDialFabAnimations } from 'animations/speed-dial-fab.animations';
import { FabButton } from 'models';
import { SpeedButtonService } from '#services/shared/speed-button.service';

@Component({
    selector: 'app-speed-dial-fab',
    templateUrl: './speed-dial-fab.component.html',
    styleUrls: ['./speed-dial-fab.component.scss'],
    animations: [speedDialFabAnimations]
})
export class SpeedDialFabComponent implements OnInit, OnDestroy {
    fabButtons: FabButton[];
    hasEvents: boolean;
    fabTogglerState = 'inactive';

    constructor(
        private speedButtonService: SpeedButtonService) {
    }

    ngOnInit(): void {
        this.speedButtonService.subject.subscribe(buttons => {
            this.fabButtons = buttons;
        });
    }

    ngOnDestroy(): void {
        this.speedButtonService.subject.unsubscribe();
    }

    get isToogled(): boolean {
        return this.fabTogglerState === 'active';
    }

    showItems() {
        this.fabTogglerState = 'active';
    }

    hideItems() {
        this.fabTogglerState = 'inactive';
    }

    onToggleFab() {
        this.isToogled
            ? this.hideItems()
            : this.showItems();
    }
}
