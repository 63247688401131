
export class User {
    ID: number;
    FirstName: string;
    LastName: string;
    Email: string;
    CAI: string;
    FullName: string;
    FullNameEmail: string;

    IsDisabled: boolean;
    IsAcknowledgedDisclaimer: boolean;

    Role: string;
    RoleID: number;

    Fabricator: string;
    FabricatorID?: number;
}
