import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidenav-user',
  templateUrl: './sidenav-user.component.html',
  styleUrls: ['./sidenav-user.component.scss']
})
export class SidenavUserComponent {
  @Output() itemClick = new EventEmitter();

  public onItemClick = () => {
    this.itemClick.emit();
  }
}
