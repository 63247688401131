import { Component, OnInit } from '@angular/core';
import { Filter } from 'models';
import { Dictionary } from 'models';
import { VendorService } from '#services/api/vendor.service';
import { Role } from 'enums';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss']
})
export class VendorListComponent implements OnInit {
  filter: Filter = <Filter>{};

  // collections
  vendors: Dictionary[];

  public vendorsColumnsToDisplay: Map<string, string> = new Map([
    ['Name', 'Vendor name'],
    ['Menu', '']
  ]);

  Role = Role;

  constructor(
    private vendorService: VendorService) {
  }

  ngOnInit() {
    this.initVendors();
  }

  initVendors() {
    this.vendorService
      .getAll()
      .pipe(
        take(1)
      ).subscribe(data => {
        this.vendors = data;
      });
  }

  get canShowVendors() {
    return this.vendors && this.vendors.length;
  }

  onVendorCollectionChanged(event) {
    if (event) {
      this.initVendors();
    }
  }

  onSearchChanged(event: { value: string }) {
    if (!event) {
      return;
    }

    this.filter = {
      search: event.value,
      paramsCount: 0
    };
  }
}
