<mat-form-field class="fx-100" appearance="{{ appearance }}">
    <mat-label>{{ label }}</mat-label>

    <input 
        type="text"
        name="inputControl"
        matInput
        placeholder="{{ placeholder }}"
        [readonly]="readonly"
        [formControl]="autocompleteControl"
        [matAutocomplete]="auto"
        matTooltip="You should select value from suggested one"
        [matTooltipDisabled]="!autocompleteControl.errors"
        [required]="required">

    <mat-icon 
        *ngIf="!readonly && (!autocompleteControl.value || required)"
        matSuffix 
        class="pointer">
        keyboard_arrow_down
    </mat-icon>

    <button     
        *ngIf="!readonly && autocompleteControl.value && !required"
        matSuffix 
        mat-icon-button
        matTooltip="Clear selection"
        aria-label="Clear" 
        (click)="clearClick()">
        <mat-icon>close</mat-icon>
    </button>

    <mat-autocomplete 
        #auto="matAutocomplete" 
        [displayWith]="onDisplayWith">
        
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{ getDisplayedValue(option) }}
        </mat-option>        
    </mat-autocomplete>
    
    <!-- <mat-error *ngIf="autocompleteControl.errors">
        You should select value from suggested one.
    </mat-error> -->
</mat-form-field>
