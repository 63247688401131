<mat-form-field class="fx-100" (click)="onLoad();">
    <mat-label>Purchase orders</mat-label>
    <mat-select
        #purchaseOrdersSelect
        placeholder="select purchase orders"
        [formControl]="purchaseOrdersControl"
        (closed)="onClose($event)"
        (selectionChange)="onChange($event)"
        disableOptionCentering
        multiple>
        <mat-option>
          <ngx-mat-select-search [formControl]="purchaseOrdersFilterControl"
              [placeholderLabel]="'Search...'"
              [noEntriesFoundLabel] ="'Not found'" ></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
            {{purchaseOrdersControl.value?.length > 0 ? purchaseOrdersControl.value[0].PurchaseOrderNumber : ''}}
            <span
                *ngIf="purchaseOrdersControl.value?.length > 1"
                class="additional-selection">
                    (+{{purchaseOrdersControl.value.length - 1}}
                    {{purchaseOrdersControl.value?.length === 2 ? 'other' : 'others'}})
            </span>
        </mat-select-trigger>
        <mat-option *ngFor="let po of filteredpurchaseOrdersMulti | async " [value]="po">
            {{po.PurchaseOrderNumber}}
        </mat-option>
    </mat-select>
</mat-form-field>
