import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class LoggerService {

    public error(message: string) {
        console.error(`Error: :${message}`);
    }

    public httpError(error: HttpErrorResponse, additionalMessage: string = '') {
        let message = `Http call to ${error.url} failed with following code: ${error.status}:\n${error.message}`;

        if (additionalMessage) {
            message = `${message}. Additional message: ${additionalMessage}`;
        }

        console.error(message);
    }

    public message(message: string) {
        console.log(message);
    }
}
