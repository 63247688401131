import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CRUD } from '_interfaces/crud';

export abstract class CRUDService<T> implements CRUD<T> {
    constructor(
        protected http: HttpClient,
        protected url: string) {
    }

    public create(item: T, url: string = this.url): Observable<T> {
        return this.http.post<T>(url, item);
    }

    public update(item: T, url: string = this.url): Observable<T> {
        return this.http.put<T>(url, item);
    }

    public delete(id: number, url: string = this.url): Observable<T> {
        return this.http.delete<T>(`${url}/${id}`);
    }

    public get(id: number, url: string = this.url): Observable<T> {
        return this.http.get<T>(`${url}/${id}`);
    }

    public getAll(url: string = this.url, options = null): Observable<T[]> {
        return this.http.get<T[]>(url || this.url, { params: options });
    }
}
