import { Component } from '@angular/core';
import { Page } from '_interfaces/page';
import { Role } from 'enums';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '#components/shared/confirmation-dialog/confirmation-dialog.component';
import { ProcurementTracker } from 'models/procurement/procurement-tracker';
import { Observable } from 'rxjs';
import { ProcurementService } from '#services/api/procurement.service';
import { ToasterService } from '#services/shared';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-procurement-sync',
  templateUrl: './procurement-sync.component.html',
  styleUrls: ['./procurement-sync.component.scss']
})
export class ProcurementSyncComponent implements Page {
  // page
  pageTitle = 'Procurement sync';
  pageDescription = 'synchronize your procurement data';

  // other properties
  parsedData: ProcurementTracker;

  Role = Role;

  constructor(
    private procurementService: ProcurementService,
    private toaster: ToasterService,
    private dialog: MatDialog
  ) { }

  onSubmit() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        minWidth: '350px',
        minHeight: '200px',
        data: <Page>{
            pageTitle: 'Confirmation',
            pageDescription: 'You are going to update database. Are you sure?'
        }
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
        if (result) {
          this.import().subscribe(results => {
            this.parsedData = results;

            this.showUploadNotification(this.parsedData);
          });
        }
    });
  }

  import(): Observable<ProcurementTracker> {
    return this.procurementService.import().pipe(take(1));
  }

  showUploadNotification(results: ProcurementTracker) {
    const hasUpdated = results.EquipmentsSuccessfullyUpdated;
    const hasErrors = results.Errors.length;

    if (hasUpdated && !hasErrors) {
        this.toaster.showSuccess('Success...', 'Data was updated successfully.');
    }

    if (hasUpdated && hasErrors) {
        this.toaster.showWarn('Warning...', 'Data was updated partially.');
    }

    if (!hasUpdated && hasErrors) {
        this.toaster.showError('Error...', 'Data was not updated.');
    }
  }
}
