import { Component, OnInit, Inject } from '@angular/core';
import { Dictionary } from 'models';
import { Page } from '_interfaces/page';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Role } from 'enums';

export interface ProjectTypeEditArgs {
  projectType: Dictionary;
}

@Component({
  selector: 'app-project-type-edit-dialog',
  templateUrl: './project-type-edit-dialog.component.html',
  styleUrls: ['./project-type-edit-dialog.component.scss']
})
export class ProjectTypeEditDialogComponent implements OnInit, Page {
  // page
  pageTitle = 'Edit Project Type';
  pageDescription = 'edit data below to update project type';

  // other properties
  projectType: Dictionary;
  isValid = false;
  Role = Role;

  constructor(
    public dialogRef: MatDialogRef<ProjectTypeEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProjectTypeEditArgs) {
  }

  ngOnInit() {
    this.projectType = {
      ...this.data.projectType
    };
  }

  onSaveClick(): void {
    if (this.isValid) {
      this.dialogRef.close(this.projectType);
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }
}
