<div class="row text-right">
    <div class="col-sm-12">
        <a (click)="linkNewPOItem = true">+ Associate new PO line item</a>
    </div>
</div>
<div class="row">
    <div class="col-sm-12" *ngIf="linkNewPOItem">
        <app-link-po-info-form
        [equipmentId]="equipmentId"
        [existingPO]="dataSource"
        (onCancel)="hidePOItemForm($event)"
        (onSave)="onLinkNewPOLinkItem($event)"
        ></app-link-po-info-form>
    </div>
</div>
<div class="col"
    *ngIf="canShowPOLinkedItems; else noPOLinkedItemsTemplate">
    <mat-table matSort multiTemplateDataRows
        (matSortChange)="onSortData($event)"
        [dataSource]="dataSource"
        class="mat-table-container"
        style="overflow: hidden"
        >
        <ng-container matColumnDef="{{column}}" *ngFor="let column of allColumns">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{getColumnTitle(column)}}
            </mat-header-cell>
            <mat-cell class="mat-cell-value" *matCellDef="let item">
                <ng-container
                    [ngTemplateOutlet]="columnContent"
                    [ngTemplateOutletContext]="{item: item, column: column}">
                </ng-container>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="allColumns; sticky: true"></mat-header-row>
        <mat-row
            *matRowDef="let row; columns: allColumns"
            matRipple
            class="element-row">
        </mat-row>
    </mat-table>
</div>

<mat-paginator
    [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons>
</mat-paginator>

<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item
            (click)="onDeletePO(item)">
            <mat-icon>delete_forever</mat-icon>
            Remove
        </button>
    </ng-template>
</mat-menu>

<ng-template #noMatchesTemplate>
    <app-empty-list-holder
        [icon]="'search'"
        [description]="'No matches'">
    </app-empty-list-holder>
</ng-template>

<ng-template #columnContent let-item="item" let-column="column">
    <ng-container [ngSwitch]="column">
        <ng-container>
            <div class="dotsMenu" *ngSwitchCase="'Menu'">
                <button
                    mat-button
                    (click)="$event.stopPropagation()"
                    class="menu-button pull-right"
                    [matMenuTriggerFor]="menu"
                    [matMenuTriggerData]="{item: item}">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>
        </ng-container>
        <span *ngSwitchCase="'UnitPrice'">
            {{ item[column] ? (item[column] | currency) : '' }}
        </span>
        <span *ngSwitchDefault>{{item[column]}}</span>
    </ng-container>
</ng-template>

<ng-template #noPOLinkedItemsTemplate>
    <app-empty-list-holder
        [description]="'No PO linked items yet.'">
    </app-empty-list-holder>
</ng-template>
