import { Component } from '@angular/core';
import { Location } from '@angular/common';

import { Page } from '_interfaces/page';
import { PurchaseOrder } from 'models';
import { PurchaseOrderService } from '#services/api';
import { ToasterService } from '#services/shared';

@Component({
    selector: 'app-po-create',
    templateUrl: './po-create.component.html',
    styleUrls: ['./po-create.component.scss']
})
export class PoCreateComponent implements Page {
    // page
    pageTitle = 'Create material supply';
    pageDescription = 'fill data below to create new purchase order';

    // other properties
    purchaseOrder: PurchaseOrder = new PurchaseOrder();
    isValid = false;

    constructor(
        private purchaseOrderService: PurchaseOrderService,
        private location: Location,
        private toaster: ToasterService) {
    }

    isValidChanged(isValid: boolean) {
        this.isValid = isValid;
    }

    async onSubmit() {
        await this.purchaseOrderService.create(this.purchaseOrder).toPromise();

        this.location.back();
        this.toaster.showSuccess('Success...', 'Purchase order has been added.');
    }

    onCancel() {
        this.location.back();
    }
}
