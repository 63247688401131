import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Page } from '_interfaces/page';
import { Equipment, PurchaseOrder } from 'models';
import { CommonHelper } from '_extentions/common-helper';
import { Role } from 'enums';
import { PurchaseOrderService } from '#services/api';

@Component({
    selector: 'app-po-edit-dialog',
    templateUrl: './po-edit-dialog.component.html',
    styleUrls: ['./po-edit-dialog.component.scss']
})

export class PoEditDialogComponent implements OnInit, Page {
    // page
    pageTitle = 'Edit material supply';
    pageDescription = 'edit data below to update the purchase order';

    // other properties
    purchaseOrder: PurchaseOrder;
    equipment: Equipment[];
    isValid = false;
    Role = Role;

    equipmentColumnsToDisplay: Map<string, string> = new Map([
      ['PurchaseOrderLineNumber', 'PO line'],
      ['EquipmentType', 'Equipment type'],
      ['ProjectName', 'Project name'],
      ['SerialNumber', 'Serial #'],
      ['DeliveryStatus', 'Status'],
  ]);

    constructor(
        public dialogRef: MatDialogRef<PoEditDialogComponent>,
        private poService: PurchaseOrderService,
        @Inject(MAT_DIALOG_DATA) public data: PurchaseOrder) {
    }

    ngOnInit() {
      this.purchaseOrder = CommonHelper.deepCopy(this.data);
      this.poService.getEquipment(this.purchaseOrder.ID).subscribe( res => this.equipment = res);
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    isValidChanged(isValid: boolean) {
        this.isValid = isValid;
    }

    onEquipmentCollectionChanged(event) {
      this.equipment = event;
    }
}
