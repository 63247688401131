import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Page } from '_interfaces/page';

@Component({
    selector: 'app-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements Page {
    // page
    pageTitle: string;
    pageDescription: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Page) {
        this.pageTitle = data.pageTitle;
        this.pageDescription = data.pageDescription;
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }
}
