<div 
    appDragDrop 
    (click)="fileInput.click()" 
    (fileDropped)="uploadFile($event)" 
    class="uploadfilecontainer">

    <ng-content></ng-content>

    <input 
        #fileInput 
        style="visibility: hidden" 
        type="file" 
        accept="{{ format }}"
        (change)="uploadFile($event.target.files)">
</div>