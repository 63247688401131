import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class FileUploaderService {
    constructor(protected http: HttpClient) {
    }

    public upload(url: string, file, options = null): Observable<any> {
        return this.http.post(url, file, { params: options });
    }
}
