import { Component } from '@angular/core';
import { ProjectTypeService } from '#services/api/project-type.service';
import { ToasterService } from '#services/shared';
import { Dictionary } from 'models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-type-create',
  templateUrl: './project-type-create.component.html',
  styleUrls: ['./project-type-create.component.scss']
})
export class ProjectTypeCreateComponent {

  // page
  pageTitle = 'Add new Project Type';
  pageDescription = 'edit data below to create a project type';

  // other properties
  newProjectType: Dictionary = new Dictionary(0, null);
  isValid = false;

  constructor(
    private projectTypeService: ProjectTypeService,
    private router: Router,
    private toaster: ToasterService
  ) { }

  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }

  async onSubmit() {
    await this.projectTypeService.create(this.newProjectType).toPromise();

    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 5 } });
    this.toaster.showSuccess('Success...', 'Project Type has been added.');
  }

  onCancel() {
    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 5 } });
  }

}
