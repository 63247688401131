<div class="page-with-shadow">
    <app-page-header [title]="pageTitle" [description]="pageDescription">
    </app-page-header>

    <div class="col" *ngIf="summary">
        <div class="row fx-100 margin-top-10 container">
            <div class="child">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>
                            <a routerLink="/projects">
                                <mat-icon>vertical_split</mat-icon>
                                <span class="nav-caption">Projects</span>
                            </a>
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <mat-divider></mat-divider>

                        <div style="display: flex; justify-content: space-between">
                            <div style="width: 100%">
                                <mat-list>
                                    <mat-list-item> <b>Total</b>: {{ summary.ProjectSummary.Total }} </mat-list-item>
                                    <mat-list-item> <b>Archived</b>: {{summary.ProjectSummary.Archived}} </mat-list-item>
                                </mat-list>
                            </div>

                            <mat-divider [vertical]="true"></mat-divider>

                            <div style="width: 100%">
                                <mat-list>
                                    <mat-list-item *ngFor="let item of summary.ProjectSummary.StatusSummary">
                                        <b>{{ item.Name }}</b>: {{ item.ItemsCount }}
                                    </mat-list-item>
                                </mat-list>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="child">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>
                            <a routerLink="/equipments">
                                <mat-icon>view_list</mat-icon>
                                <span class="nav-caption">Equipment</span>
                            </a>
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <mat-divider></mat-divider>

                        <div style="display: flex; justify-content: space-between">
                            <div style="width: 100%">
                                <mat-list>
                                    <mat-list-item> <b>Total</b>: {{ summary.EquipmentSummary.Total }} </mat-list-item>
                                    <mat-list-item> <b>Archived</b>: {{summary.EquipmentSummary.Archived}} </mat-list-item>
                                    <mat-list-item> <b>Spare</b>: {{summary.EquipmentSummary.Spare}} </mat-list-item>
                                </mat-list>
                            </div>

                            <mat-divider [vertical]="true"></mat-divider>
                            
                            <div style="width: 100%">
                                <mat-list>
                                    <mat-list-item *ngFor="let item of summary.EquipmentSummary.StatusSummary">
                                        <b>{{ item.Name }}</b>: {{ item.ItemsCount }}
                                    </mat-list-item>
                                </mat-list>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="child">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>
                            <a routerLink="/purchase-orders">
                                <mat-icon>assignment</mat-icon>
                                <span class="nav-caption">Purchase orders</span>
                            </a>
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <mat-divider></mat-divider>

                        <div style="width: 100%">
                            <mat-list>
                                <mat-list-item> <b>Total</b>: {{ summary.PurchaseOrderSummary.Total }} </mat-list-item>
                                <mat-list-item> <b>Archived</b>: {{summary.PurchaseOrderSummary.Archived}} </mat-list-item>
                            </mat-list>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>