import { EquipmentService } from '#services/api';
import { ToasterService } from '#services/shared';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Role } from 'enums';
import { Equipment, Filter } from 'models';
import { Drawing } from 'models/drawing';
import { take } from 'rxjs/operators';
import { CommonHelper } from '_extentions/common-helper';
import { EquipmentDrawingCreateArgs, EquipmentDrawingCreateDialogComponent } from '../equipment-drawing-create-dialog/equipment-drawing-create-dialog.component';

@Component({
  selector: 'app-equipment-drawing',
  templateUrl: './equipment-drawing.component.html',
  styleUrls: ['./equipment-drawing.component.scss']
})

export class EquipmentDrawingComponent implements OnInit {
  @Input() equipment: Equipment;
  drawings: Drawing[];
  orgDrawings: Drawing[];
  filter: Filter = <Filter>{};
  Role = Role;
  canaddDrawing: boolean = false;

  public drawingColumnsToDisplay: Map<string, string> = new Map([
    ['Name', 'Drawing Name'],
    ['DocumentName', 'Link to document'],
    ['IsDisabled', 'Status']
  ]);

  constructor(
    public dialog: MatDialog,
    private toaster: ToasterService,
    private equipmentService: EquipmentService
  ) { }

  ngOnInit() {
    this.initDrawing();
  }

  initDrawing() {
    const equipmentId = this.equipment && this.equipment.ID;
    if (!equipmentId) {
      return;
    }

    this.equipmentService
      .getDrawing(equipmentId)
      .pipe(
        take(1)
      )
      .subscribe(data => {      
        this.drawings = data;
        this.orgDrawings = CommonHelper.deepCopy(this.drawings);
        this.equipment.AssignedDrawing = this.drawings;
      });

  }


  onAddDrawing() {
    this.canaddDrawing = true;
    if (!this.equipment || !this.equipment.EquipmentTypeID) {
      this.canaddDrawing = false;
      this.toaster.showWarn('Warning...', 'Please set equipment Type');
    }
    else {
      const selectedItems = this.drawings && this.drawings.length > 0 ? this.drawings.filter(i => i.IsDisabled == false) : [];
      const args: EquipmentDrawingCreateArgs = {
        equipment: this.equipment,
        selectedDrawing: selectedItems
      };

      const dialogRef = this.dialog.open(EquipmentDrawingCreateDialogComponent, {
        width: '50%',
        maxHeight: '90%',
        minWidth: '400px',
        minHeight: '200px',
        data: args
      });

      dialogRef.afterClosed().subscribe(async (result: any) => {
        if (result) {
          console.log('result', result);
          this.drawings = result;

          if (this.equipment.ID > 0) {
            let difference = result.filter(x => !this.orgDrawings.some(o2 => o2.ID === x.ID));
            if (difference) {
              this.equipment.AssignedDrawing = result;
            }
          }
          else {
            this.equipment.AssignedDrawing = result;
          }
          this.toaster.showSuccess('Success...', 'Drawing changes has been saved.');
        }
      });
    }
  }

  get canShowDrawings() {
    if(this.drawings !=null && this.drawings.length>0)
        {
            if(this.equipment.EquipmentTypeID != this.drawings[0].EquipmentTypes[0].ID)
            {
              this.drawings = [];
            }
        }
    return this.drawings && this.drawings.length > 0;
  }

  onKey(event) {
    this.filter = <Filter>{
      search: event.target.value
    };
  }
}
