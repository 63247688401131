<form #equipmentTypeListForm="ngForm">
    <mat-toolbar class="page-toolbar" *ngIf="canShowEquipmentTypes">
        <span class="project-count">
            {{equipmentTypes && equipmentTypes.length}} equipment types
        </span>
        <span class="fill-remaining-space"></span>

        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary margin-left-md"
            [routerLink]="['/equipment-type-create']">
            Add new equipment type
        </button>
    </mat-toolbar>

    <div *ngIf="canShowEquipmentTypes; else noEquipmentTypesTemplate">
        <mat-divider></mat-divider>
        
        <app-search-panel 
            (searchChanged)="onSearchChanged($event)">
        </app-search-panel>
        
        <app-equipment-type-table
            [data]="equipmentTypes"
            [columnsToDisplay]="equipmentTypesColumnsToDisplay"
            [filter]="filter"
            (collectionChanged)="onEquipmentTypeCollectionChanged($event)">
        </app-equipment-type-table>
    </div>
</form>

<ng-template #noEquipmentTypesTemplate>
    <app-empty-list-holder
        [description]="'No equipment types added yet.'">
        <button 
            *appUserRoleUp="[ Role.Admin ]"
            class="btn btn-primary" 
            [routerLink]="'/equipment-type-create'">
            Add new equipment type
        </button>
    </app-empty-list-holder>
</ng-template>