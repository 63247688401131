<div class="page-with-shadow">
    <app-page-header 
        [title]="pageTitle" 
        [description]="pageDescription"> 
    </app-page-header>

    <ng-template *ngIf="!parsedData; then syncButtonTemplate; else resultTemplate"></ng-template>
</div>

<ng-template #syncButtonTemplate>
    <form (ngSubmit)="onSubmit()" class="col">
        <app-empty-list-holder 
            [description]="'To synchronize procurement data use the button below'"
            [icon]="'sync'">
            <button 
                *appUserRoleUp="[ Role.Admin ]"
                type="submit"
                class="btn btn-primary">
                Synchronize data
            </button>
        </app-empty-list-holder>
    </form>
</ng-template>

<ng-template #resultTemplate>
    <div *ngIf="parsedData" class="row" style="flex-wrap: nowrap;">
        <div class="col details-info-left-content">
            <span>
                succeed
                <p class="bold-text">{{parsedData.EquipmentsSuccessfullyUpdated}}</p>
            </span>
            <span>
                errors
                <p class="bold-text">{{parsedData.Errors.length}}</p>
            </span>
            <span>
                warnings
                <p class="bold-text">{{parsedData.Warnings.length}}</p>
            </span>
        </div>

        <div class="details-info-right-content">
            <mat-tab-group>
                <mat-tab *ngIf="parsedData.Errors.length">
                    <ng-template mat-tab-label>
                        <span class="errors-tab">Errors ({{parsedData.Errors.length}})</span>
                    </ng-template>
                    <div class="col error-list">
                        <mat-list role="list" *ngFor="let e of parsedData.Errors; let i = index">
                            <span class="column">
                                <span class="error-order-number">Order {{e.PurchaseOrderNumber}}</span>
                                <span class="error-line-number margin-left-md">Line {{e.PurchaseOrderLineNumber}}</span>
                                <span role="listitem" class="margin-left-md">
                                    <span style="font-weight: bolder;">Error:</span> 
                                    {{e.Message}}
                                </span>
                            </span>
                        </mat-list>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="parsedData.Warnings.length">
                    <ng-template mat-tab-label>
                        <span class="warnings-tab">Warnings ({{parsedData.Warnings.length}})</span>
                    </ng-template>
                    <div class="col warning-list">
                        <mat-list role="list" *ngFor="let e of parsedData.Warnings; let i = index">
                            <span class="column">
                                <span class="warning-order-number">Order {{e.PurchaseOrderNumber}}</span>
                                <span class="warning-line-number margin-left-md">Line {{e.PurchaseOrderLineNumber}}</span>
                                <span role="listitem" class="margin-left-md">
                                    <span style="font-weight: bolder;">Warning:</span> 
                                    {{e.Message}}
                                </span>
                            </span>
                        </mat-list>
                    </div>
                </mat-tab>
            </mat-tab-group>

            <app-empty-list-holder 
                *ngIf="!parsedData.Errors.length && !parsedData.Warnings.length" 
                [description]="'Synchronization has been completed with no issues.'"
                [icon]="'cloud_done'">
            </app-empty-list-holder>
        </div>
    </div>
</ng-template>
