<mat-form-field class="fx-100" (click)="onLoad();" [appearance]="appearance">
    <mat-label>Equipment types</mat-label>
    <mat-select 
        #etSelect
        placeholder="select types" 
        [formControl]="etControl"
        (closed)="onClose($event)"
        (selectionChange)="onChange($event)"
        disableOptionCentering
        [(ngModel)]="defaultEquipmentTypes" 
        multiple>
        <mat-select-trigger>
            {{etControl.value?.length > 0 ? etControl.value[0].Name : ''}}
            <span 
                *ngIf="etControl.value?.length > 1" 
                class="additional-selection">
                    (+{{etControl.value.length - 1}} 
                    {{etControl.value?.length === 2 ? 'other' : 'others'}})
            </span>
        </mat-select-trigger>
        <mat-option *ngFor="let type of types" [value]="type">
            {{type.Name}} 
        </mat-option>
    </mat-select>
</mat-form-field>
