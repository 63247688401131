import { IFilter } from './filter-base';
import { HttpParams } from '@angular/common/http';
import { Filter } from './filter';

export abstract class GetFilterBase implements IFilter {
  filter: Filter = <Filter>{};

  httpParams: HttpParams = new HttpParams();

  onUpdateFilters(value: string) {
    this.filter = <Filter>{
      search: value,
      paramsCount: this.httpParams['map'].size
    };
  }

  addFilterParam(key: string, value: string) {
    this.httpParams = this.httpParams.append(key, value);
  }

  clearFilterParams() {
    this.httpParams = new HttpParams();
  }
}
