import { Component, EventEmitter, Output, Input, DoCheck } from '@angular/core';
import { Dictionary } from 'models';

@Component({
  selector: 'milestone-template',
  templateUrl: './milestone-template.component.html',
  styleUrls: ['./milestone-template.component.scss']
})
export class MilestoneTemplateComponent implements DoCheck {
  @Input() milestone: Dictionary;
  @Output() isValidChanged = new EventEmitter();

  ngDoCheck(): void {
    this.isValidChanged.emit(!this.isValid);
  }

  get isValid(): boolean {
    if (!this.milestone) {
      return false;
    }

    return !this.milestone.Name;
  }
}
