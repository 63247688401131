import { Component } from '@angular/core';
import { AreaService } from '#services/api/area.service';
import { ToasterService } from '#services/shared';
import { Dictionary } from 'models';
import { Router } from '@angular/router';

@Component({
  selector: 'area-create',
  templateUrl: './area-create.component.html',
  styleUrls: ['./area-create.component.scss']
})
export class AreaCreateComponent {
  // page
  pageTitle = 'Add new Area';
  pageDescription = 'edit data below to create an area';

  // other properties
  newArea: Dictionary = new Dictionary(0, null);
  isValid = false;

  constructor(
    private areaService: AreaService,
    private router: Router,
    private toaster: ToasterService
  ) { }

  isValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }

  async onSubmit() {
    await this.areaService.create(this.newArea).toPromise();

    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 4 } });
    this.toaster.showSuccess('Success...', 'Area has been added.');
  }

  onCancel() {
    this.router.navigate(['tools/data-manager'], { queryParams: { tab: 4 } });
  }
}
