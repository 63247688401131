import { Injectable } from '@angular/core';

@Injectable()
export class LoadingIndicatorService {
    private counter = 0;

    public show(): void {
        this.counter++;
    }

    public hide(): void {
        if (this.counter > 0) {
            this.counter--;
        }
    }

    public isVisible(): boolean {
        return this.counter > 0;
    }
}
