import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';
import { startWith, map, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { CommonHelper } from '_extentions/common-helper';
import { Dictionary } from 'models';

@Component({
    selector: 'app-autocomplete-dropdown',
    templateUrl: './autocomplete-dropdown.component.html',
    styleUrls: ['./autocomplete-dropdown.component.scss']
})

export class AutocompleteDropdownComponent<T> implements OnInit {
    _options: T[];
    _defaultValue: T;
    validErrName: string;
    autocompleteControl = new FormControl();
    filteredOptions: Observable<T[]>;
    filterBy: string[];
    filterValue: string;

    @Input()
    get options() {
        return this._options;
    }
    set options(value: T[]) {
        if (!CommonHelper.arraysAreEqual(value, this._options)) {
            this._options = value;
            this.autocompleteControl.updateValueAndValidity();
        }
    }

    @Input()
    get defaultValue(): T {
        return this._defaultValue;
    }
    set defaultValue(value: T) {
        if (value instanceof Dictionary &&
            (!value['ID'] || !value['Name'])) {
            return;
        }

        if (!CommonHelper.objectsAreEqual(this._defaultValue, value)) {
            this._defaultValue = value;
            this.autocompleteControl.setValue(value);
            this.selectedChanged.emit({ value: value });
        }
    }

    @Input() public filterByFields: string;
    @Input() public displayProperty: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public parentFormControl: FormControl;
    @Input() public required: boolean;
    @Input() public readonly: boolean;
    @Input() public appearance = 'outline';
    @Input() public isAsync = false;
    @Input() public displayWith: (value: T) => string;

    @Output() public textChanged = new EventEmitter();
    @Output() public selectedChanged = new EventEmitter();
    @Output() public clicked = new EventEmitter();


    @HostListener('click') onClick() {
        if (!this.readonly) {
            this.clicked.emit();
        }
    }

    constructor() {
        // TODO
        // https://stackoverflow.com/questions/54328024/validate-mat-autocomplete-is-object
        // this.autocompleteControl.setValidators(this.validator(this.options));
    }

    ngOnInit() {
        if (!this.parentFormControl) {
            throw new Error('Field <parentFormControl> should be declared for AutocompleteDropDownComponent');
        }
        if (!this.label) {
            throw new Error('Field <label> should be declared for AutocompleteDropDownComponent');
        }

        this.initFilterFields();

        this.validErrName = this.label + 'autocompleteValidationError';
        this.filteredOptions = this.autocompleteControl.valueChanges
            .pipe(
                debounceTime(this.isAsync ? 700 : 0),
                startWith(''),
                map(value => this.filter(value))
            );
    }

    private filter(value): T[] {
        this.textChanged.emit({ value: value });

        if (!!!this.options || !CommonHelper.isString(value)) {
            return this.options;
        }

        this.validate(value);

        return this.getFilteredOptions(
            value.toLowerCase().trim()
        );
    }

    onDisplayWith = (selected: any) => {
        if (!CommonHelper.objectsAreEqual(this.autocompleteControl.value, selected)) {
            this.removeError(this.autocompleteControl, this.validErrName);
            this.removeError(this.parentFormControl, this.validErrName);

            this.selectedChanged.emit({ value: selected });
        }

        return selected
            ? this.getDisplayedValue(selected)
            : undefined;
    }

    initFilterFields() {
        if (this.filterByFields) {
            this.filterBy = this.filterByFields.split('|');
        } else {
            this.filterBy = [this.displayProperty];
        }
    }

    getFilteredOptions(filter): T[] {
        const arr = new Array<T>();

        this.options.forEach((option) => {
            this.filterBy.forEach((prop) => {
                if (!!option[prop] && option[prop].toLowerCase().includes(filter)) {
                    arr.push(option);
                    return;
                }
            });
        });

        return arr;
    }

    getDisplayedValue(value): string {
        if (this.displayWith) {
            return this.displayWith(value);
        }

        if (this.displayProperty) {
            return value[this.displayProperty];
        }

        return value;
    }

    validate(filterValue) {
        if (!this.required && filterValue.length === 0) {
            this.removeError(this.autocompleteControl, this.validErrName);
            this.removeError(this.parentFormControl, this.validErrName);
        } else {
            this.setErrors(this.validErrName);
        }

    }

    setErrors(errors) {
        this.autocompleteControl.setErrors(errors);
        this.parentFormControl.setErrors(errors);
    }

    removeError(control: AbstractControl, error: string) {
        if (!control) {
            return;
        }

        const err = control.errors; // get control errors
        if (err) {
            delete err[error]; // delete your own error
            // if (!Object.keys(err).length) { // if no errors left
            //     control.setErrors(null); // set control errors to null making it VALID
            // }
            // else {
            //     control.setErrors(err); // controls got other errors so set them back
            // }
            control.setErrors(null);
        }
    }

    clearClick() {
        this.autocompleteControl.setValue('');
        this.selectedChanged.emit({ value: null });
    }
}
